<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.66675 4.79163C6.32508 4.79163 6.04175 4.50829 6.04175 4.16663V1.66663C6.04175 1.32496 6.32508 1.04163 6.66675 1.04163C7.00841 1.04163 7.29175 1.32496 7.29175 1.66663V4.16663C7.29175 4.50829 7.00841 4.79163 6.66675 4.79163Z"
			fill="#36373F" />
		<path
			d="M13.3333 4.79163C12.9916 4.79163 12.7083 4.50829 12.7083 4.16663V1.66663C12.7083 1.32496 12.9916 1.04163 13.3333 1.04163C13.6749 1.04163 13.9583 1.32496 13.9583 1.66663V4.16663C13.9583 4.50829 13.6749 4.79163 13.3333 4.79163Z"
			fill="#36373F" />
		<path
			d="M7.08333 12.0834C6.975 12.0834 6.86666 12.0584 6.76666 12.0168C6.65833 11.9751 6.57501 11.9167 6.49167 11.8417C6.34167 11.6834 6.25 11.4667 6.25 11.2501C6.25 11.0334 6.34167 10.8167 6.49167 10.6584C6.57501 10.5834 6.66666 10.5251 6.76666 10.4834C6.96666 10.4001 7.2 10.4001 7.4 10.4834C7.5 10.5251 7.59166 10.5834 7.67499 10.6584C7.70833 10.7001 7.74999 10.7417 7.77499 10.7834C7.80832 10.8334 7.83334 10.8834 7.85001 10.9334C7.87501 10.9834 7.89168 11.0334 7.90001 11.0834C7.90834 11.1417 7.91667 11.2001 7.91667 11.2501C7.91667 11.4667 7.82499 11.6834 7.67499 11.8417C7.59166 11.9167 7.5 11.9751 7.4 12.0168C7.3 12.0584 7.19167 12.0834 7.08333 12.0834Z"
			fill="#36373F" />
		<path
			d="M10.0001 12.0834C9.89175 12.0834 9.78341 12.0584 9.68341 12.0167C9.57508 11.975 9.49176 11.9167 9.40842 11.8417C9.25842 11.6834 9.16675 11.4667 9.16675 11.25C9.16675 11.2 9.17507 11.1417 9.18341 11.0834C9.19174 11.0334 9.2084 10.9834 9.2334 10.9334C9.25007 10.8834 9.27509 10.8334 9.30843 10.7834C9.34176 10.7417 9.37509 10.7 9.40842 10.6584C9.71675 10.35 10.2751 10.35 10.5917 10.6584C10.6251 10.7 10.6584 10.7417 10.6917 10.7834C10.7251 10.8334 10.7501 10.8834 10.7668 10.9334C10.7918 10.9834 10.8084 11.0334 10.8168 11.0834C10.8251 11.1417 10.8334 11.2 10.8334 11.25C10.8334 11.4667 10.7417 11.6834 10.5917 11.8417C10.4334 11.9917 10.2251 12.0834 10.0001 12.0834Z"
			fill="#36373F" />
		<path
			d="M7.08333 15C6.975 15 6.86666 14.975 6.76666 14.9334C6.66666 14.8917 6.57501 14.8334 6.49167 14.7584C6.34167 14.6 6.25 14.3834 6.25 14.1667C6.25 14.1167 6.25832 14.0584 6.26666 14.0084C6.27499 13.95 6.29166 13.9 6.31666 13.85C6.33332 13.8 6.35834 13.75 6.39168 13.7C6.41668 13.6584 6.45834 13.6167 6.49167 13.575C6.57501 13.5 6.66666 13.4417 6.76666 13.4C6.96666 13.3167 7.2 13.3167 7.4 13.4C7.5 13.4417 7.59166 13.5 7.67499 13.575C7.70833 13.6167 7.74999 13.6584 7.77499 13.7C7.80832 13.75 7.83334 13.8 7.85001 13.85C7.87501 13.9 7.89168 13.95 7.90001 14.0084C7.90834 14.0584 7.91667 14.1167 7.91667 14.1667C7.91667 14.3834 7.82499 14.6 7.67499 14.7584C7.59166 14.8334 7.5 14.8917 7.4 14.9334C7.3 14.975 7.19167 15 7.08333 15Z"
			fill="#36373F" />
		<path
			d="M17.0834 8.19995H2.91675C2.57508 8.19995 2.29175 7.91662 2.29175 7.57495C2.29175 7.23328 2.57508 6.94995 2.91675 6.94995H17.0834C17.4251 6.94995 17.7084 7.23328 17.7084 7.57495C17.7084 7.91662 17.4251 8.19995 17.0834 8.19995Z"
			fill="#36373F" />
		<path
			d="M13.1833 18.983C12.8666 18.983 12.5666 18.8663 12.35 18.6496C12.0916 18.3913 11.975 18.0163 12.0333 17.6246L12.1916 16.4996C12.2333 16.208 12.4083 15.858 12.6166 15.6496L15.5666 12.6996C15.9666 12.2996 16.3583 12.0913 16.7833 12.0496C17.3083 11.9996 17.8166 12.2163 18.3 12.6996C18.8083 13.208 19.4916 14.2413 18.3 15.433L15.35 18.383C15.1416 18.5913 14.7916 18.7663 14.5 18.808L13.375 18.9663C13.3083 18.9746 13.25 18.983 13.1833 18.983ZM16.925 13.2913C16.9166 13.2913 16.9083 13.2913 16.9 13.2913C16.7833 13.2997 16.625 13.408 16.45 13.583L13.5 16.533C13.475 16.558 13.4333 16.6413 13.4333 16.6746L13.2833 17.7163L14.325 17.5663C14.3583 17.558 14.4416 17.5163 14.4666 17.4913L17.4166 14.5413C17.7833 14.1747 17.8333 13.9913 17.4166 13.5746C17.2833 13.4496 17.0916 13.2913 16.925 13.2913Z"
			fill="#36373F" />
		<path
			d="M17.4333 16.0416C17.375 16.0416 17.3167 16.0333 17.2667 16.0167C16.1667 15.7083 15.2917 14.8333 14.9833 13.7333C14.8917 13.4 15.0833 13.0583 15.4167 12.9583C15.75 12.8666 16.0917 13.0583 16.1917 13.3917C16.3833 14.075 16.925 14.6166 17.6083 14.8083C17.9417 14.9 18.1333 15.25 18.0417 15.5833C17.9583 15.8583 17.7083 16.0416 17.4333 16.0416Z"
			fill="#36373F" />
		<path
			d="M10 18.9583H6.66667C3.625 18.9583 1.875 17.2083 1.875 14.1666V7.08329C1.875 4.04163 3.625 2.29163 6.66667 2.29163H13.3333C16.375 2.29163 18.125 4.04163 18.125 7.08329V9.99996C18.125 10.3416 17.8417 10.625 17.5 10.625C17.1583 10.625 16.875 10.3416 16.875 9.99996V7.08329C16.875 4.69996 15.7167 3.54163 13.3333 3.54163H6.66667C4.28333 3.54163 3.125 4.69996 3.125 7.08329V14.1666C3.125 16.55 4.28333 17.7083 6.66667 17.7083H10C10.3417 17.7083 10.625 17.9916 10.625 18.3333C10.625 18.675 10.3417 18.9583 10 18.9583Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'CalendarEditIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
