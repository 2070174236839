<template>
  <div style="margin-top: 63px">
    <!-- Hero -->
    <div class="hero-1">
      <!-- Text -->
      <div class="text-1">
        <!-- Title -->
        <div style="font-weight: 700; font-size: 40px">
          FREQUENTLY ASKED QUESTION
        </div>
        <!-- Description -->
        <div style="font-weight: 400; font-size: 15px">
          Temukan beragam informasi yang kamu butuhkan seputar program HiColleagues di sini!
        </div>
      </div>

    </div>
    <v-row style="padding: 70px 102px; background-color: #F5F6F9;">
      <v-col cols="3">
        <v-card style="padding: 27.5px 30px; border-radius: 20px">
          <div class="d-flex flex-column" style="gap: 15px">
            <div v-for="(item, idx) in program" :key="idx" :class="[
              selectedProgram == idx ? 'program-active' : '',
              'program',
            ]" @click="changeProgram(idx, item.key)">
              {{ item["name"] }}
            </div>
            <v-btn block elevation="0" class="btn-call-b1" @click="sendToWa">
              HUBUNGI KAMI
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-row no-gutters v-if="program[selectedProgram].sub_program" style="gap: 10px">
          <v-chip v-for="(item, idx) in program[selectedProgram].data" :key="idx" :class="[
              selectedSubProgram == idx ? 'sub-program-active' : '',
              'sub-program',
            ]" @click="changeSubProgram(idx, item.key)">{{ item.name }}</v-chip>
        </v-row>
        <!-- no sub program -->
        <v-expansion-panels flat accordion multiple v-if="!program[selectedProgram].sub_program">
          <v-expansion-panel v-for="(item, idx) in dataFaq" :key="idx" class="faq-card">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.description"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- with sub program -->
        <v-expansion-panels v-if="program[selectedProgram].sub_program" style="margin-top: 30px" flat accordion
          multiple>
          <v-expansion-panel v-for="(item, idx) in dataFaq" :key="idx" class="faq-card">
            <v-expansion-panel-header>
              <div>
                {{ item.title }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.description"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <empty-data v-if="!dataFaq.length" message="Belum ada faq saat ini" />

      </v-col>
    </v-row>
    <!-- Call Center -->
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 40px">
        <v-col cols="7">
          <div class="call-center-title">
            Belum Menemukan Jawaban Seputar Program HiColleagues?
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan 24/7 Customer Service HiColleagues
          </div>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4" class="call-center-button" @click="sendToWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import EmptyData from "../../others/EmptyData.vue";

export default {
  components:{
    EmptyData
  },
  data() {
    return {
      selectedProgram: 0,
      selectedSubProgram: 0,
      dataFaq: [],
      program: [
        {
          name: "Umum",
          key: "HOME",
          sub_program: false,
        },
        {
          name: "Bootcamp",
          key: "BOOTCAMP",
          sub_program: false,
        },
        // {
        //   name: "Pelatihan Umum",
        //   key: "PUBLIC_TRAINING",
        //   sub_program: false,
        // },
        {
          name: "Prakerja",
          sub_program: false,
          key: "PRAKERJA",
          // data: [
          //   {
          //     name: "Umum",
          //     key: "PRAKERJA",
          //   },
          //   {
          //     name: "Kelas Webinar",
          //     key: "PRAKERJA_WEBINAR",
          //   },
          // ],
        },
        {
          name: "Corporate Service",
          sub_program: true,
          key: "CORPORATE",
          data: [
            {
              name: "Umum",
              key: "CORPORATE",
            },
            {
              name: "Corp Collaboration",
              key: "COLLABORATION",
            },
            {
              name: "Acceleration Program",
              key: "ACCELERATION",
            },
            {
              name: "Corp Training",
              key: "TRAINING",
            },
            {
              name: "Talent as a Service",
              key: "TALENT_SERVICE",
            },
            {
              name: "Technology Service",
              key: "SERVICES",
            },
          ],
        },
        {
          name: "Career",
          sub_program: false,
          key: "CAREER"
        },
        {
          name: "Event",
          sub_program: false,
          key: "EVENT"
        },
        {
          name: "Kampus Merdeka",
          sub_program: true,
          key: "KAMPUS_MERDEKA",
          data: [
            {
              name: "Umum",
              key: "KAMPUS_MERDEKA"
            },
            {
              name: "Magang Bersertifikat",
              key: "MAGANG"
            },
          ],
        },
      ],
    };
  },
  methods: {
    sendToWa() {
      window.open("https://wa.me/6281234567890", "_blank");
    },
    async getFaqByName(name) {
      try {
        const resp = await this.$http.get(
          `/v1/faq?category=${name}`
        );
        this.dataFaq = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    changeProgram(idx, key) {
      this.selectedProgram = idx;
      this.selectedSubProgram = 0;
      this.getFaqByName(key)
    },
    changeSubProgram(idx, key) {
      this.selectedSubProgram = idx;
      this.getFaqByName(key)
    },
  },
  mounted() {
    this.getFaqByName("HOME")
  }
};
</script>

<style src="./faq.css" scoped></style>