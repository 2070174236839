<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 18.5415C9.31667 18.5415 8.63333 18.3248 8.06667 17.8998L3.72501 14.6498C2.91667 14.0415 2.43335 13.0748 2.43335 12.0665V1.46649H17.5667V12.0665C17.5667 13.0748 17.0833 14.0415 16.275 14.6498L11.9333 17.8998C11.3667 18.3248 10.6833 18.5415 10 18.5415ZM3.68335 2.70816V12.0582C3.68335 12.6748 3.98333 13.2665 4.475 13.6415L8.81666 16.8915C9.51666 17.4165 10.4917 17.4165 11.1917 16.8915L15.5334 13.6415C16.025 13.2665 16.325 12.6748 16.325 12.0582V2.70816H3.68335Z"
			fill="#36373F" />
		<path
			d="M18.3334 2.70831H1.66675C1.32508 2.70831 1.04175 2.42498 1.04175 2.08331C1.04175 1.74165 1.32508 1.45831 1.66675 1.45831H18.3334C18.6751 1.45831 18.9584 1.74165 18.9584 2.08331C18.9584 2.42498 18.6751 2.70831 18.3334 2.70831Z"
			fill="#36373F" />
		<path
			d="M13.3334 7.29169H6.66675C6.32508 7.29169 6.04175 7.00835 6.04175 6.66669C6.04175 6.32502 6.32508 6.04169 6.66675 6.04169H13.3334C13.6751 6.04169 13.9584 6.32502 13.9584 6.66669C13.9584 7.00835 13.6751 7.29169 13.3334 7.29169Z"
			fill="#36373F" />
		<path
			d="M13.3334 11.4583H6.66675C6.32508 11.4583 6.04175 11.175 6.04175 10.8333C6.04175 10.4916 6.32508 10.2083 6.66675 10.2083H13.3334C13.6751 10.2083 13.9584 10.4916 13.9584 10.8333C13.9584 11.175 13.6751 11.4583 13.3334 11.4583Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'BillIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
