import { render, staticRenderFns } from "./Prakerja.vue?vue&type=template&id=3b86e8b6&scoped=true&"
import script from "./Prakerja.vue?vue&type=script&lang=js&"
export * from "./Prakerja.vue?vue&type=script&lang=js&"
import style0 from "./css/prakerja.css?vue&type=style&index=0&id=3b86e8b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b86e8b6",
  null
  
)

export default component.exports