<template>
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 4.3125C6.1925 4.3125 5.9375 4.0575 5.9375 3.75V1.5C5.9375 1.1925 6.1925 0.9375 6.5 0.9375C6.8075 0.9375 7.0625 1.1925 7.0625 1.5V3.75C7.0625 4.0575 6.8075 4.3125 6.5 4.3125Z" fill="#7B7E8C"/>
    <path d="M12.5 4.3125C12.1925 4.3125 11.9375 4.0575 11.9375 3.75V1.5C11.9375 1.1925 12.1925 0.9375 12.5 0.9375C12.8075 0.9375 13.0625 1.1925 13.0625 1.5V3.75C13.0625 4.0575 12.8075 4.3125 12.5 4.3125Z" fill="#7B7E8C"/>
    <path d="M15.875 7.37997H3.125C2.8175 7.37997 2.5625 7.12497 2.5625 6.81747C2.5625 6.50997 2.8175 6.25497 3.125 6.25497H15.875C16.1825 6.25497 16.4375 6.50997 16.4375 6.81747C16.4375 7.12497 16.1825 7.37997 15.875 7.37997Z" fill="#7B7E8C"/>
    <path d="M12.5 17.0625H6.5C3.7625 17.0625 2.1875 15.4875 2.1875 12.75V6.375C2.1875 3.6375 3.7625 2.0625 6.5 2.0625H12.5C15.2375 2.0625 16.8125 3.6375 16.8125 6.375V12.75C16.8125 15.4875 15.2375 17.0625 12.5 17.0625ZM6.5 3.1875C4.355 3.1875 3.3125 4.23 3.3125 6.375V12.75C3.3125 14.895 4.355 15.9375 6.5 15.9375H12.5C14.645 15.9375 15.6875 14.895 15.6875 12.75V6.375C15.6875 4.23 14.645 3.1875 12.5 3.1875H6.5Z" fill="#7B7E8C"/>
    <path d="M6.875 10.875C6.7775 10.875 6.68 10.8525 6.59 10.815C6.5 10.7775 6.41751 10.725 6.34251 10.6575C6.27501 10.5825 6.22249 10.5 6.18499 10.41C6.14749 10.32 6.125 10.2225 6.125 10.125C6.125 9.93001 6.20751 9.73502 6.34251 9.59252C6.41751 9.52502 6.5 9.4725 6.59 9.435C6.725 9.375 6.87501 9.36 7.02501 9.39C7.07001 9.3975 7.115 9.4125 7.16 9.435C7.205 9.45 7.25 9.47252 7.295 9.50252C7.3325 9.53252 7.36999 9.56252 7.40749 9.59252C7.43749 9.63002 7.47499 9.66751 7.49749 9.70501C7.52749 9.75001 7.55001 9.79501 7.56501 9.84001C7.58751 9.885 7.60251 9.93 7.61001 9.975C7.61751 10.0275 7.625 10.0725 7.625 10.125C7.625 10.32 7.54249 10.515 7.40749 10.6575C7.26499 10.7925 7.07 10.875 6.875 10.875Z" fill="#7B7E8C"/>
    <path d="M9.5 10.875C9.305 10.875 9.11001 10.7925 8.96751 10.6575C8.93751 10.62 8.90751 10.5825 8.87751 10.545C8.84751 10.5 8.82499 10.455 8.80999 10.41C8.78749 10.365 8.77249 10.32 8.76499 10.275C8.75749 10.2225 8.75 10.1775 8.75 10.125C8.75 10.0275 8.77249 9.92999 8.80999 9.83999C8.84749 9.74999 8.90001 9.6675 8.96751 9.5925C9.17751 9.3825 9.515 9.31499 9.785 9.43499C9.8825 9.47249 9.95749 9.525 10.0325 9.5925C10.1675 9.735 10.25 9.93 10.25 10.125C10.25 10.1775 10.2425 10.2225 10.235 10.275C10.2275 10.32 10.2125 10.365 10.19 10.41C10.175 10.455 10.1525 10.5 10.1225 10.545C10.0925 10.5825 10.0625 10.62 10.0325 10.6575C9.95749 10.725 9.8825 10.7775 9.785 10.815C9.695 10.8525 9.5975 10.875 9.5 10.875Z" fill="#7B7E8C"/>
    <path d="M6.875 13.5C6.7775 13.5 6.68 13.4775 6.59 13.44C6.5 13.4025 6.41751 13.35 6.34251 13.2825C6.27501 13.2075 6.22249 13.1325 6.18499 13.035C6.14749 12.945 6.125 12.8475 6.125 12.75C6.125 12.555 6.20751 12.36 6.34251 12.2175C6.41751 12.15 6.5 12.0975 6.59 12.06C6.8675 11.94 7.19749 12.0075 7.40749 12.2175C7.43749 12.255 7.47499 12.2925 7.49749 12.33C7.52749 12.375 7.55001 12.42 7.56501 12.465C7.58751 12.51 7.60251 12.555 7.61001 12.6075C7.61751 12.6525 7.625 12.705 7.625 12.75C7.625 12.945 7.54249 13.14 7.40749 13.2825C7.26499 13.4175 7.07 13.5 6.875 13.5Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Calendar2",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  }
}
</script>

<style></style>