<template>    
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5599 11.0385L20.1999 9.45846C19.9399 9.15846 19.7299 8.59846 19.7299 8.19846V6.49846C19.7299 5.43846 18.8599 4.56846 17.7999 4.56846H16.0999C15.7099 4.56846 15.1399 4.35846 14.8399 4.09846L13.2599 2.73846C12.5699 2.14846 11.4399 2.14846 10.7399 2.73846L9.16988 4.10846C8.86988 4.35846 8.29988 4.56846 7.90988 4.56846H6.17988C5.11988 4.56846 4.24988 5.43846 4.24988 6.49846V8.20846C4.24988 8.59846 4.03988 9.15846 3.78988 9.45846L2.43988 11.0485C1.85988 11.7385 1.85988 12.8585 2.43988 13.5485L3.78988 15.1385C4.03988 15.4385 4.24988 15.9985 4.24988 16.3885V18.0985C4.24988 19.1585 5.11988 20.0285 6.17988 20.0285H7.90988C8.29988 20.0285 8.86988 20.2385 9.16988 20.4985L10.7499 21.8585C11.4399 22.4485 12.5699 22.4485 13.2699 21.8585L14.8499 20.4985C15.1499 20.2385 15.7099 20.0285 16.1099 20.0285H17.8099C18.8699 20.0285 19.7399 19.1585 19.7399 18.0985V16.3985C19.7399 16.0085 19.9499 15.4385 20.2099 15.1385L21.5699 13.5585C22.1499 12.8685 22.1499 11.7285 21.5599 11.0385ZM16.1599 10.4085L11.3299 15.2385C11.1899 15.3785 10.9999 15.4585 10.7999 15.4585C10.5999 15.4585 10.4099 15.3785 10.2699 15.2385L7.84988 12.8185C7.55988 12.5285 7.55988 12.0485 7.84988 11.7585C8.13988 11.4685 8.61988 11.4685 8.90988 11.7585L10.7999 13.6485L15.0999 9.34846C15.3899 9.05846 15.8699 9.05846 16.1599 9.34846C16.4499 9.63846 16.4499 10.1185 16.1599 10.4085Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Verify",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
  },
};
</script>