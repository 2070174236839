<template>
    <div class="partner">
      <div class="partner-title text-center">
        <h1>{{ title }}</h1>
      </div>
      <div class="partner-content">
        <div class="partner-container">
          <div
            class="card col-12 cursor-pointer"
            v-for="(item, idx) in clona" :key="idx" :title="item.name"
          >
            <div class="portfolio-card">
              <div class="event-box"  v-for="(item2, idx2) in item" :key="idx2">
            <img
              :src="item2.thumbnail_url"
              alt="Content"
              class="event-box-img"
              @click="toDetail(item2)"
            />
            <div class="event-ctn">
              <div @click="toDetail(item2)">
                <h3 class="judul-event" :title="item2.name">{{ item2.name }}</h3>
                <div class="event-ctn-label">
                  <span>Webinar</span>
                </div>
                <div class="event-ctn-info">
                  <DateIcon fill="#575966" />
                  <span class="name">{{ moment(item2.registration_close_date) }}</span>
                </div>
                <div class="event-ctn-info">
                  <clock fill="#575966" />
                  <span class="name">{{ moment(item2.registration_close_date, 'HH:mm') }} WIB</span>
                </div>
                <div class="event-ctn-info">
                  <people fill="#575966" />
                  <span class="name"
                    >50 Peserta</span
                  >
                </div>
                <div class="event-ctn-info">
                  <methode fill="#575966" />
                  <span class="name">{{ item2.method | toMethode }}</span>
                </div>
                <div class="event-ctn-info" v-if="item2.instructor">
                  <speaker fill="#575966" />
                  <span class="name"
                    >{{ item2.instructor.name}}</span>
                </div>
              </div>
              <div
                style="display: flex; justify-content: center; margin: 10px 0"
              >
                <Button
                  name="TUKAR VOUCHER"
                  width="250px"
                  @click="() => toCheckout(item2)"
                />
              </div>
            </div>
               </div>
            </div>
        </div>
        </div>
      </div>
      <div class="partner-button">
        <div class="slider">
          <div
            class="indicator"
            :style="{
              width: `${sliderIndicatorTesti}%`,
              transform: `translateX(${indicatorValueTesti}%)`,
              transition: `transform 0.5s`,
            }"
          ></div>
        </div>
        <div style="margin-right: 20px; display: flex;">
          <img class="arrow-button" src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftPartner" />
          <img class="arrow-button" src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightPartner" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
import moment from "moment";
import Button from "../others/Button.vue";
import Clock from "./icons/Clock.vue";
import DateIcon from "./icons/Date.vue";
import Speaker from "./icons/Speaker.vue";
import Methode from "./icons/Methode.vue";
import People from './icons/People.vue';

  export default {
    name: "PrakerjaComponent",
    components: {
    Button,
    DateIcon,
    Clock,
    Methode,
    Speaker,
    People
  },
    props: {
      partners: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        clona: [],
        sliderIndicatorTesti: 0,
        indicatorValueTesti: 0,
      };
    },
    watch: {
      partners() {
        this.clona = []
        for (let i = 0; i < this.partners.length; i += 2) {
          const group = this.partners.slice(i, i + 2);
          this.clona.push(group);
         }
        this.sliderIndicatorTesti = (1 / this.clona.length) * 100;
        if (this.sliderIndicatorTesti >= 100) {
          this.sliderIndicatorTesti = 100;
        }
      },
    },
    methods: {
      scrollRightPartner() {
        const container = this.$el.querySelector(".partner-container");
        const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
        container.scrollLeft += cardWidth;
        this.indicatorValueTesti +=
          this.sliderIndicatorTesti +
          ((this.clona.length - 1) * 100) / this.clona.length;
        if (this.indicatorValueTesti >= (this.clona.length - 1) * 100) {
          this.indicatorValueTesti = (this.clona.length - 1) * 100;
        }
      },
      scrollLeftPartner() {
        const container = this.$el.querySelector(".partner-container");
        const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
        container.scrollLeft -= cardWidth;
        this.indicatorValueTesti -=
          this.sliderIndicatorTesti +
          ((this.clona.length - 1) * 100) / this.clona.length;
        if (this.indicatorValueTesti <= 0) {
          this.indicatorValueTesti = 0;
        }
      },
      toDetail(item) {
      this.$router.push("/class/prakerja/" + item.slug_name);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.clona.forEach((item) => {
          item.forEach((item2) => {
            const end = new Date(item2.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item2.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item2.expired = [days, hours, minutes, seconds];
          })
        });
      };
      setInterval(showRemaining, 1000);

      },
      checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    moment(date, val) {
      if (!val) {
        return moment(date).utc().format("dddd, DD MMMM YYYY");  
      }
      return moment(date).utc().format(val);  
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants  
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckout(data) {
      await this.$http
        .get("/v1/class-detail/" + data.slug_name)
        .then((response) => {
          const classDetail = response.data.data.class_detail;
          const reqCheckout = JSON.stringify({
            class_id: classDetail.class_id,
            class_name: classDetail.name,
            price: classDetail.price,
            level: classDetail.level,
            session: classDetail.session_total,
            hour: classDetail.hours,
            image_url: classDetail.small_image_url,
            class_type: "PRAKERJA_CLASS",
            days: classDetail.days,
            methode: classDetail.method,
            category_id: classDetail.category_id,
          });

          localStorage.clear();
          localStorage.setItem("checkout", reqCheckout);
          window.location.href = "/checkout";
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    }
    },
    mounted() {
    this.countDownTimer();
  },
  };
  </script>
  
  <style src="./css/prakerja.css" scoped></style>