<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7583 12.5167C12.275 12.5167 11.75 12.3584 11.225 12.0334L10.125 11.3667C10.075 11.3334 9.92498 11.3334 9.87498 11.3667L8.77498 12.0334C7.87498 12.5834 6.99165 12.6667 6.28331 12.2667C5.59165 11.8751 5.20831 11.0917 5.20831 10.0667V4.19175C5.20831 2.15841 6.31665 1.04175 8.33331 1.04175H11.6666C13.6833 1.04175 14.7916 2.15841 14.7916 4.19175V10.0667C14.7916 11.1001 14.4083 11.8834 13.7166 12.2667C13.425 12.4334 13.1 12.5167 12.7583 12.5167ZM9.99998 10.0917C10.275 10.0917 10.55 10.1584 10.775 10.2917L11.875 10.9584C12.3666 11.2584 12.8166 11.3334 13.1 11.1751C13.375 11.0167 13.5333 10.6084 13.5333 10.0584V4.19175C13.5333 2.85841 12.975 2.29175 11.6583 2.29175H8.32498C7.00831 2.29175 6.44998 2.85841 6.44998 4.19175V10.0667C6.44998 10.6167 6.60831 11.0251 6.88331 11.1834C7.16665 11.3417 7.61665 11.2667 8.10831 10.9667L9.20831 10.3001C9.44998 10.1584 9.72498 10.0917 9.99998 10.0917Z"
      fill="#36373F"
    />
    <path
      d="M12.5 18.9584H7.49999C2.97499 18.9584 1.04166 17.0084 1.04166 12.4418V9.91675C1.04166 6.03342 2.44166 4.06675 5.57499 3.54175C5.90832 3.48342 6.23332 3.71675 6.29166 4.05842C6.34999 4.40009 6.11666 4.71675 5.77499 4.77509C3.26666 5.19175 2.28332 6.63342 2.28332 9.91675V12.4418C2.28332 16.2834 3.69166 17.7084 7.49166 17.7084H12.4917C16.2917 17.7084 17.7 16.2834 17.7 12.4418V9.91675C17.7 6.58342 16.6833 5.14175 14.0667 4.75009C13.725 4.70009 13.4917 4.38342 13.5417 4.04175C13.5917 3.70009 13.9083 3.46675 14.25 3.51675C17.5 4.00009 18.95 5.96675 18.95 9.91675V12.4418C18.9583 17.0084 17.025 18.9584 12.5 18.9584Z"
      fill="#36373F"
    />
  </svg>
</template>

<script>
export default {
  name: "Bookmark2",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
