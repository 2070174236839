<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 18.9584C9.44181 18.9584 8.87514 18.8167 8.37514 18.5251L3.42514 15.6667C2.42514 15.0834 1.80014 14.0084 1.80014 12.8501V7.1501C1.80014 5.99176 2.42514 4.91676 3.42514 4.33342L8.37514 1.4751C9.37514 0.891764 10.6168 0.891764 11.6251 1.4751L16.5751 4.33342C17.5751 4.91676 18.2001 5.99176 18.2001 7.1501V12.8501C18.2001 14.0084 17.5751 15.0834 16.5751 15.6667L11.6251 18.5251C11.1251 18.8167 10.5585 18.9584 10.0001 18.9584ZM10.0001 2.29175C9.65847 2.29175 9.30847 2.38342 9.00014 2.55842L4.05014 5.41675C3.43347 5.77508 3.05014 6.43343 3.05014 7.1501V12.8501C3.05014 13.5584 3.43347 14.2251 4.05014 14.5834L9.00014 17.4417C9.61681 17.8001 10.3835 17.8001 11.0001 17.4417L15.9501 14.5834C16.5668 14.2251 16.9501 13.5667 16.9501 12.8501V7.1501C16.9501 6.44176 16.5668 5.77508 15.9501 5.41675L11.0001 2.55842C10.6918 2.38342 10.3418 2.29175 10.0001 2.29175Z"
      :fill="fill"
    />
    <path
      d="M9.99996 9.79151C8.58329 9.79151 7.43329 8.6415 7.43329 7.22483C7.43329 5.80817 8.58329 4.6582 9.99996 4.6582C11.4166 4.6582 12.5666 5.80817 12.5666 7.22483C12.5666 8.6415 11.4166 9.79151 9.99996 9.79151ZM9.99996 5.9082C9.27496 5.9082 8.68329 6.49983 8.68329 7.22483C8.68329 7.94983 9.27496 8.54151 9.99996 8.54151C10.725 8.54151 11.3166 7.94983 11.3166 7.22483C11.3166 6.49983 10.725 5.9082 9.99996 5.9082Z"
      :fill="fill"
    />
    <path
      d="M13.3334 14.5086C12.9917 14.5086 12.7084 14.2253 12.7084 13.8836C12.7084 12.7336 11.4917 11.792 10 11.792C8.50835 11.792 7.29169 12.7336 7.29169 13.8836C7.29169 14.2253 7.00835 14.5086 6.66669 14.5086C6.32502 14.5086 6.04169 14.2253 6.04169 13.8836C6.04169 12.0419 7.81669 10.542 10 10.542C12.1834 10.542 13.9584 12.0419 13.9584 13.8836C13.9584 14.2253 13.675 14.5086 13.3334 14.5086Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "UsersOctagon",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    fill: {
      type: String,
      default: '#36373F'
    }
  },
};
</script>
