<template>
  <div>
    <div style="background: #162ca2; border-radius: 24px; padding: 48px 0px">
      <img
        style="width: 100%; border-bottom-left-radius: 64px"
        src="/img/main.jpg"
        alt=""
      />
      <div class="text-center" style="padding: 0px 16px 0px 16px">
        <h2
          class="sub-title-span-white"
          style="font-weight: 700; font-size: 22px; line-height: 32px"
        >
          Tingkatkan Kompetensi Era Digitalisasi dengan Kelas Pilihan
        </h2>

        <p
          class="sub-title-span-white"
          style="
            margin-top: 20px;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
          "
        >
          <span style="font-weight: 600; font-size: 13px; line-height: 22px">
            #MulaiLangkahHebat</span
          >
          dengan temukan kelas terbaik yang cocok untuk tingkatkan kompetensi
          dalam transformasi digital berkelanjutan dan siap jadi talenta
          profesional!
        </p>

        <div style="display: flex; flex-direction: column; gap: 10px">
          <button
            style="
              background-color: #ffde59;
              height: 40px;
              border-radius: 8px;
              font-size: 14px;
              color: #36373f;
              line-height: 20px;
              font-weight: 600;
            "
            @click="scrollToSection('public-training-class')"
          >
            LIHAT KELAS
          </button>
          <button
            style="
              background-color: #ffffff;
              height: 40px;
              border-radius: 8px;
              font-size: 14px;
              color: #162ca2;
              line-height: 20px;
              font-weight: 600;
            "
            @click="sendToWa"
          >
            KONSULTASI GRATIS
          </button>
        </div>
      </div>
    </div>

    <div>
      <div>
        <h2
          style="
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #36373f;
            text-align: center;
          "
        >
          HiColleagues Pilihan Tepat Akselerasi Skill Digital
        </h2>

        <div
          style="
            display: flex;
            overflow: auto;
            gap: 16px;
            padding: 0px 16px 20px 16px;
          "
        >
          <div v-for="n in benefits" :key="n.id" class="content-card">
            <div class="left-span">
              <img :src="n.image" width="48" height="48" />
            </div>
            <div>
              <div class="title-2" style="text-align: left">{{ n.title }}</div>
              <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2
          style="
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #36373f;
            text-align: center;
            margin: 48px 0;
          "
        >
          Jelajahi Kategori Kelas Video Pembelajaran HiColleagues
        </h2>
        <div
          style="
            display: flex;
            overflow: auto;
            gap: 16px;
            padding: 0px 16px 20px 16px;
          "
        >
          <div v-for="n in kategori" :key="n.id" :class="`content-card-2 ${layout == n.id ? 'active-bb' :''}`"  @click="changeCategory(n.id)">
            <div class="icons-span">
              <Cpu v-if="n.image === 'Cpu'" />
              <Messages v-if="n.image === 'Messages'" />
              <HeartCircle v-if="n.image === 'HeartCircle'" />
            </div>
            <div>
              <div class="title-2" style="text-align: left">{{ n.title }}</div>
              <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2
        style="
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: #36373f;
          text-align: center;
          margin: 48px 0;
        "
      >
        Rekomendasi Kelas HiColleagues
      </h2>
      <div
        style="
          display: flex;
          overflow: auto;
          gap: 16px;
          padding: 0px 16px 20px 16px;
        "
      >
        <div v-for="(data, idx) in classRecommends" :key="idx" class="content-card-3" @click="$router.push('/class/public-training/' + data.slug_name);">
          <div style="height: 164px; overflow: hidden">
            <img
              style="
                width: 100%;
                min-height: 164px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
              "
               :src="data.thumbnail_url"
              class=""
            />
          </div>
          <div style="padding: 16px">
            <div style="display: flex; gap: 4px; margin: 16px 0px 8px 0px">
              <div
                :style="{
                  textAlign: 'center',
                  backgroundColor: '#f1f6ff',
                  borderRadius: '30px',
                }"
              >
                <p class="level-btn">
                  {{ data.level }}
                </p>
              </div>
              <div
                :style="{
                  textAlign: 'center',
                  backgroundColor: '#faf6e7',
                  borderRadius: '30px',
                }"
              >
                <p class="level-btn">
                  {{ data.method || 'Online/Offline' }}
                </p>
              </div>
              <div
                :style="{
                  textAlign: 'center',
                  backgroundColor: '#faeceb',
                  borderRadius: '30px',
                }"
                v-if="data.count_session"
              >
                <p class="level-btn">
                  {{ data.count_session }}
                </p>
              </div>
            </div>
            <div>
              <div class="title-2" style="text-align: left">{{ data.name }}</div>
              <div style="height: 65px">
                <div class="desc mt-2" style="text-align: left">{{ data.description }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!classRecommends.length">
          <div class="text-center" style="margin: 20px 0">
            <img src="/img/other/ic_coming_soon.png" height="300" />
          </div>
        </div>
      </div>
    </div>
    <div id="public-training-class" style="margin-top: 50px;">
      <TechnologyCategory v-if="layout == 1" class="bg-white-radius" />
      <LanguageCategory v-if="layout == 2" class="bg-white-radius" />
      <SoftSkillCategory v-if="layout == 3" class="bg-white-radius" />
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <AccordeonFaq v-for="(item, index) in questions" :key="index" :title="item.title"
            :description="item.description" :active="item.active" @toggle="toggleAccordion(index)" />
          <div class="btn-stoke-blue cursor-pointer mt-5" @click="$router.push('/faq')">
            <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            <ArrowLong />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cpu from "@/assets/icons/Cpu.vue";
import Messages from "@/assets/icons/Messages.vue";
import HeartCircle from "@/assets/icons/HeartCircle.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import AccordeonFaq from "@/components/AccordeonFaq.vue";

import TechnologyCategory from "@/components/public-training/mobile/TechnologyCategory";
import LanguageCategory from "@/components/public-training/mobile/LanguageCategory";
import SoftSkillCategory from "@/components/public-training/mobile/SoftSkillCategory";

export default {
  components: {
    TechnologyCategory,
    LanguageCategory,
    SoftSkillCategory,
    Cpu,
    Messages,
    HeartCircle,
    ArrowLong,
    AccordeonFaq
  },
  data() {
    return {
      layout: 0,
      benefits: [
        {
          title: "Intensive Program",
          desc: "Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
          image: "/img/bootcamp/ic_intensive_program.png",
        },
        {
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
          image: "/img/bootcamp/ic_competent_trainer.png",
        },
        {
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
          image: "/img/bootcamp/ic_sertifikat_kompetensi.png",
        },
        {
          title: "Job Connector",
          desc: "Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp",
          image: "/img/bootcamp/ic_job_recommentation.png",
        },
        {
          title: "Installment Option Available",
          desc: "Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta",
          image: "/img/bootcamp/ic_installment.png",
        },
      ],
      kategori: [
        {
          id: 1,
          title: "Kelas Teknologi",
          desc: "Pelajari teknologi terkini dan tingkatkan keahlian digital Anda.",
          image: "Cpu",
        },
        {
          id: 2,
          title: "Kelas Bahasa",
          desc: "Kuasi bahasa baru untuk memperluas peluang komunikasi global.",
          image: "Messages",
        },
        {
          id: 3,
          title: "Kelas Soft Skill",
          desc: "Kembangkan kemampuan interpersonal untuk sukses profesional.",
          image: "HeartCircle",
        },
      ],
      
      classRecommends: [],
      questions: [],
    };
  },
  methods: {
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=PUBLIC_TRAINING&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getDataClassRecomendation() {
			await this.$http
				.get(`/v1/class?is_recommend=true`)
				.then((response) => {
					this.classRecommends = response.data.data.classes
				})
				.catch((err) => console.error(err.response))
		},
    sendToWa() {
			window.open(
				'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya',
			);
		},
    changeCategory(categoryId) {
			this.$router.replace({ path: '/class/public-training', query: { category:categoryId } });
      window.location.reload()
		},
		scrollToSection(sectionId) {
			const element = document.getElementById(sectionId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		},
  },
  mounted() {
    this.layout = this.$route.query.category ?? 1;
    this.getFaq();
    this.getDataClassRecomendation()
  },
};
</script>

<style src="./css/publicTraining.css" scoped></style>
