<template>
    <div class="background">
        <div class="outer-container-portfolio">
            <div class="navigation">
                <span @click="$router.push('/')" class="nav-active font-poppins">Beranda</span>
                <Arrow :width="20" direct="left" />
                <span @click="$router.push('/portfolio')" class="nav-active font-poppins">Portfolio</span>
                <Arrow :width="20" direct="left" />
                <span class="nav-active font-poppins">{{ categoryText(portfolio.category) }}</span>
                <Arrow :width="20" direct="left" />
                <span class="font-poppins nav-inactive">Detail Proyek</span>
            </div>
            <div class="container-portfolio">
                <img loading="lazy" :src="portfolio.image_url" class="portfolio-main-img" />
                <div class="container-portfolio-content">
                    <div class="container-dashboard-manager">
                        <h2>Dashboard Manager</h2>
                        <div class="container-dashboard-manager-detail">
                            <div class="dashboard-manager-detail">
                                <span>Clien</span>
                                <p>{{ portfolio.title }}</p>
                            </div>
                            <div class="dashboard-manager-detail">
                                <span>Timeline</span>
                                <p>{{ $utils.getDate(portfolio.start_date, 'YYYY') }}</p>
                            </div>
                            <div class="dashboard-manager-detail">
                                <span>Device</span>
                                <p>{{ portfolio.device.map(item => item.name).join(", ") }}</p>
                            </div>
                            <div class="dashboard-manager-detail">
                                <span>Link</span>
                                <a :href="portfolio.link_project" target="_blank">{{ portfolio.link_project }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="container-portfolio-content-info" v-if="portfolio.body">
                        <h2>Detail Proyek</h2>
                        <p class="portfolio-body" v-html="portfolio.body" style="margin-top: 16px;"></p>
                    </div>
                    <div class="container-portfolio-content-info">
                        <h2>Tanggung Jawab Kami</h2>
                        <p class="portfolio-body"  v-html="portfolio.responsibility"></p>
                    </div>
                    <div class="container-portfolio-sop" v-if="portfolio.scope_project.length && scopeOpened">
                        <h2>Lingkup Proyek</h2>
                        <div class="container-sop-list">
                            <div class="sop-parent-box" v-for="(item, idx) in portfolio.scope_project" :key="idx">
                                <div class="sop-box">
                                    <img :src="item.value" alt="">
                                    <p>{{ utem.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-portfolio-content-technology">
                        <h2>Teknologi</h2>
                        <div class="container-technology-list">
                            <div class="technology-box" v-for="(item, idx) in portfolio.technology" :key="idx">
                                <img :src="item.value" alt="">
                                <p>{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="container-portfolio-content-doc">
                        <h2>Dokumentasi</h2>
                        <div class="container-doc-list">
                            <img :src="item.img" alt="" v-for="(item, idx) in portfolio.key_feature" :key="idx">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px 80px">
                <v-col cols="8">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="3" class="call-center-button" @click="handleToCorporatePage">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";

export default {
    name: "DekstopPortfolioDetail",
    components: {
        Arrow,
    },
    data() {
        return {
            portfolio: {
                image_url: "",
                title: "",
                label: "",
                body: "",
                program: "",
                category: "SERVICES",
                start_date: "",
        responsibility: "",
        link_project: "",
                scope_project: [],
                key_feature: [],
                technology: [],
        device: [], 
                created_at: ""
            },
            categoryList: [
                {
                    key: "SERVICES",
                    value: "Technology Service"
                },
                {
                    key: "COLLABORATION",
                    value: "Corporate Collaboration"
                },
                {
                    key: "TALENT_SERVICE",
                    value: "Talent as a Service"
                },
                { key: "Training", value: "TRAINING" },
            ],
            scopeOpened: false
        }
    },
    methods: {
        handleToCorporatePage() {
            return this.$router.push('/corporate');
        },
        toRedirect(to) {
            window.open(to)
        },
        categoryText(category) {
            return this.categoryList.find((c) => c.key == category).value;
        },
        async getPortfolioDetail() {
            try {
                const resp = await this.$http.get(`/v1/project-portfolio/${this.$route.params.id}`)
                this.portfolio = resp.data.data.project_portfolio
            } catch (err) {
                console.log(err);
            }
        },
        applyStyleContentInfo() {
            this.$nextTick(() => {
                const pElement = this.$el.querySelectorAll('.portfolio-body p span');
                pElement.forEach((element) => {
                    element.style.fontSize = '16px';
                    element.style.fontWeight = 400;
                    element.style.fontFamily = "Poppins, sans-serif";
                    element.style.lineHeight = '32px';
                    element.style.color = '#36373F';
                })
            })
        }
    },
    mounted() {
        this.getPortfolioDetail()
        this.applyStyleContentInfo()
    },
    updated() {
        this.applyStyleContentInfo()
    }
}
</script>

<style scoped src="./detail.css"></style>