<template>
  <div style="padding-top: 60px">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      @close="isAlertErr = false"
    />
    <div>
      <div class="head-class" ref="ctn_head">
        <div class="left">
          <div class="d-flex">
            <div class="btn-white-oval font-poppins">Beginner Friendly</div>
            <div class="btn-white-oval font-poppins">Competent & Certified Instructors</div>
            <div class="btn-white-oval font-poppins">Live Class</div>
          </div>

          <p class="text-banner">{{ classDetail.name }}</p>
          <p class="description-class">
            Tingkatkan skill menjadi profesional dengan Job Guarantee Program
            dan dapatkan unlimited career support sampai kamu diterima bekerja.
          </p>
          <v-btn depressed color="#FFDE59" @click="goToCheckoutPage" class="button-daftar">
            <span class="text-capitalize font-weight-bold blue-text font-poppins" @click="goToCheckoutPage">DAFTAR
              SEKARANG</span>
          </v-btn>
          <v-btn depressed color="#FFFFFF" @click="sendToWa" class="button-daftar" style="margin-left: 30px">
            <span class="text-capitalize font-weight-bold blue-text font-poppins">KONSULTASI GRATIS</span>
          </v-btn>
        </div>
        <img :src="classDetail.image_url" class="img-banner-1" />
      </div>
      <div class="box-radius">
        <div v-for="n in menuPublicClass" :key="n.id" class="menu-detail"
          @click="clickActiveMenu(n.layout, n.position)">
          <span>{{ n.name }}</span>
          <div :class="{ linesmall: layout === n.layout }"></div>
        </div>

        <v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="
            margin-top: 39px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          ">
          <span class="text-capitalize font-weight-bold white--text"
            style="font-size: 16px; letter-spacing: 0 !important">DAFTAR SEKARANG</span>
        </v-btn>
        <v-btn outlined color="gray" style="
            margin-top: 10px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          " @click="sendToWa()">
          <span class="text-capitalize font-weight-bold"
            style="font-size: 16px; letter-spacing: 0 !important">KONSULTASI SEKARANG</span>
        </v-btn>
      </div>
      <div style="background-color: #F5F6F9; margin-top: -580px; padding: 60px 0;">
        <div class="container-parent" style="" ref="overview">
          <div class="container-detail">
            <span class="addional">Overview</span>
            <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
              Mengapa Memulai Karir sebagai {{ classDetail.name }}
            </h5>
            <div class="cada-parent">
              <div v-for="(data, idx) in overview" :key="idx" class="card-sayhay">
                <components :is="data.icon"></components>
                <h3>{{ data.title }}</h3>
                <p style="font-size: 14px" class="mt-4">{{ data.desc }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-parent" ref="kenapa_hicolleagues" style="margin: 50px 0">
          <div class="container-detail">
            <span class="addional">Mengapa HiColleagues</span>
            <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
              Wujudkan Karir Impian sebagai {{ classDetail.name }} dengan Intensive Program Bootcamp HiColleagues
            </h5>
            <div class="why-hicoll mt-5">
              <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
                <div>
                  <img :src="item.img" width="60" alt="Ctn" />
                </div>
                <div>
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.desc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-parent" ref="taget_peserta" style="padding: 50px 0">
          <div class="container-detail">
            <span class="addional">Target Peserta</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Kriteria Ideal Peserta Program Akselerasi Digital Bootcamp
            </h5>
            <div style="display: flex; gap: 20px;" class="mt-5">
              <div style="width: 40%;">
                <img src="/img/class/main.png" alt="" style="width: 100%; border-radius: 16px;">
              </div>
              <div>
                <ul>
                  <li v-for="(item, idx) in classDetail.target_audience" :key="idx">
                    {{ item.level }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-container" style="margin-bottom: 50px;" ref="materi_silabus">
        <div class="container-detail" style="padding-top: 45px; padding: 40px 0px">
          <span class="addional">Materi Silabus</span>
          <h5 class="title mb-5" style="font-family: 'Poppins', sans-serif !important">
            Materi yang Akan HiColers Pelajari
          </h5>
          <!-- <p class="mb-4">Lorem ipsum dolor sit amet consectetur. Id nullam lorem interdum proin nisi. Amet ut ullamcorper eu vestibulum et cras lobortis. Convallis purus ut ultricies tempor euismod eget. Massa sed quis orci nunc tellus dolor id metus.</p> -->
          <div v-for="(item, idx) in classDetail.summary_syllabus" :key="idx" class="faq-box"
            @click="item.show = !item.show">
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
              <Arrow :down="item.show" fill="#36373f" />
            </div>
            <p v-if="item.show" v-html="item.value" class="faq-box-active"></p>
          </div>
        </div>
      </div>
      <div style="background-color: #F5F6F9; margin-bottom: -14px; padding-bottom: 20px;">
        <div class="container-parent" ref="metode_belajar" style="padding-top: 120px">
          <div class="container-detail">
            <span class="addional">Metode Belajar</span>
            <h5 class="title" style="
                font-family: 'Poppins', sans-serif !important;
                line-height: 30px;
              ">
              Ikuti Bootcamp HiColleagues dengan Metode Belajar yang Konstruktif
            </h5>
            <div class="why-hicoll mt-8">
              <div v-for="(item, idx) in methodLearning" :key="idx">
                <div class="circle-yellow">
                  <components :is="item.img"  alt="Ctn"></components>
                </div>
                <h3 class="mt-3">{{ item.title }}</h3>
                <p style="color: #36373f">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-parent mt-10" ref="instruktur" style="margin: 50px 0">
          <div class="container-detail">
            <span class="addional">Instruktur</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Belajar dari Mentor Kompeten & Tersertifikasi
            </h5>
            <div style="display: grid; grid-template-columns: repeat(4, 1fr); gap: 20px; margin-top: 20px">
              <div class="card-listis" v-for="(data, idx) in instructor" :key="idx">
                <img :src="$utils.loadImage(data.image_url)" alt="" style="width: 100%; border-radius: 16px 16px 0 0; height: 180px; object-fit: cover;" />
                <div style="padding: 20px 16px">
                  <p style="font-weight: bold; margin: 0;">{{ data.name }}</p>
                  <p class="mt-1" style="margin: 0; font-size: 12px;">{{ data.description.slice(0, 50) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-parent mt-10" ref="timeline_belajar">
          <div class="container-detail">
            <span class="addional">Jadwal & Biaya Pelatihan</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Investasi Masa Depan yang Dapat HiColers Ikuti
            </h5>
            <div class="timeline-bootcamp mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
                <p class="batch">{{item.name}} <br /> Batch {{ item.batch_number }}</p>
              <div class="info-schadule">
                <div class="info-schadule-ctn" style="width: 45%;">
                  <span class="sm">Tanggal Dimulai</span>
                  <span class="bold">{{ formatDate(item.start_date) }}</span>
                  <span class="sm">Durasi, {{ $utils.getMonthDifference(item.start_date, item.end_date) }}</span>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Jadwal Kelas</span>
                  <span class="bold">{{ classDetail.days }}</span>
                  <span class="sm">{{ classDetail.hours }}</span>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Kuota Peserta</span>
                  <span class="bold">{{ item.total_audience }} Peserta</span>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Metode Pelatihan</span>
                  <span class="bold">{{ $utils.toMethode(classDetail.method) }}</span>
                <span class="sm cursor-pointer" v-if="classDetail.method == 'Offline'" style="text-decoration: underline;" @click="toLink(item.location_map)">
                  {{ item.location_address }}
                </span>
                </div>
              </div>
              <div class="info-schadule" v-if="item.notes">
                <div class="info-schadule-ctn">
                  <span class="sm">Informasi Lain</span>
                  <span v-html="item.notes"></span>
                </div>
                </div>
              <div class="box-price">
                <p class="promo-early">{{classDetail.is_discount ? 'Special Promo' : 'Harga Normal'}}</p>
                <h2 v-if="!classDetail.is_discount">
                    {{ classDetail.price | toCurrency }}
                  </h2>
                <div v-else>
                  <span class="bold"><del>{{ classDetail.price | toCurrency }}</del></span>
                  <h2 class="my-0 mb-4" style="color: #162CA2;">{{  toDiscount(classDetail.price - classDetail.discount_price) }}</h2>
                  <span>Berlaku sampai <b>{{ formatDateInfo(classDetail.end_discount_date) }}</b></span>
                </div>
              
                  <p class="text-right">*S&K Berlaku</p>
              </div>
              <div class="box-price-info">
                <span style="font-size: 14px; font-weight: bold;">Pendaftaran ditutup pada {{ formatDateInfo(classDetail.registration_close_date) }}</span>
                    <div style="display: flex; gap: 18px;">
                      <v-btn depressed color="#162CA2" @click="goToCheckoutPage" class="box-cta-daftar">
                        <span class="text-capitalize font-weight-bold white--text"
                          style="font-size: 15px; letter-spacing: 0 !important">DAFTAR SEKARANG</span>
                      </v-btn>
                      <v-btn outlined color="gray" class="box-cta-daftar" @click="sendToWa()">
                        <span class="text-capitalize font-weight-bold"
                          style="font-size: 15px; letter-spacing: 0 !important">KONSULTASI SEKARANG</span>
                      </v-btn>
                    </div>
              </div>
            </div>
            <p v-if="!classDetail.schedules.length" class="text-center mt-10">Jadwal tidak ditemukan</p>
          </div>
          <div class="container-detail mt-10">
            <span class="addional">Metode Pembayaran</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Pilih Metode Pembayaran yang Cocok Sesuai Kebutuhanmu
            </h5>
            <div class="cada-parent">
              <div v-for="(data, idx) in paymenMethod" :key="idx" class="card-sayhay">
                <components :is="data.icon"></components>
                <h3>{{ data.title }}</h3>
                <p style="font-size: 14px" class="mt-4">{{ data.desc }}</p>
                <div style="height: 45px;"></div>
                <div style="position: absolute; bottom: 32px;">
                  <div class="btn-stoke-blue" v-if="data.btn">
                    <span class="font-poppins" style="font-size: 13px;">Pelajari Lebih Lanjut</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-parent" ref="faq" style="margin: 50px 0">
          <div class="container-detail">
            <span class="addional">FAQ</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Pertanyaan Seputar Program
            </h5>
            <div class="faq" style="margin-top: 10px;">
            <v-expansion-panels flat class="faq-card" style="background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0px; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="padding: 0px 0px 0px 30px !important">
                  <h2 style="margin: 25px 0; font-size: 18px; line-height: 31px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow style="margin-right: 35px;" />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px;" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="padding: 25px 10px;">
            <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')"  style="margin: 0; padding: 10px 25px; gap: 10px; background-color: white">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import goTo from "vuetify/lib/services/goto";
import ButtonYellow from "../../others/ButtonYellow.vue";
// import Button from "../../others/Button.vue";

import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from "@/assets/icons/Arrow.vue";
// import CheckIc from "@/assets/icons/Check.vue";
import Popup from "@/components/others/Popup.vue";

import EmptyWallet from '@/assets/icons/bootcamp/EmptyWallet.vue';
import ThreadUp from '@/assets/icons/bootcamp/ThreadUp.vue';
import BriefCase from '@/assets/icons/bootcamp/BriefCase.vue';
import MoneyTick from '@/assets/icons/bootcamp/MoneyTick.vue';
import CardPost from '@/assets/icons/bootcamp/CardPost.vue';
import M1 from '@/assets/icons/bootcamp/M1.vue';
import M2 from '@/assets/icons/bootcamp/M2.vue';
import M3 from '@/assets/icons/bootcamp/M3.vue';
import M4 from '@/assets/icons/bootcamp/M4.vue';
import M5 from '@/assets/icons/bootcamp/M5.vue';
import M6 from '@/assets/icons/bootcamp/M6.vue';
import M7 from '@/assets/icons/bootcamp/M7.vue';

export default {
  name: "DetailClassBootcampDekstop",
  components: {
    // Button,
    ButtonYellow,
    Arrow,
    Popup,
    ArrowLong,
    // CheckIc,
    EmptyWallet,
    ThreadUp,
    BriefCase,
    MoneyTick,
    CardPost,
    M1,
    M2,
    M3,
    M4,
    M5,
    M6,
    M7,
  },

  data() {
    return {
      overview: [
        {
          icon: EmptyWallet,
          title: "High-Paying Jobs",
          desc: "Berorientasi pada kebutuhan industri saat ini dengan tingginya permintaan Web Developer yang berperan dalam realisasi produk digital untuk membantuk performa bisnis perusahaan"
        },
        {
          icon: ThreadUp,
          title: "Promising Career Path",
          desc: "Pekerjaan yang berdampak pada profitabilitas dan performa bisnis perusahaan dengan peluang level keberlanjutan dalam kepemimpinan dan top management"
        },
        {
          icon: BriefCase,
          title: "Flexible Working",
          desc: "Pekerjaan yang dapat dilakukan dengan sistem kerja apapun dalam menunjang performa kerja, baik dengan sistem kerja Inhouse, Remote, maupun Freelance "
        },
      ],
      questions: [],
      paymenMethod: [
      {
          icon: MoneyTick,
          title: "100% Upfront",
          desc: "Pembayaran dilakukan secara cash di muka dengan mengikuti biaya yang berlaku pada masa periode pendaftaran",
          btn: false,
        },
        {
          icon: MoneyTick,
          title: "Installment Payment",
          desc: "Pembayaran dilakukan dengan menerapkan metode cicilan bertahap 3x yang berlaku pada setiap periode pendaftaran",
          btn: true,
        },
        {
          icon: CardPost,
          title: "Company Sponsorship",
          desc: "Pembayaran dengan menerapkan anggaran pengembangan talenta perusahaan untuk mensponsori program akselerasi HiColleagues",
          btn: true,
        },
      ],
      whyHiColl: [
        {
          img: "/img/bootcamp/ic_intensive_program.png",
          title: "Intensive Program",
          desc: "Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
        },
        {
          img: "/img/bootcamp/ic_competent_trainer.png",
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_completion.png",
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_kompetensi.png",
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
        },
        {
          img: "/img/bootcamp/ic_job_recommentation.png",
          title: "Job Connector",
          desc: "Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp",
        },
        {
          img: "/img/bootcamp/ic_installment.png",
          title: "Installment Option Available",
          desc: "Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta",
        },
      ],
      methodLearning: [
        {
          img: M1,
          title: "Join Community Group",
          desc: "Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.",
        },
        {
          img: M2,
          title: "Live Class via Zoom",
          desc: "Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.",
        },
        {
          img: M3,
          title: "Access to LMS",
          desc: "Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.",
        },
        {
          img: M4,
          title: "Practical Learning",
          desc: "Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.",
        },
        {
          img: M5,
          title: "Sharing Session",
          desc: "Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.",
        },
        {
          img: M6,
          title: "Live Consultation",
          desc: "Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.",
        },
        {
          img: M7,
          title: "Final Project & Exam",
          desc: "Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.",
        },
      ],
      menuPublicClass: [
        { 
          name: "Overview", 
          id: 1, 
          position: 0, 
          layout: "info-kelas" 
        },
        {
          name: "Mengapa HiColleagues",
          id: 2,
          position: 0,
          layout: "kenapa-hicoleagues",
        },
        {
          name: "Target Peserta",
          id: 3,
          position: 0,
          layout: "tagret-peserta",
        },
        {
          name: "Materi Silabus",
          id: 4,
          position: 0,
          layout: "materi-silabus",
        },
        {
          name: "Metode Belajar",
          id: 5,
          position: 0,
          layout: "methode-belajar",
        },
        {
          name: "Instruktur",
          id: 6,
          position: 0,
          layout: "instructor",
        },
        {
          name: "Jadwal & Biaya",
          id: 7,
          position: 0,
          layout: "timeline-belajar",
        },
        {
          name: "FAQ",
          id: 8,
          position: 0,
          layout: "faq",
        },
      ],
      methodePembayaran: [
        {
          title: "Pembayaran Pertama",
          type: "(Saat Pendaftaran)",
          list: [
            "<span>Ketika calon peserta mendaftar untuk bootcamp, calon peserta akan diminta untuk</span> membayar pembayaran pertama.",
            "<span>Besaran</span> pembayaran pertama adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Calon peserta akan dianggap</span> resmi terdaftar setelah pembayaran pertama diterima",
          ],
        },
        {
          title: "Pembayaran Kedua",
          type: "(Tengah Training / Senin, 13 November 2023)",
          list: [
            "Pembayaran kedua akan jatuh tempo pada tengah periode pelatihan, <span>yaitu pada tanggal yang telah ditentukan dalam perjanjian pendaftaran.</span>",
            "<span>Besaran</span> pembayaran kedua juga adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan</span> diingatkan sebelum jatuh tempo <span>pembayaran kedua</span>",
          ],
        },
        {
          title: "Pembayaran Ketiga",
          type: "(Sebelum Penerimaan Sertifikat)",
          list: [
            "<span>Pembayaran ketiga akan dilakukan setelah calon peserta</span> menyelesaikan seluruh pelatihan dan memenuhi semua persyaratan <span>yang diperlukan.</span>",
            "Pembayaran ketiga adalah sisa dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan diingatkan</span> sebelum jatuh tempo <span>pembayaran ketiga.</span>",
            "<span>Setelah pembayaran ketiga diterima, peserta akan diberikan</span> sertifikat sebagai bukti kelulusan.",
          ],
        },
      ],
      priceAddInfo: [
        "Tipe kelas Public",
        "Kapasitas max 10 peserta",
        "Jadwal kelas telah ditentukan",
        "Diajarkan langsung oleh mentor",
        "Gratis Survival Kit",
        "Sertifikat Kelulusan",
      ],
      classDetail: {
        schedules: [],
      },
      instructor: [
        {
          image_url: "/img/class/main.png",
          name: "Nae Fiona Alderia",
          description: "Senior Software Developer di LazyCash Payment",
          linkedin_url: ""
        }
      ],
      alertErrMessage: "",
      isAlertErr: false,
      expired: [],
      layout: "info-kelas",
    };
  },
  methods: {
    mappingHeightOfElement() {
      let point = this.$refs.ctn_head.clientHeight;
      this.menuPublicClass[0].position = point;
      point += this.$refs.overview.clientHeight;
      this.menuPublicClass[1].position = point;
      point += this.$refs.kenapa_hicolleagues.clientHeight;
      this.menuPublicClass[2].position = point;
      point += this.$refs.taget_peserta.clientHeight;
      this.menuPublicClass[3].position = point;
      point += this.$refs.materi_silabus.clientHeight;
      this.menuPublicClass[4].position = point;
      point += this.$refs.metode_belajar.clientHeight;
      this.menuPublicClass[5].position = point;
      point += this.$refs.instruktur.clientHeight;
      this.menuPublicClass[6].position = point;
      point += this.$refs.timeline_belajar.clientHeight
      this.menuPublicClass[7].position = point;
    },
    clickActiveMenu(e, p) {
      this.layout = e;
      console.log(p);
      goTo(Number(p + 10));
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=DETAIL_CLASS&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    toDiscount(total_price) {
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    goToCheckoutPage() {
      if (new Date(this.classDetail.registration_close_date) < new Date()) {
        this.alertErrMessage = "Pendaftaran sudah di tutup";
        this.isAlertErr = true;
        return;
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.is_discount
          ? (this.classDetail.price - this.classDetail.discount_price)
          : this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: "BOOTCAMP_CLASS",
        schedule: this.classDetail.schedules,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });
      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = `/checkout?category=BOOTCAMP&slug_name=${this.$route.params.slug_name}`;
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.classDetail.end_discount_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slug_name)
        .then((response) => {
          const data = response.data.data.class_detail;

          this.instructor = [
            response.data.data.instructor
          ]
          this.classDetail = {
            ...data,
            summary_syllabus: data.summary_syllabus
              ? data.summary_syllabus.map((item) => ({
                ...item,
                show: true,
              }))
              : [],
          };
          setTimeout(() => {
            this.mappingHeightOfElement();
          }, 1000);
        })
        .catch(() => {
          this.$router.push('/class/bootcamp')
        });
    },
    handleScroll() {
      this.menuPublicClass.forEach((item) => {
        if (item.position <= window.scrollY) {
          this.layout = item.layout;
        }
      });      
    },
    formatDate(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    formatDateInfo(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    toMethode(value) {
      if (!value) return
      if (value.toLowerCase() === "online") {
        return "Daring (via Zoom)"
      } else if (value.toLowerCase() === "offline") {
        return "Luring"
      } else {
        return value
      }
    },
      toLink(link) {
      window.open(link)
    }
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getDetailClass();
    this.getFaq()
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./css/detailClassBootcamp.css" scoped></style>