<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.9917 15.4081V6.94144C6.9917 6.60811 7.0917 6.28311 7.27503 6.00811L9.55003 2.62478C9.90837 2.08311 10.8 1.69978 11.5584 1.98311C12.375 2.25811 12.9167 3.17478 12.7417 3.99144L12.3084 6.71644C12.275 6.96644 12.3417 7.19144 12.4834 7.36644C12.625 7.52478 12.8334 7.62478 13.0584 7.62478H16.4834C17.1417 7.62478 17.7084 7.89144 18.0417 8.35811C18.3584 8.80811 18.4167 9.39144 18.2084 9.98311L16.1584 16.2248C15.9 17.2581 14.775 18.0998 13.6584 18.0998H10.4084C9.85003 18.0998 9.0667 17.9081 8.70837 17.5498L7.6417 16.7248C7.23337 16.4164 6.9917 15.9248 6.9917 15.4081Z"
			fill="#162CA2" />
		<path
			d="M4.3415 5.31665H3.48317C2.1915 5.31665 1.6665 5.81665 1.6665 7.04998V15.4333C1.6665 16.6667 2.1915 17.1667 3.48317 17.1667H4.3415C5.63317 17.1667 6.15817 16.6667 6.15817 15.4333V7.04998C6.15817 5.81665 5.63317 5.31665 4.3415 5.31665Z"
			fill="#162CA2" />
	</svg>
</template>

<script>
export default {
	name: 'LikeIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
