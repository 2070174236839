<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#7B7E8C"/>
    <path d="M4.90009 19.7504C4.71009 19.7504 4.52009 19.6804 4.37009 19.5304C4.08009 19.2404 4.08009 18.7604 4.37009 18.4704L18.3701 4.47043C18.6601 4.18043 19.1401 4.18043 19.4301 4.47043C19.7201 4.76043 19.7201 5.24043 19.4301 5.53043L5.43009 19.5304C5.28009 19.6804 5.09009 19.7504 4.90009 19.7504Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "CloseSlash",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
