<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2 27.6668H12.8C6.94671 27.6668 4.33337 25.0535 4.33337 19.2002V12.8002C4.33337 6.94683 6.94671 4.3335 12.8 4.3335H19.2C25.0534 4.3335 27.6667 6.94683 27.6667 12.8002V19.2002C27.6667 25.0535 25.0534 27.6668 19.2 27.6668ZM12.8 6.3335C8.02671 6.3335 6.33337 8.02683 6.33337 12.8002V19.2002C6.33337 23.9735 8.02671 25.6668 12.8 25.6668H19.2C23.9734 25.6668 25.6667 23.9735 25.6667 19.2002V12.8002C25.6667 8.02683 23.9734 6.3335 19.2 6.3335H12.8Z"
      fill="#36373F"
    />
    <path
      d="M18 23.6668H14C10.1334 23.6668 8.33337 21.8668 8.33337 18.0002V14.0002C8.33337 10.1335 10.1334 8.3335 14 8.3335H18C21.8667 8.3335 23.6667 10.1335 23.6667 14.0002V18.0002C23.6667 21.8668 21.8667 23.6668 18 23.6668ZM14 10.3335C11.2267 10.3335 10.3334 11.2268 10.3334 14.0002V18.0002C10.3334 20.7735 11.2267 21.6668 14 21.6668H18C20.7734 21.6668 21.6667 20.7735 21.6667 18.0002V14.0002C21.6667 11.2268 20.7734 10.3335 18 10.3335H14Z"
      fill="#36373F"
    />
    <path
      d="M10.6801 6.33317C10.1201 6.33317 9.68005 5.87984 9.68005 5.33317V2.6665C9.68005 2.11984 10.1334 1.6665 10.6801 1.6665C11.2267 1.6665 11.6801 2.11984 11.6801 2.6665V5.33317C11.6801 5.87984 11.2267 6.33317 10.6801 6.33317Z"
      fill="#36373F"
    />
    <path
      d="M16 6.33317C15.4533 6.33317 15 5.87984 15 5.33317V2.6665C15 2.11984 15.4533 1.6665 16 1.6665C16.5467 1.6665 17 2.11984 17 2.6665V5.33317C17 5.87984 16.5467 6.33317 16 6.33317Z"
      fill="#36373F"
    />
    <path
      d="M21.3334 6.33317C20.7867 6.33317 20.3334 5.87984 20.3334 5.33317V2.6665C20.3334 2.11984 20.7867 1.6665 21.3334 1.6665C21.88 1.6665 22.3334 2.11984 22.3334 2.6665V5.33317C22.3334 5.87984 21.88 6.33317 21.3334 6.33317Z"
      fill="#36373F"
    />
    <path
      d="M29.3333 11.6665H26.6666C26.12 11.6665 25.6666 11.2132 25.6666 10.6665C25.6666 10.1198 26.12 9.6665 26.6666 9.6665H29.3333C29.88 9.6665 30.3333 10.1198 30.3333 10.6665C30.3333 11.2132 29.8933 11.6665 29.3333 11.6665Z"
      fill="#36373F"
    />
    <path
      d="M29.3333 17H26.6666C26.12 17 25.6666 16.5467 25.6666 16C25.6666 15.4533 26.12 15 26.6666 15H29.3333C29.88 15 30.3333 15.4533 30.3333 16C30.3333 16.5467 29.8933 17 29.3333 17Z"
      fill="#36373F"
    />
    <path
      d="M29.3333 22.3335H26.6666C26.12 22.3335 25.6666 21.8802 25.6666 21.3335C25.6666 20.7868 26.12 20.3335 26.6666 20.3335H29.3333C29.88 20.3335 30.3333 20.7868 30.3333 21.3335C30.3333 21.8802 29.8933 22.3335 29.3333 22.3335Z"
      fill="#36373F"
    />
    <path
      d="M21.3334 30.3332C20.7867 30.3332 20.3334 29.8798 20.3334 29.3332V26.6665C20.3334 26.1198 20.7867 25.6665 21.3334 25.6665C21.88 25.6665 22.3334 26.1198 22.3334 26.6665V29.3332C22.3334 29.8798 21.88 30.3332 21.3334 30.3332Z"
      fill="#36373F"
    />
    <path
      d="M16.0133 30.3332C15.4666 30.3332 15.0133 29.8798 15.0133 29.3332V26.6665C15.0133 26.1198 15.4666 25.6665 16.0133 25.6665C16.56 25.6665 17.0133 26.1198 17.0133 26.6665V29.3332C17.0133 29.8798 16.56 30.3332 16.0133 30.3332Z"
      fill="#36373F"
    />
    <path
      d="M10.6801 30.3332C10.1334 30.3332 9.68005 29.8798 9.68005 29.3332V26.6665C9.68005 26.1198 10.1334 25.6665 10.6801 25.6665C11.2267 25.6665 11.6801 26.1198 11.6801 26.6665V29.3332C11.6801 29.8798 11.2267 30.3332 10.6801 30.3332Z"
      fill="#36373F"
    />
    <path
      d="M5.33329 11.6665H2.66663C2.11996 11.6665 1.66663 11.2265 1.66663 10.6665C1.66663 10.1065 2.11996 9.6665 2.66663 9.6665H5.33329C5.87996 9.6665 6.33329 10.1198 6.33329 10.6665C6.33329 11.2132 5.89329 11.6665 5.33329 11.6665Z"
      fill="#36373F"
    />
    <path
      d="M5.33329 17H2.66663C2.11996 17 1.66663 16.5467 1.66663 16C1.66663 15.4533 2.11996 15 2.66663 15H5.33329C5.87996 15 6.33329 15.4533 6.33329 16C6.33329 16.5467 5.89329 17 5.33329 17Z"
      fill="#36373F"
    />
    <path
      d="M5.33329 22.3335H2.66663C2.11996 22.3335 1.66663 21.8802 1.66663 21.3335C1.66663 20.7868 2.11996 20.3335 2.66663 20.3335H5.33329C5.87996 20.3335 6.33329 20.7868 6.33329 21.3335C6.33329 21.8802 5.89329 22.3335 5.33329 22.3335Z"
      fill="#36373F"
    />
  </svg>
</template>

<script>
export default {
  name: "Cpu",
  props: {
    width: {
      type: String,
      default: "32",
    },
    height: {
      type: String,
      default: "32",
    },
  },
};
</script>
