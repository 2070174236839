<template>
  <svg :width="width" :height="height" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4.29889" r="4" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Eclipse",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  }
}
</script>