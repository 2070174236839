<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3199 30.3733C12.5199 30.3733 11.7599 29.9733 11.24 29.2667L9.63997 27.1333C9.63997 27.1467 9.5733 27.1066 9.54663 27.1066H9.0533C4.4933 27.1066 1.66663 25.8667 1.66663 19.72V14.3867C1.66663 8.77334 5.09331 7.30668 7.97331 7.05334C8.29331 7.01334 8.66664 7 9.0533 7H17.5866C22.4133 7 24.9733 9.56001 24.9733 14.3867V19.72C24.9733 20.1067 24.9599 20.48 24.9066 20.84C24.6666 23.68 23.2 27.1066 17.5866 27.1066H17.0533L15.3999 29.2667C14.8799 29.9733 14.1199 30.3733 13.3199 30.3733ZM9.0533 9C8.74664 9 8.4533 9.01333 8.1733 9.04C5.07997 9.30666 3.66663 11 3.66663 14.3867V19.72C3.66663 24.2933 5.07997 25.1066 9.0533 25.1066H9.58663C10.1866 25.1066 10.8666 25.44 11.24 25.92L12.84 28.0666C13.1333 28.4667 13.5066 28.4667 13.8 28.0666L15.3999 25.9333C15.7866 25.4133 16.4 25.1066 17.0533 25.1066H17.5866C20.9733 25.1066 22.6666 23.68 22.92 20.64C22.96 20.32 22.9733 20.0267 22.9733 19.72V14.3867C22.9733 10.6667 21.3066 9 17.5866 9H9.0533Z"
      fill="#36373F"
    />
    <path
      d="M13.3203 18.9206C12.5736 18.9206 11.9869 18.3206 11.9869 17.5872C11.9869 16.8539 12.5869 16.2539 13.3203 16.2539C14.0536 16.2539 14.6536 16.8539 14.6536 17.5872C14.6536 18.3206 14.0669 18.9206 13.3203 18.9206Z"
      fill="#36373F"
    />
    <path
      d="M17.5866 18.9206C16.84 18.9206 16.2533 18.3206 16.2533 17.5872C16.2533 16.8539 16.8533 16.2539 17.5866 16.2539C18.32 16.2539 18.92 16.8539 18.92 17.5872C18.92 18.3206 18.32 18.9206 17.5866 18.9206Z"
      fill="#36373F"
    />
    <path
      d="M9.06637 18.9206C8.3197 18.9206 7.73303 18.3206 7.73303 17.5872C7.73303 16.8539 8.33303 16.2539 9.06637 16.2539C9.7997 16.2539 10.3997 16.8539 10.3997 17.5872C10.3997 18.3206 9.7997 18.9206 9.06637 18.9206Z"
      fill="#36373F"
    />
    <path
      d="M23.9201 21.7198C23.6535 21.7198 23.3868 21.6132 23.2001 21.4132C22.9868 21.1998 22.8935 20.8932 22.9335 20.5999C22.9735 20.3199 22.9868 20.0265 22.9868 19.7198V14.3865C22.9868 10.6665 21.3202 8.99984 17.6002 8.99984H9.06679C8.76012 8.99984 8.46682 9.01317 8.18682 9.03984C7.89349 9.07984 7.5868 8.97315 7.37347 8.77315C7.16014 8.55982 7.04012 8.26652 7.06679 7.97319C7.30679 5.09319 8.78679 1.6665 14.4001 1.6665H22.9335C27.7602 1.6665 30.3201 4.22652 30.3201 9.05318V14.3865C30.3201 19.9998 26.8935 21.4665 24.0135 21.7198C23.9735 21.7198 23.9468 21.7198 23.9201 21.7198ZM9.22682 6.99984H17.5868C22.4135 6.99984 24.9735 9.55985 24.9735 14.3865V19.5465C27.2402 18.9865 28.3068 17.3198 28.3068 14.3865V9.05318C28.3068 5.33318 26.6401 3.6665 22.9201 3.6665H14.3868C11.4535 3.6665 9.80015 4.73317 9.22682 6.99984Z"
      fill="#36373F"
    />
  </svg>
</template>
<script>
export default {
  name: "Messages",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
};
</script>
