<template>
  <div class="container-size">
    <popup
      :message="notif.alertErrMessage"
      v-if="notif.isAlertErr"
      @close="notif.isAlertErr = false"
    />
    <div class="checkout-event" v-if="!isConfirmPayment">
      <h1 class="text-center checkout-e-title">Form Pendaftaran</h1>
      <div class="card-item card-item-padding" @click="isDetail = !isDetail">
        <div class="d-flex justify-content-between">
          <p class="c-detail">Detail Kelas</p>
          <arrow :down="!isDetail" />
        </div>
        <div class="d-flex gap-2" v-if="isDetail">
          <div>
            <img
              :src="event.img_urls.desktop.purchase_detail"
              style="margin-right: 5px; border-radius: 15px"
              width="95"
              height="95"
            />
          </div>
          <div>
            <h3 class="class-name">{{ event.name }}</h3>
            <p class="harga">Harga</p>
            <span class="price" v-if="!checkIsEarlyBird(event)">{{
              event.price ? toCurrency(event.price) : "GRATIS"
            }}</span>
            <div v-else class="mb-2">
              <span class="price mr-2">{{
                event.price - event.discount_price
                  ? toCurrency(event.price - event.discount_price)
                  : "GRATIS"
              }}</span>
              <p>
                <del>{{ toCurrency(event.price) }}</del
                ><br /><span class="early-bird-promo">Promo Early bird</span>
              </p>
            </div>
          </div>
        </div>
        <div class="class-info" v-if="isDetail">
          <div class="class-info-content">
            <div class="mob">
              <img
                src="/img/icons/date.png"
                alt="Level training"
                width="20"
                height="20"
              />
              <span class="name font-poppins">Hari & Tanggal</span>
            </div>
            <div class="class-info-detail">
              <span class="font-poppins">{{
                moment(event.session_schedules[0].date_start)
              }}</span>
            </div>
          </div>
          <div class="class-info-content">
            <div class="mob">
              <img
                src="/img/icons/clock.png"
                alt="Level training"
                width="20"
                height="20"
              />
              <span class="name font-poppins">Jam</span>
            </div>
            <div class="class-info-detail">
              <span class="font-poppins"
                >{{ event.session_schedules[0].start_time }} -
                {{ event.session_schedules[0].end_time }} WIB</span
              >
            </div>
          </div>
          <div class="class-info-content">
            <div class="mob">
              <img
                src="/img/icons/methode.png"
                alt="Level training"
                width="20"
                height="20"
              />
              <span class="name font-poppins">Metode</span>
            </div>
            <div class="class-info-detail">
              <span class="font-poppins">{{ event.method | toMethode }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-item-padding">
          <div class="input-box">
            <span>Tipe Event</span>
            <v-text-field
              v-model="event.category.name"
              outlined
              class="mt-2 rounded-sm"
              dense
              disabled
            ></v-text-field>
          </div>
        </div>
        <div class="spacing-ctn"></div>
        <div class="card-item-padding">
          <div class="input-box">
            <span>Titel</span>
            <v-radio-group
              row
              ref="gender"
              v-model="gender"
              :error-messages="genderErrors"
              @input="$v.gender.$touch()"
              @blur="$v.gender.$touch()"
              required
            >
              <v-radio
                v-for="(item, idx) in genders"
                :key="idx"
                class="font-poppins"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="input-box">
            <span>Nama Lengkap</span>
            <v-text-field
              ref="fullName"
              v-model="fullName"
              outlined
              dense
              required
              placeholder="Masukkan Nama Lengkapmu"
              class="mt-2 rounded-sm"
              :error-messages="fullNameErrors"
              @input="$v.fullName.$touch()"
              @blur="$v.fullName.$touch()"
            ></v-text-field>
          </div>
          <div class="input-box">
            <span>No. Handphone</span>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="Kode Negara"
                  outlined
                  dense
                  class="mt-2 rounded-sm"
                  value="+62"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="phoneNumber"
                  outlined
                  dense
                  required
                  placeholder="Misal : 81X-XXXX-XXXX"
                  class="mt-2 rounded-sm"
                  type="number"
                  :error-messages="phoneNumberErrors"
                  @input="$v.phoneNumber.$touch()"
                  @blur="$v.phoneNumber.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="input-box">
            <span>Email</span>
            <v-text-field
              v-model="email"
              @keydown.space.prevent
              outlined
              dense
              required
              placeholder="Misal : hicolleagues@email.com"
              class="mt-2 rounded-sm"
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
          </div>
          <div class="input-box">
            <span>Tanggal Lahir</span>
            <v-text-field
              class="mb-2"
              v-model="birthdate"
              prepend-icon="mdi-calendar"
              type="date"
              :error-messages="birthdateErrors"
              @input="$v.birthdate.$touch()"
              @blur="$v.birthdate.$touch()"
            ></v-text-field>
          </div>
          <div class="input-box">
            <span>Instansi</span>
            <v-text-field
              ref="corporate_name"
              v-model="corporate_name"
              outlined
              dense
              required
              placeholder="Masukkan Nama Instansimu"
              class="mt-2 rounded-sm"
            ></v-text-field>
          </div>
        </div>
        <div class="spacing-ctn" v-if="event.price"></div>
        <div class="card-item-padding">
          <div class="input-box" v-if="event.price">
            <span>Masukan kode voucher</span>
            <v-text-field
              v-model="voucherCode"
              outlined
              class="mt-2 rounded-sm"
              dense
              required
              clear-icon="mdi-close-circle"
              clearable
              @click:clear="voucherCode = ''"
            ></v-text-field>
          </div>
          <div class="aggrement">
            Dengan mengisi formulir ini saya menyetujui
            <span class="info" @click="privacyPolicy">Kebijakan Privasi</span>
            dan
            <span class="info" @click="termCondition"
              >Syarat dan Ketentuan</span
            >
            untuk mengikuti program yang saya daftarkan.
          </div>
          <div class="button-checkout">
            <Button
              name="DAFTAR"
              :disabled="validationField"
              width="320px"
              :loading="isLoading"
              @click="() => toCheckout(event.price ? false : true)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-event" v-else>
      <div class="card-item card-item-padding" style="margin-top: 170px">
        <h2 class="text-center" style="margin-bottom: 20px">
          Rincian Pembelian
        </h2>
        <div class="d-flex gap-2">
          <div>
            <img
              :src="event.img_urls.desktop.purchase_detail"
              style="margin-right: 5px; border-radius: 15px"
              width="95"
              height="95"
            />
          </div>
          <div>
            <h3 class="class-name">{{ event.name }}</h3>
            <div class="author-info">
              <span>{{ moment(event.session_schedules[0].date_start) }}</span>
              <span class="circle"></span>
              <span>{{ event.session_schedules[0].start_time }} WIB</span>
              <span class="circle"></span>
              <span>{{ event.method | toMethode }}</span>
            </div>
          </div>
        </div>
        <hr class="mt-5" />
        <div style="margin: 15px 0">
          <div class="price-info">
            <span>Subtotal</span>
            <span v-if="checkIsEarlyBird(event)">{{
              (event.price - event.discount_price) | toCurrency
            }}</span>
            <span v-else>{{ event.price | toCurrency }}</span>
          </div>
          <div class="price-info">
            <span>Potongan</span>
            <span>{{ discount ? toCurrency(discount) : "-" }}</span>
          </div>
        </div>
        <hr />
        <div class="price-info mt-5">
          <p class="price-title">Total</p>
          <p class="price-total" v-if="checkIsEarlyBird(event)">
            {{
              event.price - event.discount_price - discount
                ? toCurrency(event.price - event.discount_price - discount)
                : "GRATIS"
            }}
          </p>
          <p class="price-total" v-else>
            {{
              event.price - discount
                ? toCurrency(event.price - discount)
                : "GRATIS"
            }}
          </p>
        </div>
        <div class="button-checkout">
          <Button
            name="LANJUT KE PEMBAYARAN"
            width="320px"
            :loading="isLoading"
            @click="toCheckout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Arrow from "@/assets/icons/Arrow.vue";
import Button from "@/components/others/Button.vue";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "EventCheckout",
  components: {
    Arrow,
    Button,
    Popup,
  },
  mixins: [validationMixin],
  validations: {
    fullName: { required },
    phoneNumber: { required },

    birthdate: { required },
    learningMethod: { required },
    // voucherCode: { required },
    // schedule: { required },
    gender: { required },
    classType: { required },
    email: { required, email },
  },
  data() {
    return {
      isDetail: true,
      isConfirmPayment: false,
      isLoading: false,
      modal: false,
      transactionId: 0,
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      event: {
        name: "",
        registration_close_date: "",
        method: "",
        label: "",
        price: 0,
        img_urls: {
          desktop: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
          mobile: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
        },
        session_schedules: [
          {
            date_start: "",
          },
        ],
        category: {
          name: "",
        },
      },
      isDoubleClick: false,
      discount: 0,
      voucherCode: "",
      email: "",
      gender: "",
      fullName: "",
      phoneNumber: "",
      birthdate: "",
      corporate_name: "",
      genders: ["Tuan", "Nyonya", "Nona"],
    };
  },
  computed: {
    validationField() {
      if (
        this.email &&
        this.gender &&
        this.fullName &&
        this.birthdate &&
        this.phoneNumber
      ) {
        return false;
      } else {
        return true;
      }
    },
    fullNameErrors() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.required &&
        errors.push("Nama lengkap tidak boleh kosong.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Titel tidak boleh kosong");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email tidak boleh kosong");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push("Nomor telepon tidak boleh kosong.");
      return errors;
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.birthdate.$dirty) return errors;
      !this.$v.birthdate.required &&
        errors.push("Tanggal lahir tidak boleh kosong.");
      return errors;
    },
  },
  methods: {
    async getEventDetail() {
      try {
        const resp = await this.$http.get("/v2/event/" + this.$route.params.id);
        const item = resp.data.data.event;
        this.event = {
          ...item,
          session_schedules: item.session_schedules
            ? item.session_schedules
              ? item.session_schedules
              : [
                  {
                    date_end: "",
                    date_start: "",
                    end_time: "",
                    start_time: "",
                  },
                ]
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        };
      } catch (err) {
        console.log(err);
      }
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async checkDiscountVoucher() {
      try {
        const resp = await this.$http.get(
          "/v1/voucher/event/" + this.voucherCode
        );
        this.discount = resp.data.data.voucher.discount;
        return true;
      } catch (err) {
        this.isLoading = false;
        this.notif.isAlertErr = true;
        this.notif.alertErrMessage = "Voucher tidak di temukan";
        return false;
      }
    },
    async toCheckout(isFree = false) {
      if (this.phoneNumber.length < 10) {
        this.notif.isAlertErr = true;
        this.notif.alertErrMessage = "Nomor telepon tidak valid";
        return;
      }
      this.isLoading = true;
      if (isFree) {
        try {
          await this.$http.post("/v1/transaction-event", {
            event_id: this.event.id,
            type_event: this.event.category.name,
            batch: this.event.batch,
            title: this.gender,
            full_name: this.fullName,
            phone_number: this.phoneNumber,
            email: this.email,
            birthdate: this.birthdate,
            corporate_name: this.corporate_name,
            voucher_code: this.voucherCode,
          });
          this.isLoading = false;
          this.$router.push("/event/success");
          return;
        } catch (err) {
          this.isLoading = false;
          this.notif.isAlertErr = true;
          this.notif.alertErrMessage = err.response.data.data;
          return;
        }
      }
      if (!this.isConfirmPayment) {
        if (this.voucherCode) {
          const checkVoucherTrue = await this.checkDiscountVoucher();
          if (!checkVoucherTrue) return;
        }
        try {
          const resp = await this.$http.post("/v1/transaction-event", {
            event_id: this.event.id,
            type_event: this.event.category.name,
            title: this.gender,
            full_name: this.fullName,
            batch: this.event.batch,
            phone_number: this.phoneNumber,
            email: this.email.toLowerCase(),
            birthdate: this.birthdate,
            corporate_name: this.corporate_name,
            voucher_code: this.voucherCode,
            is_paid: false,
          });
          this.transactionId = resp.data.data.transaction_id;
          if (resp.data.data.invoice_link) {
            window.open(resp.data.data.invoice_link);
            this.isLoading = false;
            this.$router.push("/event/success");
            return
          }
          this.isConfirmPayment = true;
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          this.notif.isAlertErr = true;
          this.notif.alertErrMessage = err.response.data.data;
        }
      } else {
        if (this.isDoubleClick) {
          setTimeout(() => {
            this.isDoubleClick = false
          }, 3000)
          return
        }
        this.isDoubleClick = true
        try {
          let discount_price = 0;
          if (this.checkIsEarlyBird(this.event)) {
            discount_price = this.event.discount_price;
          }
          if (this.event.price - discount_price - this.discount > 0) {
            const resp = await this.$http.post(
              "/v1/transaction-event/send-payment",
              {
                transaction_id: this.transactionId,
              }
            );
            window.open(resp.data.data.url);
          }
          this.isLoading = false;
          this.$router.push("/event/success");
        } catch (err) {
          this.isLoading = false;
          this.notif.isAlertErr = true;
          this.notif.alertErrMessage = err.response.data.data;
        }
      }
    },
    checkIsEarlyBird(item) {
      if (
        new Date(item.start_discount_date) <= new Date() &&
        new Date(item.end_discount_date) >= new Date() && !this.voucherCode
      ) {
        return true;
      } else {
        return false;
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    privacyPolicy() {
      this.$router.push("/privacy-policy");
    },
    termCondition() {
      this.$router.push("/term-condition");
    },
  },
  mounted() {
    this.getEventDetail();
    this.$utils.scrollTop()
    this.$gtag.pageview(this.$route);
  },
};
</script>
<style scoped>
.checkout-event {
  width: 50%;
  margin: 50px auto;
}
.card-item {
  margin-top: 20px;
  border-radius: 26px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card-item-padding {
  padding: 20px 40px;
}
.card-item .harga {
  margin: 0;
  margin-top: 10px;
  color: #7b7e8c;
}
.card-item .price {
  font-weight: bolder;
  margin: 0;
  color: #162ca2;
  font-size: 18px;
  font-family: "Poppins", sans-serif !important;
}
.class-info {
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}
.class-info-content {
  gap: 8px;
}

.class-info-detail {
  display: flex;
  flex-direction: column;
}
.class-info-detail .name {
  color: #7b7e8c;
  font-size: 16px;
}
.class-info-content .mob {
  display: flex;
  gap: 10px;
}
.input-box span {
  display: block;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: -3px;
  font-family: "Poppins", sans-serif !important;
}

.spacing-ctn {
  margin-top: -20px;
  background-color: #f5f6fa;
  height: 10px;
}
.rounded-sm {
  border-radius: 14px !important;
}

.early-bird-promo {
  color: #162ca2;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.aggrement {
  font-size: 15px;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}
.button-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
}
.author-info {
  display: flex;
  gap: 10px;
  font-size: 14px;
  align-items: center;
  margin: 10px 0;
}
.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: gray;
}
.price-info {
  display: flex;
  justify-content: space-between;
}
.price-title {
  font-size: 22px;
  font-weight: bold;
}
.price-total {
  color: #b80f0a;
  font-size: 22px;
  font-weight: bold;
}
hr {
  border: 1px solid #d3d6e1;
}
.aggrement .info {
  color: #000080;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
  background-color: white !important;
}
.c-detail {
  font-weight: bolder;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 900px) {
  .checkout-e-title {
    display: none !important;
    font-size: 20px;
    line-height: 30px;
  }
  .checkout-event {
    width: 95%;
  }
  .card-item-padding {
    padding: 20px;
  }
  .class-info {
    display: block;
  }
  .class-info-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .aggrement {
    width: 98%;
  }
}
</style>