<template>
  <div style="background-color: #f5f6f9 !important" class="mt-10">
    <popup
      :message="notif.alertErrMessage"
      v-if="notif.isAlertErr"
      @close="notif.isAlertErr = false"
    />
    <div
      style="
        margin-bottom: 50px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
      "
      class="main-banner"
    >
      <div style="text-align: center">
        <div style="overflow: hidden">
          <img
            style="width: 200%; border-bottom-left-radius: 104px"
            src="/img/event/event_main.jpg"
          />
        </div>

        <div style="margin: 0px 18px 64px 18px">
          <h2
            style="
              color: white;
              font-size: 22px;
              font-weight: 700;
              line-height: 32px;
            "
          >
            Temukan Event Menarik & Buat Digital Upskilling -mu Makin Seru!
          </h2>
          <p
            style="
              color: white;
              font-size: 13px;
              font-weight: 400;
              line-height: 22px;
            "
            class="mt-5"
          >
            <span
              style="
                color: white;
                font-size: 13px;
                font-weight: 600;
                line-height: 22px;
              "
            >
              #MulaiLangkahHebat</span
            >
            dengan gabung event menarik yang HiColers minati dan siap melesat
            bersama HiColleagues!
          </p>
          <div>
            <Button
              style="
                margin: auto;
                background-color: #ffde59;
                color: #162ca2;
                font-weight: 600;
                margin-top: 32px;
              "
              :bold="true"
              name="LIHAT EVENT"
              width="250px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="banner-content">
      <div style="width: 328px; margin: auto" class="text-center mt-4">
        <h2 style="font-weight: 700; font-size: 18px; line-height: 28px">
          Mengapa Harus Ikut Event HiColleagues?
        </h2>
      </div>
      <div style="display: flex; overflow: auto; padding-bottom: 20px">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="48" height="48" />
          </div>
          <div>
            <h3 class="title-2">{{ n.title }}</h3>
            <p style="margin: 0px" class="desc mt-2">{{ n.desc }}</p>
          </div>
        </div>
      </div>
      <div style="margin-top: 80px" class="text-center">
        <h2
          style="
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            margin: 0px;
          "
        >
          Rekomendasi Event HiColleagues
        </h2>
        <p
          class="mt-2"
          style="font-weight: 400; font-size: 13px; line-height: 18px"
        >
          Berbagai event menarik yang tersedia untuk optimasi keterampilan
          HiColers!
        </p>
        <div class="event-box-parent">
          <div class="event-box" v-for="(item, idx) in events" :key="idx">
            <div style="overflow: hidden; height: 165px">
              <img
                :src="item.img_urls.mobile.thumbnail"
                alt="Content"
                class="event-box-img"
                @click="toDetail(item)"
              />
            </div>
            <div class="event-ctn">
              <div @click="toDetail(item)">
                <div style="overflow: hidden">
                  <div class="event-ctn-label">
                    <span
                      style="
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 14px;
                        background-color: #faeceb;
                      "
                      >{{ item.category.name }}</span
                    >
                  </div>
                  <div style="height: 40px;">
                  <h3 
                    style="
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 20px;
                      margin: 0px;
                    "
                  >
                    {{ item.name }} {{ item.batch ? "Batch " + item.batch : '' }}
                  </h3></div>
                  <div class="event-ctn-info">
                    <img src="/img/icons/date.png" alt="Date" />
                    <span class="name">{{
                      moment(item.session_schedules[0].date_start)
                    }}</span>
                  </div>
                  <div class="event-ctn-info">
                    <img src="/img/icons/clock.png" alt="Clock" />
                    <span class="name"
                      >{{ item.session_schedules[0].start_time }} -
                      {{ item.session_schedules[0].end_time }} WIB</span
                    >
                  </div>
                  <div class="event-ctn-info">
                    <img src="/img/icons/methode.png" alt="Methode" />
                    <span class="name">{{ item.method | toMethode }}</span>
                  </div>
                  <div class="event-ctn-info" v-if="item.instructors.length">
                    <img src="/img/icons/speaker.png" alt="Methode" />
                    <span class="name"
                      >{{ item.instructors[0].name
                      }}<span v-if="item.instructors.length > 1"
                        >, {{ item.instructors.length - 1 }}+ lainnya</span
                      ></span
                    >
                  </div>
                </div>
                <div >
                  <div>
                    <p class="event-price" v-if="!checkIsEarlyBird(item)">
                      <span
                        style="
                          color: #575966;
                          font-family: 'Poppins';
                          font-size: 11px;
                          font-weight: 500;
                          line-height: 14px;
                        "
                      >
                        Harga Reguler
                      </span>
                      <br />
                      {{ item.price ? toCurrency(item.price) : "GRATIS" }}
                    </p>
                    <div v-else class="mb-2">
                      <span
                        style="
                          color: #575966;
                          font-family: 'Poppins';
                          font-size: 11px;
                          font-weight: 500;
                          line-height: 14px;
                        "
                      >
                        Early Bird Promo
                      </span>
                      <br />
                      <del class="discount-price mr-2">{{
                        toCurrency(item.price)
                      }}</del>
                      <span class="event-price mr-2">{{
                        item.price - item.discount_price
                          ? toCurrency(item.price - item.discount_price)
                          : "GRATIS"
                      }}</span>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      margin-bottom: 8px;
                      position: relative;
                    "
                  >
                    <Slash v-if="!item.expired.length " style="position: absolute; top: 10px; left: 60px" />
                    <Button
                      :disabled="!item.expired.length"
                      :name="!item.expired.length ? 'Telah Usai' : 'DAFTAR'"
                      width="100%"
                      @click="() => toCheckout(item)"
                    />
                  </div>
                  <div>
                    <div class="event-due-date" v-if="item.expired.length">
                      <div class="event-due-date-ctn">
                        <span>{{ item.expired[0] }}</span>
                        <span>Hari</span>
                      </div>
                      <span>:</span>
                      <div class="event-due-date-ctn">
                        <span>{{ item.expired[1] }}</span>
                        <span>Jam</span>
                      </div>
                      <span>:</span>
                      <div class="event-due-date-ctn">
                        <span>{{ item.expired[2] }}</span>
                        <span>Menit</span>
                      </div>
                      <span>:</span>
                      <div class="event-due-date-ctn">
                        <span>{{ item.expired[3] }}</span>
                        <span>Detik</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-card elevation="0" class="call-center-container">
        <v-row style="padding: 40px 0px">
          <v-col cols="12">
            <div class="call-center-title">
              Masih Bingung untuk Gabung Event HiColleagues?
            </div>
            <div class="call-center-desc">
              Sesuaikan minatmu dengan konsultasi bersama HiColleagues
            </div>
          </v-col>
          <v-col cols="12" class="call-center-button" @click="contactMe">
            <v-btn class="btn" elevation="0"> KONSULTASI GRATIS </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel
              style="border-radius: 20px; margin-bottom: 10px"
              v-for="(item, index) in questions"
              :key="index"
            >
              <v-expansion-panel-header
                disable-icon-rotate
                @click="openFaq(index)"
                style="border-radius: 20px"
              >
                <h4 style="line-height: 22px">{{ item.title }}</h4>
                <template v-slot:actions>
                  <div
                    class="icon"
                    v-if="item.active"
                    style="margin-right: 20px"
                  >
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius: 0 0 20px 20px">
                <div v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            class="btn-stoke-blue cursor-pointer mt-5"
            @click="$router.push('/faq')"
          >
            <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            <ArrowLong />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import Button from "@/components/others/Button.vue";
import Popup from "@/components/others/Popup.vue";
import Slash from "@/assets/icons/Slash.vue";
// import ArrowLong from "@/assets/icons/ArrowLong.vue";
// import Arrow from "@/assets/icons/Arrow.vue";

export default {
  name: "EventMobile",
  data() {
    return {
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Narasumber Profesional",
          desc: "Event semakin menarik karena diisi oleh narasumber profesional & pengalaman di bidangnya",
        },
        {
          image: "/img/event/topik-ter-update.png",
          title: "Topik Ter-update",
          desc: "Topik hangat dan menarik tersedia untuk melengkapi wawasan, informasi & keterampilan HiColers",
        },
        {
          image: "/img/event/interaktif-sesi.png",
          title: "Interaktif Sesi",
          desc: "Komunikasi 2 arah antara narasumber dan peserta selama pelaksanaan event berlangsung",
        },
        {
          image: "/img/event/e-certificate.png",
          title: "E-Certificate",
          desc: "Peserta mendapatkan sertifikat Partisipan atau Penyelesain setelah event berlangsung ",
        },
        {
          image: "/img/event/harga_terjangkau.png",
          title: "Harga Terjangkau",
          desc: "Tidak perlu risau karena harga event terjangkau dan terdapat event gratis",
        },
      ],
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      questions: [],
      events: [],
    };
  },
  components: {
    Button,
    Popup,
    Slash,
    // ArrowLong,
    // Arrow,
  },
  methods: {
    contactMe() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=EVENT&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.slug_name);
      if (
        total_registrants >= item.participant_quota &&
        item.participant_quota !== 0
      ) {
        this.notif.isAlertErr = true;
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh";
        return;
      }
      this.$router.push("/checkout/event/" + item.slug_name);
    },
    toDetail(item) {
      this.$router.push("/event/detail/" + item.slug_name);
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.events.forEach((item) => {
          const end = new Date(item.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item.expired = [days, hours, minutes, seconds];
        });
      };
      setInterval(showRemaining, 1000);
    },
    discountPercent(event) {
      return Math.round(
        ((event.price - (event.price - event.discount_price)) / event.price) *
          100
      );
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get("/v1/event?is_active=true");
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules
            ? item.session_schedules
              ? item.session_schedules
              : [
                  {
                    date_end: "",
                    date_start: "",
                    end_time: "",
                    start_time: "",
                  },
                ]
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        }));
      } catch (err) {
        console.log(err);
      }
    },
    checkIsEarlyBird(item) {
      if (
        new Date(item.start_discount_date) <= new Date() &&
        new Date(item.end_discount_date) >= new Date()
      ) {
        return true;
      } else {
        return false;
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    scroolTo() {
      window.scrollTo(0, 1600);
    },
  },
  mounted() {
    this.getDataEvents();
    this.countDownTimer();
    this.getFaq();
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./event.css" scoped></style>
