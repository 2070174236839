<template>
  <div>
    <!-- Hero Section -->
    <popup :message="alertErrMessage" v-if="isAlertErr" :isError="isError" @close="isAlertErr = false" />

    <div class="hero">
      <div class="content">
        <h1>Bangun Kolaborasi Bersinergi untuk Tingkatkan Performa Bisnis</h1>
        <p>
          Perluas skala bisnis melalui kolaborasi dan sinergi bersama HiColleagues sebagai mitra terpercaya dalam merancang dan menyelenggarakan produk/event pelatihan
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection('collab')">KOLABORASI SEKARANG</button>
          <button class="call" @click="sendToWa">HUBUNGI KAMI</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/corporate/collaboration/collaboration.png" width="520" height="" alt="" />
      </div>
    </div>
    <div class="container-fade">
      <div class="left-fade"></div>
      <div class="right-fade"></div>
    </div>
    <!-- Logo -->
    <div class="logo">
      <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
      <div class="card" style="overflow-x: none !important">
          <div v-for="(partner, index) in partners" :key="index">
            <div class="logo-card-partner-2">
              <!-- <img src="/img/partner/astrapay.png" width="160" /> -->
              <img :src="partner.img_url_web" width="160" />
            </div>
          </div>
        </div>
    </div>

    <!-- Produk Kolaborasi -->
    <div class="product-collaboration">
      <h1>HiColleagues Corporate Collaboration</h1>
      <p>
        Perluas kerjasama kemitraan melalui program unggulan HiColleagues dan berikan dampak ke peningkatan performa bisnis Anda
      </p>
      <div class="card-product">
        <div class="product" v-for="(product, index) in products" :key="index">
          <img :src="product.icon" width="60" height="60" alt="" />
          <h3>{{ product.title }}</h3>
          <p>{{ product.desc }}</p>
        </div>
      </div>
    </div>

    <div class="bg-linear">
      <!-- USP -->
      <div class="usp">
        <div class="card-usp">
          <h1>Mengapa Memilih HiColleagues sebagai Mitra Corporate Training</h1>
          <p>
            HiColleagues telah membuktikan melalui <b>30+ produk kerjasama kemitraan perusahaan yang telah dilaksanakan</b> dan memberi dampak positif terhadap akselerasi performa bisnis mitra
          </p>
          <div class="card-img">
            <img src="/img/corporate/collaboration/training.png" alt="" />
          </div>
        </div>
        <div class="usp-container">
          <div class="card-usp" v-for="(usp, index) in usps" :key="index">
            <div class="title-container mb-2">
              <img :src="usp.image" width="45" height="45" alt="" />
              <div>
                <h3>{{ usp.title }}</h3>
                <p>{{ usp.desc }}</p>
              </div>
            </div>
            <div class="content">
            </div>
            <hr />
          </div>
        </div>
      </div>

      <!-- Langkah Kolaborasi -->
        <StepCollaboration title="Langkah Kolaboratif Kemitraan" :steps="steps" />
    </div>

    <!-- Portfolio Event Collaboration -->
    <portofolio title="Proyek yang Telah Berjalan" :portfolios="portfolios" />
    <Testimony title="Testimoni Kepuasan Klien Kami" :testimonies="testimonies" />

    <!-- Form Pendaftaran Kolaborasi -->
    <div class="form-konsultasi" id="collab">
      <div class="header text-center">
        <h1>Formulir Pendaftaran Konsultasi</h1>
      </div>
      <div class="sub-heading mt-1 text-center">
        <p>
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </p>
        <v-form class="text-start" style="margin-top: 60px">
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="company"
                >Nama Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="company"
                v-model="form.corporate_name"
                outlined
                placeholder="Masukkan Nama Perusahaan"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="website">Website Perusahaan</label>
              <v-text-field
                class="form-input text-form"
                v-model="form.web_url"
                name="website"
                outlined
                placeholder="Masukkan Alamat Website Perusahaan"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="pic-name"
                >Nama Lengkap PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="pic-name"
                v-model="form.full_name"
                outlined
                placeholder="Masukkan Nama Lengkap PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field
                class="form-input text-form"
                name="occupation"
                v-model="form.job_pic" 
                outlined
                placeholder="Masukkan Okupasi PIC"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="email"
                >Email PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="email"
                v-model="form.email"
                outlined
                placeholder="Masukkan Email PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="phone-number"
                >No. Whatsapp PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="phone-number"
                outlined
                placeholder="Masukkan No. Whatsapp PIC"
                type="number"
                v-model="form.phone_number"
                hide-spin-buttons
              >
                <template v-slot:prepend-inner>
                  <v-row class="d-flex align-center mt-0 ml-7">
                    <img
                      src="/img/other/flag-indonesia.png"
                      alt=""
                      width="30"
                      height="20"
                      style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)"
                    />
                    <span style="color: #575966" class="mx-3">+62</span>
                  </v-row>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="form-collab">
              <label class="form-label" for="collaboration"
                >Proyeksi Kolaborasi/Kemitraan</label
              >
              <v-text-field
                class="form-input text-form"
                solo
                flat
                background-color="rgba(247, 249, 253, 1)"
                placeholder="Corporate  Collaboration"
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-3">
            <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
              <template v-slot:label>
                <p class="label-checkbox">
                  Dengan mengirim formulir ini saya menyetujui<a href="/term-condition">
                    Syarat & Ketentuan </a>untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                </p>
              </template>
            </v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-6">
            <v-btn elevation="0" :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`" @click="onSubmit">KIRIM </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels
              flat
              class="faq-card"
              style="padding: 20px; background-color: #F1F6FF;"
            >
              <v-expansion-panel v-for="(item, index) in questions"
              :key="index" style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header
                  disable-icon-rotate
                  @click="openFaq(index)"
                >
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active"/>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="btn-stoke-blue" @click="$router.push('/faq')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
        </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import Portofolio from '../../Portofolio.vue';
import StepCollaboration from '../../StepCollaboration.vue';
import Testimony from "../../Testimony.vue";
import Popup from "@/components/others/Popup.vue";
import Arrow from '@/assets/icons/Arrow.vue';
import ArrowLong from '@/assets/icons/ArrowLong.vue';

export default {
  components: {
    StepCollaboration,
    Testimony,
    Portofolio,
    Popup,
    Arrow,
    ArrowLong
  },
  name: "DesktopCorporateCollaboration",
  data() {
    return {
      partners: [],
      products: [
        {
          icon: "/img/corporate/collaboration/f1.png",
          title: "Workshop",
          desc: "Sesi interaktif yang dirancang untuk membahas tentang topik tertentu dengan memberikan kesempatan peserta untuk belajar secara praktis melalui studi kasus, diskusi kelompok, dan latihan secara langsung. ",
        },
        {
          icon: "/img/corporate/collaboration/f2.png",
          title: "Webinar",
          desc: "Seminar online dalam mendapatkan wawasan melalui platform virtual, dengan mengikuti presentasi, panel diskusi, dan sesi tanya jawab yang disampaikan oleh para profesional di bidangnya.",
        },
        {
          icon: "/img/corporate/collaboration/f3.png",
          title: "Mini Bootcamp",
          desc: "Pelatihan intensif jangka pendek dengan fokus terhadap pengembangan keterampilan tertentu, dirancang untuk memberikan pelatihan yang mendalam dan praktis dalam waktu singkat.",
        },
        {
          icon: "/img/corporate/collaboration/f4.png",
          title: "Bootcamp",
          desc: "Program pelatihan intensif yang panjang & komprehensif dengan mencakup kurikulum yang dirancang khusus untuk pengembangan keterampilan teknis & profesional yang mendalam.",
        },
        {
          icon: "/img/corporate/collaboration/f5.png",
          title: "Training",
          desc: "Program pelatihan yang dirancang khusus untuk meningkatkan keterampilan spesifik yang dibutuhkan di dunia industri yang bertujuan menjadi profesional yang kompeten.",
        },
      ],
      usps: [
        {
          image: "/img/corporate/collaboration/b1.png",
          title: "Kolaborasi Inklusif",
          desc: "Layanan kolaborasi yang dirancang untuk membangun budaya kerja inklusif dan membangun Tim yang lebih kuat dan kolaboratif",
        },
        {
          image: "/img/corporate/collaboration/b2.png",
          title: "Bangun Kredibilitas Mitra",
          desc: "HiColleagues dapat memberikan validitas tambahan pada perusahaan Anda sebagai our trusted partner.",
        },
        {
          image: "/img/corporate/collaboration/b3.png",
          title: "Tingkatkan Produktivitas & Efisiensi Tim",
          desc: "Bermitra dengan HiColleagues untuk membuka potensi penuh tim Anda dan mencapai tujuan bisnis secara lebih efektif.",
        },
        {
          image: "/img/corporate/collaboration/b4.png",
          title: "Strategi Kolaboratif Terbaik",
          desc: "Layanan kolaborasi yang menerapkan strategi kolaboratif dan solusi terpadu dalam membantu untuk mencapai tujuan bisnis Anda.",
        },
        {
          image: "/img/corporate/collaboration/b5.png",
          title: "Best Practice",
          desc: "Penerapan best practice & metodologi kolaboratif yang telah terbukti efektif di berbagai industri. HiColleagues memiliki proses kontrol kualitas yang ketat untuk memastikan program kolaborasi memenuhi standar tertinggi.",
        },
        {
          image: "/img/corporate/collaboration/b6.png",
          title: "Tingkat kepuasan Klien yang Tinggi",
          desc: "HiColleagues berkomitmen untuk terus memberikan tingkat kepuasan klien yang unggul dengan layanan kolaborasi terbaik.",
        },
      ],
      steps: [
        {
          image_url: "/img/corporate/collaboration/c1.png",
          title: "Identifikasi Kebutuhan",
          description:"Tim ahli kami akan melakukan analisis mendalam terhadap kebutuhan bisnis Anda, tujuan perusahaan, tantangan yang dihadapi, mengidentifikasi area-area yang membutuhkan pengembangan & merancang solusi yang tepat dan terukur untuk memenuhi kebutuhan spesifik Anda",
        },
        {
          image_url: "/img/corporate/collaboration/c2.png",
          title: "Merancang Konsep Inovatif",
          description:"Kami akan merancang konsep dan strategi terbaik untuk mengatasi tantangan yang dihadapi perusahaan Anda menggunakan strategi pendekatan yang kreatif dan inovatif untuk menghasilkan solusi yang terdepan dan sesuai dengan tren terkini.",
        },
        {
          image_url: "/img/corporate/collaboration/c3.png",
          title: "Mengeksekusi",
          description:"Proyek kolaborasi akan ditangani oleh tim profesional dan berpengalaman yang memiliki spesialisasi di berbagai bidang. Tim kami akan selalu berkomunikasi dengan Anda untuk memastikan proyek berjalan sesuai dengan rencana dan target yang ditetapkan",
        },
        {
          image_url: "/img/corporate/collaboration/c4.png",
          title: "Pelaporan yang Transparan & Akurat",
          description:"Kami akan menyediakan laporan analisis yang komprehensif dan rinci mengenai hasil implementasi program kolaborasi yang mencakup metrik yang jelas dan terukur untuk menunjukkan kemajuan yang telah dicapai.",
        },
        {
          image_url: "/img/corporate/collaboration/c5.png",
          title: "Analisis Pasca Proyek",
          description:"Pasca proyek, kami melakukan analisis mengenai hasil yang telah dicapai. Dengan melihat Data dan informasi yang diperoleh dari analisis ini dapat digunakan untuk menyusun strategi yang lebih efektif untuk mencapai tujuan bisnis Anda.",
        },
      ],
      portfolios: [],
      questions: [],
      testimonies: [],
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "COLLABORATION",
        web_url: "",
        job_pic: ""
      },
    };
  },
  methods: {
    duptlicatePartners() {
      this.partners = this.partners.concat(this.partners, this.partners, this.partners);
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get('/v1/logo?category=COLLABORATION');
        this.partners = resp.data.data.logos;
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=COLLABORATION&page=1&per_page=9');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },

    async getPortofolio() {
      try {
        const resp = await this.$http.get('/v1/project-portfolio?category=COLLABORATION&is_main=true');
        this.portfolios = resp.data.data.project_portfolios;
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=COLLABORATION&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return
      }
      if (!this.form.corporate_name || !this.form.full_name || !this.form.job_pic || !this.form.email || !this.form.phone_number || !this.form.category || !this.form.web_url) {
        this.alertErrMessage = "Harap isi semua form yang tersedia"
        this.isAlertErr = true
        this.isError = true
        return
      }
      try {
        await this.$http.post('/v1/corporate-registration', this.form)
        this.alertErrMessage = "Success"
        this.isAlertErr = true
        this.isError = false
        this.clearForm()
      } catch {
        this.alertErrMessage = "Error saat submit data"
        this.isAlertErr = true
        this.isError = true
      }
    },
    clearForm() {
      this.is_confirm = false
      this.form.full_name = ""
      this.form.email = ""
      this.form.phone_number = ""
      this.form.corporate_name = ""
      this.form.category = ""
      this.form.web_url = ""
      this.form.job_pic = ""
    },
  },
  mounted() {
    this.getClientLogo() 
    this.getTestimoni()
    this.getPortofolio()
    this.getFaq()
  },
  
};
</script>

<style src="./corporateCollaboration.css" scoped></style>