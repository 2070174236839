<template>
  <div style="background-color: #f1f6ff; padding-top: 64px">
    <h2
      style="
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #36373f;
        text-align: center;
        width: 328px;
        margin: 0px auto 24px auto;
      "
    >
      Jelajahi Kelas HiColleagues
    </h2>
    <div v-for="i in subCategories" :key="i.name" class="bg">
      <h4
        style="
          margin-bottom: 24px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #36373f;
        "
      >
        {{ i.name }}
      </h4>
      <div
        style="
          display: flex;
          overflow: auto;
          gap: 16px;
          padding: 0px 16px 20px 16px;
        "
      >
        <div
          v-for="n in i.recommendationClass"
          :key="n.id"
          class="content-card-3"
        >
          <router-link
            style="text-decoration: none"
            :to="'/class/public-training/' + n.slug_name"
          >
            <div style="height: 164px; overflow: hidden">
              <img
                style="
                  width: 100%;
                  min-height: 164px;
                  border-top-left-radius: 16px;
                  border-top-right-radius: 16px;
                "
                :src="n.thumbnail_url"
                class=""
              />
            </div>
            <div style="padding: 16px">
              <div style="display: flex; gap: 4px; margin: 16px 0px 8px 0px">
                <div
                  :style="{
                    textAlign: 'center',
                    backgroundColor: '#F1F6FF',
                    borderRadius: '30px',
                  }"
                >
                  <p
                    style="
                      font-weight: 400;
                      font-size: 11px;
                      line-height: 14px;
                      margin: 0px;
                      padding: 2px 8px;
                      color: #36373f;
                    "
                  >
                    {{ n.level }}
                  </p>
                </div>
                <div
                  :style="{
                    textAlign: 'center',
                    backgroundColor: '#FAF6E7',
                    borderRadius: '30px',
                  }"
                >
                  <p
                    style="
                      font-weight: 400;
                      font-size: 11px;
                      line-height: 14px;
                      margin: 0px;
                      padding: 2px 8px;
                      color: #36373f;
                    "
                  >
                    {{ n.method || 'Online/Offline' }}
                  </p>
                </div>
                <div
                  :style="{
                    textAlign: 'center',
                    backgroundColor: '#faeceb',
                    borderRadius: '30px',
                  }"
                  v-if="n.count_session"
                >
                  <p
                    style="
                      font-weight: 400;
                      font-size: 11px;
                      line-height: 14px;
                      margin: 0px;
                      padding: 2px 8px;
                      color: #36373f;
                    "
                    
                  >
                    {{ n.count_session }}
                  </p>
                </div>
              </div>
              <div style="height: 35px;">
                <div class="title-2" style="text-align: left">{{ n.name }}</div>
              </div>
              <div style="height: 55px;">
              <div class="desc mt-2" style="text-align: left">
                {{ n.description }}
              </div>
            </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="!i.recommendationClass.length">
                <div class="text-center" style="margin: 20px 0">
                    <img src="/img/other/ic_coming_soon.png" height="300" />
                </div>
            </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryId: 1, 
      subCategories: [
        {
          name: "KELAS PROGRAMMING",
          color: "#fff",
          recommendationClass: [],
        },
        {
          name: "KELAS DATA",
          color: "#F1F6FF",
          recommendationClass: [],
        },
        {
          name: "KELAS LAINNYA",
          color: "#fff",
          recommendationClass: [],
        },
      ],
    };
  },
  methods: {
    async getClass() {
      if (this.categoryId == 1) {
      this.subCategories = [
        {
          name: 'Kelas Programming',
          recommendationClass: [],
        },
        {
          name: 'Kelas Data',
          recommendationClass: [],
        },
        {
          name: 'Kelas Desain',
          recommendationClass: [],
        },
      ];
    } else {
      this.subCategories = [
        {
          name: '',
          recommendationClass: [],
        },
      ];
    }
      await this.$http
        .get(`/v1/class/category/${this.categoryId}`)
        .then((response) => {
          response.data.data.classes.forEach((element) => {
              if (this.categoryId == 1) {
            if (element.class_sub_category.id == 1) {
              this.subCategories[0].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            } else if (element.class_sub_category.id == 2) {
              this.subCategories[1].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            } else {
              this.subCategories[2].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            }
          } else {
            this.subCategories[0].recommendationClass.push({
              id: parseInt(element.id),
              name: element.name,
              thumbnail_url: element.thumbnail_url,
              description: element.description,
              level: element.level,
              method: element.method,
              slug_name: element.slug_name,
            });
          }
          });
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
  },
  mounted() {
    this.categoryId = this.$route.query.category ?? 1;
    this.getClass();
  },
};
</script>

<style src="./css/technologyCategory.css" scoped></style>
