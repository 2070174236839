<template>
  <div class="container-size-blog">
    <img loading="lazy" :src="blog.image_url" class="img-ctn-mobile" v-if="mobileView" />
    <div class="back-btn" @click="toBack" v-if="mobileView">
      <img src="/img/other/arrow-back.png" alt="back icon" />
    </div>
    <v-alert class="alert-info" type="success" :value="show_alert">Copied</v-alert>
    <div class="spacing" v-if="mobileView"></div>
    <div class="main-page">
      <div class="navigation" v-if="!mobileView">
        <span @click="toBlog" class="nav-active font-poppins">Home</span>
        <Arrow :width="20" direct="left" />
        <span class="font-poppins">{{ blog.title }}</span>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="text-center mt-5" v-if="!mobileView">
            <img loading="lazy" :src="blog.image_url" class="img-main-dekstop" />
          </div>
          <div class="author">
            <div class="author-info">
              <span class="font-poppins">{{ onDateFormatting(blog.date_release) }}</span>
              <span class="circle"></span>
              <span v-if="blog.reading_minute" class="font-poppins">Dibaca {{ blog.reading_minute }} menit</span>
            </div>
          </div>
          <div class="author" v-if="mobileView">
            <img src="/img/other/ic_facebook_gray.png" alt="facebook" title="Share in facebook"
              @click="onShereFacebook" />
            <img src="/img/other/ic_linkedin_gray.png" alt="linkedin" title="Share in linkedin"
              @click="onShereLinkedin" />
            <img src="/img/other/ic_email_gray.png" alt="email" title="Share in email" @click="onShereEmail" />
            <img src="/img/other/ic_copylink_gray.png" alt="copy" title="copy url" @click="onCopyUrl" />
          </div>
          <h2 class="mt-5">{{ blog.title }}</h2>
          <div class="text-justify mt-5 main-body" ref="contentContainer" v-html="blog.body"></div>
          <div class="art-content">
            <h5 class="font-poppins mb-4">
              Kamu Mungkin Juga Suka
            </h5>
            <div class="art-content-list">
              <div class="card" v-for="(item, idx) in blogs" :key="idx" @click="toArticle(item.slug_name)">
                <img class="card-img" :src="item.thumbnail_url" alt="content" />
                <div class="card-content">
                  <p class="bold title-berita">
                    {{ $minimizeText(item.title, 110) }}
                  </p>
                  <span class="font-poppins">{{ onDateFormatting(item.date_release) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-scroll">
            <p>Share</p>
            <span title="Share in facebook" @click="onShereFacebook">
              <facebook />
            </span>
            <span title="Share in linkedin" @click="onShereLinkedin">
              <linkedin />
            </span>
            <img src="/img/other/ic_email_gray.png" alt="email" title="Share in email" @click="onShereEmail" />
            <span title="copy url" @click="onCopyUrl">
              <copy />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import Facebook from '@/assets/icons/blog/Facebook.vue';
import Linkedin from '@/assets/icons/blog/Linkedin.vue';
import Copy from '@/assets/icons/blog/Copy.vue';

export default {
  data() {
    return {
      blog: {
        title: "",
        body: "",
        summary: ""
      },
      show_alert: false,
      mobileView: false,
      isLoading: false,
      blogs: [],
      mounth_list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  components: {
    Arrow,
    Facebook,
    Copy,
    Linkedin
  },
  metaInfo() {
    const blog = this.blog
    const thisPath = window.location.href
    return {
      title: blog.title,
      htmlAttrs: {
        lang: "id",
        amp: true,
      },
      meta: [
        { name: "keywords", content: blog.title },
        { name: "description", content: blog.body.slice(0, 80) },
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": blog.title,
            "url": thisPath,
            "potentialAction": {
              "@type": "SearchAction",
              "target": thisPath
            }
          }
        }
      ]
    };
  },
  watch: {
    show_alert() {
      if (!this.show_alert) return;
      setTimeout(() => {
        this.show_alert = false;
      }, 3000);
    },
  },
  methods: {
    async getBlogDetail() {
      // this.spin_load.val = true
      this.isLoading = true;
      await this.$http
        .get("/v1/blog/" + this.$route.params.slugname)
        .then((response) => {
          this.isLoading = false;
          this.blog = response.data.data.blog;
        })
        .catch(() => {
          this.isLoading = false;
        });
      // .finally(() => this.spin_load.val = false)
    },
    async getBlogs() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/blog/related")
        .then((response) => {
          this.blogs = response.data.data.blogs;
        })
        .catch((err) => console.error(err.response));
    },
    toBlog() {
      this.$router.push("/blog");
    },
    onDateFormatting(dateStr) {
      const date = new Date(dateStr);
      return `${date.getDate()} ${this.mounth_list[date.getMonth()]
        } ${date.getFullYear()}`;
    },
    onCopyUrl() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(window.location.href);
      this.show_alert = true;
    },
    onShereEmail() {
      let url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${this.blog.title}&body=${window.location.href}&ui=2&tf=1&pli=1`;
      window.open(url);
    },
    onShereLinkedin() {
      let url = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}&title=${this.blog.title}`;
      window.open(url);
    },
    onShereFacebook() {
      let url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
      window.open(url);
    },
    toArticle(url) {
      this.$router.push("/blog/" + url);
      this.getBlogDetail();
      this.getBlogs();
      window.scrollTo(0, 0);
    },
    toBack() {
      this.$router.go(-1);
    },
    handleView() {
      if (window.innerWidth <= 990) {
        this.mobileView = true;
      }
    },
    applyStyles() {
      const container = this.$refs.contentContainer;
      if (container) {
        container.querySelectorAll('*').forEach(p => {
          p.style.fontFamily = 'Poppins, sans-serif';
        });
        container.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(heading => {
          heading.querySelectorAll('strong').forEach(strong => {
            const parent = strong.parentNode;
            while (strong.firstChild) {
              parent.insertBefore(strong.firstChild, strong);
            }
            strong.remove();
          });
        });
      }
    }
  },
  created() {
    this.getBlogDetail();
  },
  updated() {
    this.applyStyles();
  },
  mounted() {
    this.getBlogDetail();
    this.applyStyles();
    this.getBlogs();
    this.handleView();
    this.$gtag.pageview(this.$route);
  },
};
</script>

<style src="./detailBlog.css" scoped></style>
