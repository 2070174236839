<template>
  <div>
    <v-alert class="alert-info" type="success" :value="show_alert"
      >Copied</v-alert
    >
    <popup :message="errMessage" v-if="isErr" @close="isErr = false" />
    <div class="banner">
      <img src="/img/bootcamp/main2.png" class="img-banner" />
      <div class="body-banner">
        <h2 class="title-banner">
          Temukan Peluang Besar untuk Wujudkan Karir Impian
        </h2>
        <p class="sub-title-banner">
          Sambut peluang masa depan dan tingkatkan karir cemerlang bersama
          HiColleagues
        </p>
        <button
          class="button-daftar"
          style="background-color: #ffde59; color: #36373f"
          @click="scrollToId('Karir')"
        >
          INFO PEKERJAAN
        </button>
        <button
          class="button-daftar"
          @click="sendToWa"
          style="background-color: #ffffff; color: #162ca2"
        >
          KONSULTASI GRATIS
        </button>
      </div>
    </div>
    <div class="ctn-career-about">
      <h3 class="sub-title-about">
        HiColleagues Pilihan Tepat dalam Wujudkan Karir Impian
      </h3>
      <div class="ctn-content-card">
        <div v-for="n in benefits" :key="n.id" class="content-card">
          <div class="left-span">
            <img
              :src="n.image"
              width="48"
              height="48"
              style="border-radius: 50%"
            />
          </div>
          <div>
            <div class="title-2" style="text-align: left">
              {{ n.title }}
            </div>
            <div class="desc" style="text-align: left">
              {{ n.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-coll">
      <div class="collaboratif">
        <h1>Langkah Kolaborasi Kemitraan</h1>
        <div class="divider-container">
          <div class="divider-line-container">
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
          </div>
          <div class="divider-circle">
            <div class="inner-circle"></div>
          </div>
          <div class="divider-line-container">
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
            <div class="divider-line"></div>
          </div>
        </div>
        <div class="desc">
          <div class="card-content" v-for="(data, index) in steps" :key="index">
            <div class="card-img">
              <img
                :src="data.image_url"
                alt=""
                width="100"
                height="100"
                style="
                  border-radius: 10px;
                  position: absolute;
                  top: 0;
                  margin-top: -45px;
                  margin-left: 15px;
                  z-index: 0;
                "
              />
            </div>
            <div class="desc">
              <h3>{{ data.title }}</h3>
              <p>{{ data.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <h1 id="Karir" class="pt-10 text-center" style="font-size: 20px;">
        Jelajahi Peluang Karir HiColleagues
      </h1>
      <div class="content">
        <v-row>
          <v-col cols="12">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field
                    v-model="filter.position"
                    type="text"
                    class="f-input"
                    placeholder="Cari Pekerjaan"
                    dense
                    flat
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              color="#ACAFBF"
              placeholder="Tipe Pekerjaan"
              v-model="filter.work_type"
              :items="workTypeList"
              :item-text="'name'"
              :item-value="'value'"
              style="border-radius: 10px !important; background-color: white"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              color="#ACAFBF"
              placeholder="Level Pekerjaan"
              v-model="filter.level"
              :items="levelList"
              :item-text="'name'"
              :item-value="'value'"
              style="border-radius: 10px !important; background-color: white"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <Button
              name="CARI"
              width="100%"
              height="54px"
              @click="() => getCareer()"
            />
          </v-col>
        </v-row>
        <div class="list-career">
          <div
            class="list-career-ctn"
            v-for="(data, idx) in listCareer"
            :key="idx"
            @click="$router.push(`/career/${data.id}`)"
          >
            <img :src="data.logo ? data.logo : '/img/career/logo_sample.png'" />
            <h2 class="mb-6 mt-6">{{ data.name }}</h2>
            <p style="font-weight: 500">{{ data.company }}</p>
            <p style="font-weight: 400">{{ data.location }}</p>
            <div class="career-data">
              <div class="label">
                <div
                  style="background-color: #f1f6ff"
                  v-if="data.contract_type"
                >
                  <clock-ic width="18px" height="18px" />
                  <p>{{ data.contract_type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="data.work_type">
                  <location-ic width="18px" height="18px" />
                  <p>{{ data.work_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="data.level">
                  <level-ic width="18px" height="18px" />
                  <p>{{ data.level }}</p>
                </div>
              </div>
            </div>
            <div class="btn-daftar mt-8">
              <div class="btn-daftar-act">
                <Button
                  name="LAMAR SEKARANG"
                  width="100%"
                  @click="() => toCheckout(data)"
                />
              </div>
              <div class="btn-daftar-cpy">
                <div class="button-outline" @click.stop="() => onCopy(data.id)">
                  <CopyIc width="25px" height="25px" />
                </div>
              </div>
            </div>
            <p class="text-center mt-8">
              Batas Lamar
              <b style="font-family: 'Poppins'">{{ moment(data.closed_at) }}</b>
            </p>
          </div>
        </div>
        <Button
          v-if="total > 1"
          style="margin-top: 32px"
          name="MUAT LEBIH BANYAK"
          width="100%"
        />
        <EmptyData
          v-if="!listCareer || !listCareer.length"
          message="Belum ada Karir saat ini"
        />
      </div>
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
          <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')" style="background-color: white; margin-top: 30px;">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../others/Button.vue";
import CopyIc from "@/assets/icons/career/Copy.vue";
import EmptyData from "../../../others/EmptyData.vue";
import ClockIc from "@/assets/icons/career/Clock.vue";
import LevelIc from "@/assets/icons/career/Level.vue";
import LocationIc from "@/assets/icons/career/Location.vue";
import moment from "moment";
import ArrowLong from "@/assets/icons/ArrowLong.vue";
import Popup from "@/components/others/Popup.vue";
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  name: "MobileCareer",
  components: {
    Button,
    AccordeonFaq,
    ArrowLong,
    ClockIc,
    LevelIc,
    LocationIc,
    CopyIc,
    EmptyData,
    Popup,
  },
  data() {
    return {
      benefits: [
        {
          image: "/img/about/c2.png",
          title: "Peluang Karir Terbaru",
          desc: "Informasi up-to-date secara berkala menyesuaikan kebutuhan industri terkini memberikan kesempatan karir bagi HiColers secara aktual",
        },
        {
          image: "/img/about/b6.png",
          title: "Kemudahan Akses",
          desc: "Platform HiColleagues memudahkan pelamar dalam mencari dan mengelola lamaran hingga proses rekrutmen selesai",
        },
        {
          image: "/img/about/b4.png",
          title: "Mitra Terpercaya",
          desc: "HiColleagues telah memiliki pengalaman rekrutmen yang kredibel, sehingga kami yakin dapat menghadirkan pengalaman terbaik bagi pelamar dalam mewujudkan impian karir",
        },
      ],
      steps: [
        {
          image_url: "/img/corporate/tech/bn1.png",
          title: "Temukan Lowongan Pekerjaan",
          description:
            "Jelajahi berbagai informasi lowongan pekerjaan dan sesuaikan dengan minat karir HiColers. Informasi yang aktual dan selaras dengan industri terkini akan ter-update secara berkala untuk kemudahan informasi bagi pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn2.png",
          title: "Lamar Pekerjaan",
          description:
            "Lakukan pendaftaran dan pastikan data yang HiColers kirim telah sesuai dengan benar. Data admistrasi akan digunakan sebagai acuan dalam proses seleksi kandidat pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn3.png",
          title: "Seleksi Administratif",
          description:
            "Data pelamar yang telah berhasil terkirim akan dilakukan kurasi oleh tim rekrutmen HiColleagues. Hal ini untuk memvalidasi data telah sesuai dengan kualifikasi atau kriteria pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn4.png",
          title: "Uji Keterampilan",
          description:
            "Merupakan tahap validasi kemampuan pelamar terhadap kebutuhan peran pekerjaan. Pelamar akan mengerjakan study case tertentu dan hasilnya akan menjadi pertimbangan sebagai standar kualifikasi keterampilan pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "interview",
          description:
            "Apabila secara administratif dan Uji Keterampilan pelamar telah lolos, maka, akan dilakukan sesi interview baik oleh tim rekrutmen HiColleagues maupun Users. Hasil dari tahap ini akan digunakan sebagai Final Assessment pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "Offering",
          description:
            "Setelah rangkaian seleksi dilakukan, apabila pelamar telah tervalidasi sesuai dengan kualifikasi yang dibutuhkan, maka tim rekrutmen HiColleagues akan memberikan penawaran terhadap peran atau okupasi yang dibutuhkan.",
        },
      ],
      workTypeList: [
        {
          name: "Full-Time",
          value: "Full-Time",
        },
        {
          name: "Part-Time",
          value: "Part-Time",
        },
        {
          name: "Freelance",
          value: "Freelance",
        },
        {
          name: "Internship",
          value: "Internship",
        },
      ],
      levelList: [
        {
          name: "Head",
          value: "Head",
        },
        {
          name: "Senior",
          value: "Senior",
        },
        {
          name: "Middle",
          value: "Middle",
        },
        {
          name: "Junior",
          value: "Junior",
        },
      ],
      filter: {
        position: "",
        work_type: "",
        level: "",
      },
      errMessage: "",
      show_alert: false,
      isErr: false,
      currentPage: 1,
      total: 1,
      questions: [],
      testimonies: [],
      listCareer: [],
    };
  },
  watch: {
    show_alert() {
      if (!this.show_alert) return;
      setTimeout(() => {
        this.show_alert = false;
      }, 3000);
    },
  },
  methods: {
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToId(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    scrollRightStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft += cardWidth * 1.07;
      if (this.indicator < 2) {
        this.indicator++;
        if (this.indicator2 < 1) {
          this.indicator2++;
        }
      }
      if (this.indicator === 2 && this.indicator3 < 1) {
        this.indicator3++;
      }
    },
    scrollLeftStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft -= cardWidth * 1.07;
      if (this.indicator === 1) {
        this.indicator2--;
      }
      if (this.indicator > 0) {
        this.indicator--;
      }
      if (this.indicator3 < 2 && this.indicator > 0) {
        this.indicator3--;
      }
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    toCheckout(data) {
      if (new Date() > new Date(data.closed_at)) return;
      this.$router.push(`/career/applicant/${data.id}`);
    },
    onCopy(id) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(`${window.location.href}/${id}`);
      this.show_alert = true;
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=CAREER&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        this.isErr = true;
        this.errMessage = err.response.data.data;
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=CAREER&is_active=true&page=1&per_page=4"
        );
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        this.isErr = true;
        this.errMessage = err.response.data.data;
      }
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, "_blank");
      } else {
        this.$router.push({ path: e }).then(() => {
          this.$router.go();
        });
      }
    },
    async getCareer() {
      try {
        const res = await this.$http.get(
          `/v1/career?position=${this.filter.position}&level=${this.filter.level}&work_type=${this.filter.work_type}&page=${this.currentPage}&per_page=9`
        );
        this.listCareer = res.data.data.careers;
        this.total = Math.floor(res.data.data.total / 9) + 1;
        console.log(res.data.data);
      } catch (err) {
        this.isErr = true;
        this.errMessage = err.response.data.data;
      }
    },
  },
  mounted() {
    this.getFaq();
    this.getTestimoni();
    this.getCareer();
  },
};
</script>

<style src="./career.css" scoped></style>
