<template>
    <div class="partner">
      <div class="partner-title text-center">
        <h1>{{ title }}</h1>
      </div>
      <div class="partner-content">
        <div class="partner-container">
          <div
            class="card col-12 cursor-pointer"
            v-for="(item, idx) in clona" :key="idx" :title="item.name"
          >
            <div class="portfolio-card">
              <div class="event-box"  v-for="(item2, idx2) in item" :key="idx2">
            <img
              :src="item2.img_urls.desktop.thumbnail"
              alt="Content"
              class="event-box-img"
              @click="toDetail(item2)"
            />
            <div class="event-ctn">
              <div @click="toDetail(item2)">
                <h3 class="judul-event">{{ item2.name }}</h3>
                <div class="event-ctn-label">
                  <span>{{ item2.category.name }}</span>
                </div>
                <div class="event-ctn-info">
                  <DateIcon fill="#575966" />
                  <span class="name">{{ moment(item2.session_schedules[0].date_start) }}</span>
                </div>
                <div class="event-ctn-info">
                  <clock fill="#575966" />
                  <span class="name"
                    >{{ item2.session_schedules[0].start_time }} - {{ item2.session_schedules[0].end_time }} WIB</span
                  >
                </div>
                <div class="event-ctn-info">
                  <people fill="#575966" />
                  <span class="name"
                    >{{ item2.participant_quota ? `${item2.participant_quota} Peserta` : "Tidak terbatas" }}</span
                  >
                </div>
                <div class="event-ctn-info">
                  <methode fill="#575966" />
                  <span class="name">{{ item2.method | toMethode }}</span>
                </div>
                
                <div class="event-ctn-info" v-if="item2.instructors.length">
                  <speaker fill="#575966" />
                  <span class="name"
                    >{{ item2.instructors[0].name
                    }}<span v-if="item2.instructors.length > 1"
                      >, {{ item2.instructors.length - 1 }}+ lainnya</span
                    ></span>
                </div>
                <div style="height: 35px" v-else></div>
                <div style="height: 50px;">
                  <p class="event-price" v-if="!checkIsEarlyBird(item2)">
                    {{ item2.price ? toCurrency(item2.price) : "GRATIS" }}
                  </p>
                  <div v-else>
                    <span class="event-price mr-2">{{ (item2.price - item2.discount_price) ? toCurrency(item2.price - item2.discount_price) : "GRATIS" }}</span><del class="discount-price">{{ toCurrency(item2.price) }}</del><br>
                    <span style="font-size: 14px;"><b style="color: #162ca2; font-family: 'Poppins';">Early Bird Promo</b>! Diskon {{discountPercent(item2)}}%</span>
                  </div>
                </div>
                <div
                style="display: flex; justify-content: center; margin: 10px 0"
              >
                <Button
                  :disabled="!item2.expired.length"
                  name="DAFTAR"
                  width="250px"
                  @click="() => toCheckout(item2)"
                />
              </div>
                <div style="height: 60px; margin-top: 20px;">
                  <div class="event-due-date" v-if="item2.expired.length">
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[0] }}</span>
                      <span>Hari</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[1] }}</span>
                      <span>Jam</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[2] }}</span>
                      <span>Menit</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[3] }}</span>
                      <span>Detik</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
               </div>
            </div>
        </div>
        </div>
      </div>
      <div class="partner-button">
        <div class="slider">
          <div
            class="indicator"
            :style="{
              width: `${sliderIndicatorTesti}%`,
              transform: `translateX(${indicatorValueTesti}%)`,
              transition: `transform 0.5s`,
            }"
          ></div>
        </div>
        <div style="margin-right: 20px; display: flex;">
          <img class="arrow-button" src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftPartner" />
          <img class="arrow-button" src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightPartner" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
import moment from "moment";
import Button from "../others/Button.vue";
import Clock from "./icons/Clock.vue";
import DateIcon from "./icons/Date.vue";
import Speaker from "./icons/Speaker.vue";
import Methode from "./icons/Methode.vue";
import People from './icons/People.vue';

  export default {
    name: "EventComponent",
    components: {
    Button,
    DateIcon,
    Clock,
    Methode,
    Speaker,
    People
  },
    props: {
      partners: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        clona: [],
        sliderIndicatorTesti: 0,
        indicatorValueTesti: 0,
      };
    },
    watch: {
      partners() {
        this.clona = []
        for (let i = 0; i < this.partners.length; i += 2) {
          const group = this.partners.slice(i, i + 2);
          this.clona.push(group);
         }
        this.sliderIndicatorTesti = (1 / this.clona.length) * 100;
        if (this.sliderIndicatorTesti >= 100) {
          this.sliderIndicatorTesti = 100;
        }
      },
    },
    methods: {
      scrollRightPartner() {
        const container = this.$el.querySelector(".partner-container");
        const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
        container.scrollLeft += cardWidth;
        this.indicatorValueTesti +=
          this.sliderIndicatorTesti +
          ((this.clona.length - 1) * 100) / this.clona.length;
        if (this.indicatorValueTesti >= (this.clona.length - 1) * 100) {
          this.indicatorValueTesti = (this.clona.length - 1) * 100;
        }
      },
      scrollLeftPartner() {
        const container = this.$el.querySelector(".partner-container");
        const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
        container.scrollLeft -= cardWidth;
        this.indicatorValueTesti -=
          this.sliderIndicatorTesti +
          ((this.clona.length - 1) * 100) / this.clona.length;
        if (this.indicatorValueTesti <= 0) {
          this.indicatorValueTesti = 0;
        }
      },
      toDetail(item) {
      this.$router.push("/event/detail/" + item.id);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.clona.forEach((item) => {
          item.forEach((item2) => {
            const end = new Date(item2.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item2.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item2.expired = [days, hours, minutes, seconds];
          })
        });
      };
      setInterval(showRemaining, 1000);

      },
      checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants  
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.id)
      if (total_registrants >= item.participant_quota && item.participant_quota !== 0) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh"
        return
      }
      this.$router.push("/checkout/event/" + item.id);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    }
    },
    mounted() {
      this.countDownTimer();
    },
  };
  </script>
  
  <style src="./css/event.css" scoped></style>