<template>
    <!-- Dialog -->
    <v-dialog v-if="dialog" v-model="dialog" max-width="100%" :persistent="persistent" @click:outside="closePopup">
        <div class="custom-dialog" @click.self="closePopup">
            <v-card class="container-card" @click="goTo">
                    <v-card class="countdown-card" v-if="isSales">
                        <div class="countdown d-flex g-4">
                            <div class="countdown-label">
                                <p class="fs-5"><b>Berakhir dalam :</b></p>
                            </div>
                            <!-- Divider between days and hours -->
                            <div class="countdown-item" title="hari">
                                <span class="countdown-value">{{ timeLeft.days }}</span>
                            </div>
                            <div class="divider">:</div>
                            <!-- Divider between days and hours -->
                            <div class="countdown-item" title="jam">
                                <span class="countdown-value">{{ timeLeft.hours }}</span>
                            </div>
                            <div class="divider">:</div>
                            <!-- Divider between hours and minutes -->
                            <div class="countdown-item" title="menit">
                                <span class="countdown-value">{{ timeLeft.minutes }}</span>
                            </div>
                            <div class="divider">:</div>
                            <!-- Divider between minutes and seconds -->
                            <div class="countdown-item" title="detik">
                                <span class="countdown-value">{{ timeLeft.seconds }}</span>
                            </div>
                        </div>
                    </v-card>
                    <v-card max-width="450" max-height="800" class="img-card">
                        <v-img :src="imgSrc"> </v-img>
                    </v-card>
                    <v-btn class="close-button" icon @click="closePopup">
                    <close />
                </v-btn>
                </v-card>
    </div>
    </v-dialog>
</template>

<script>
import Close from '@/assets/icons/Close.vue';
export default {
    name: "HitBanner",
    components: {
        Close
    },
    data() {
        return {
            timeLeft: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
        }
    },
    props: {
        endDate: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
        imgSrc: {
            type: String,
            required: true,
        },
        persistent: {
            type: Boolean,
            required: true,
        },
        isSales: {
            type: Boolean,
            required: true,
        },
        linkUrl: {
            type: String,
            required: true,
        },
    },
    computed: {
        countdownFinished() {
            return (
                this.timeLeft.days <= 0 &&
                this.timeLeft.hours <= 0 &&
                this.timeLeft.minutes <= 0 &&
                this.timeLeft.seconds <= 0
            );
        },
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },
        goTo() {
            this.$emit("close");
            window.open(this.linkUrl, '_blank')
        },
        updateCountdown() {
            const now = new Date().getTime();
            const distance = new Date(this.endDate).getTime() - now;

            if (distance > 0) {
                this.timeLeft.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                this.timeLeft.hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                this.timeLeft.minutes = Math.floor(
                    (distance % (1000 * 60 * 60)) / (1000 * 60)
                );
                this.timeLeft.seconds = Math.floor((distance % (1000 * 60)) / 1000);
            } else {
                clearInterval(this.countdownInterval);
            }
        },
    },
    mounted() {
        this.updateCountdown();
        this.countdownInterval = setInterval(this.updateCountdown, 1000);
    },
    beforeDestroy() {
        clearInterval(this.countdownInterval);
    },
};
</script>

<style src="./css/hitBanner.css" scoped></style>