<template>
    <div style="background-color: #F5F6F9; margin-top: 0px; padding-top: 80px;">
        <div class="container">
            <popup :message="alertErrMessage" v-if="isAlertErr" :isError="isError" @close="isAlertErr = false" />
            <p>CONTACT US</p>
            <h2 style="line-height: 30px;">Wujudkan Kolaborasi Menuju Transformasi Digital Berkelanjutan &
                <span style="color: #162CA2; font-weight: bold; font-family: Poppins;">#CiptakanMasaDepan</span>
                Gemilang Bersama HiColleagues
            </h2>
            <p class="mb-6 mt-6">Tim HiColleagues siap melayani kebutuhan Akselerasi Digital dalam karir/bisnis dan
                menjawab pertanyaan Anda. Hubungi kami melalui:</p>
            <div>
                <div class="btn-call" @click="toAction('mail')">
                    <div style="width: 15%;">
                        <Sms />
                    </div>
                    <div>
                        <p><b>Email</b></p>
                        <p>hello@hicolleagues.com</p>
                    </div>
                </div>
                <div class="btn-call" @click="toAction('wa')">
                    <div style="width: 15%;">
                        <Call />
                    </div>
                    <div>
                        <p><b>Hubungi Kami</b></p>
                        <p>+62 821 2727 4632</p>
                    </div>
                </div>
                <p class="text-center my-8">Atau hubungi kami melalui</p>
                <div class="form-konsultasi">
                    <div>
                        <label class="form-label" for="full_name">Nama Lengkap</label>
                        <v-text-field class="form-input text-form" name="full_name" v-model="form.full_name" outlined
                            placeholder="Masukkan Nama Lengkap"></v-text-field>
                    </div>
                    <div>
                        <label class="form-label" for="email">Email</label>
                        <v-text-field class="form-input text-form" name="email" v-model="form.email" outlined
                            placeholder="Misal: halo@hicolleagues.com"></v-text-field>
                    </div>
                    <div>
                        <label class="form-label" for="phone_number">No. Whatsapp</label>
                        <v-text-field class="form-input text-form" name="phone_number" outlined
                            placeholder="Misal: 813-xxxx-xxxx" type="number" v-model="form.number_phone"
                            hide-spin-buttons>
                            <template v-slot:prepend-inner>
                                <v-row class="d-flex align-center mt-0 ml-7">
                                    <img src="/img/other/flag-indonesia.png" alt="" width="30" height="20"
                                        style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)" />
                                </v-row>
                            </template>
                        </v-text-field>
                    </div>
                    <div>
                        <label class="form-label" for="instansi">Instansi/Perusahaan</label>
                        <v-text-field class="form-input text-form" name="instansi" v-model="form.company" outlined
                            placeholder="Masukkan Instansi/Perusahaan"></v-text-field>
                    </div>
                    <div>
                        <label class="form-label" for="instansi">Layanan</label>
                        <v-select class="form-input text-form" outlined v-model="form.service" :items="serviceTabs"
                            :item-text="'title'" :item-value="'value'" placeholder="Pilih Jenis Layanan" />
                    </div>
                    <div>
                        <label class="form-label" for="instansi">Kategori Produk</label>
                        <v-select class="form-input text-form" outlined v-model="form.productType"
                            :items="productTypeTabs" :item-text="'title'" :item-value="'value'"
                            placeholder="Pilih Kategori Produk" />
                    </div>
                    <div>
                        <label class="form-label" for="note">Pesan</label>
                        <v-textarea outlined v-model="form.notes" class="mt-2 rounded-sm"
                            placeholder="Tulis pesan di sini..." rows="3" name="note"></v-textarea>
                    </div>
                    <v-row class="d-flex justify-center align-center mt-3">
                        <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
                            <template v-slot:label>
                                <p class="label-checkbox">
                                    Dengan mengirim formulir ini saya menyetujui <a href="/term-condition">Syarat &
                                        Ketentuan</a> untuk melakukan layanan konsultasi kolaborasi/kemitraan
                                    bersama HiColleagues.

                                </p>
                            </template>
                        </v-checkbox>
                    </v-row>
                    <v-row class="d-flex justify-center align-center mt-6">
                        <v-btn elevation="0" :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`"
                            @click="onSubmit">KIRIM </v-btn>
                    </v-row>
                    <br />
                </div>
            </div>
            <div style="padding-bottom: 30px;"></div>
        </div>
    </div>
</template>


<script>
import Call from "@/assets/icons/Call.vue"
import Sms from "@/assets/icons/Sms.vue"
import Popup from "@/components/others/Popup.vue";

export default {
    name: "MobileContactUs",
    components: {
        Call,
        Sms,
        Popup
    },
    data() {
        return {
            form: {
                full_name: "",
                email: "",
                number_phone: "",
                product_type: "",
                service: "",
                company: "",
                notes: ""
            },
            is_confirm: false,
            isError: false,
            alertErrMessage: "",
            isAlertErr: false,
            productTypeTabs: [
                {
                    title: "Umum (seperti: Public Training, Bootcamp, Event)",
                    value: "Umum (seperti: Public Training, Bootcamp, Event)",
                },
                {
                    title: "Corporate Service",
                    value: "Corporate Service",
                },
                {
                    title: "Kartu Prakerja",
                    value: "Kartu Prakerja",
                },
                {
                    title: "Kampus Merdeka",
                    value: "Kampus Merdeka",
                },
                {
                    title: "Lainnya",
                    value: "Lainnya",
                },

            ],
            serviceTabs: [
                {
                    title: "Konsultasi",
                    value: "Konsultasi",
                },
                {
                    title: "Pengaduan",
                    value: "Pengaduan",
                },
            ],
        }
    },
    methods: {
        toAction(ctx) {
            if (ctx === "mail") {
                window.open("https://mail.google.com/mail/?view=cm&fs=1&to=hello@hicolleagues.com&su=Customer%20Service&body=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya")
            } else {
                window.open("https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya");
            }
        },
        async onSubmit() {
            if (!this.is_confirm) {
                return
            }
            if (!this.form.full_name || !this.form.email || !this.form.number_phone || !this.form.company) {
                this.alertErrMessage = "Harap isi semua form yang tersedia"
                this.isAlertErr = true
                this.isError = true
                return
            }
            try {
                await this.$http.post('/v1/customer-service', this.form)
                this.alertErrMessage = "Success"
                this.isAlertErr = true
                this.isError = false
                this.clearForm()
            } catch {
                this.alertErrMessage = "Error saat submit data"
                this.isAlertErr = true
                this.isError = true
            }
        },
        clearForm() {
            this.is_confirm = false
            this.form.full_name = ""
            this.form.email = ""
            this.form.number_phone = ""
            this.form.company = ""
            this.form.notes = ""
            this.form.product_type = ""
            this.form.service = ""
        },
    },

}
</script>

<style src="./contactUs.css" scoped></style>