<template>
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.075 13.3125H4.925C3.185 13.3125 2.1875 12.315 2.1875 10.575V1.5C2.1875 1.1925 2.4425 0.9375 2.75 0.9375H16.25C16.5575 0.9375 16.8125 1.1925 16.8125 1.5V10.575C16.8125 12.315 15.815 13.3125 14.075 13.3125ZM3.3125 2.0625V10.575C3.3125 11.6925 3.8075 12.1875 4.925 12.1875H14.0675C15.185 12.1875 15.68 11.6925 15.68 10.575V2.0625H3.3125Z" fill="#7B7E8C"/>
    <path d="M17 2.0625H2C1.6925 2.0625 1.4375 1.8075 1.4375 1.5C1.4375 1.1925 1.6925 0.9375 2 0.9375H17C17.3075 0.9375 17.5625 1.1925 17.5625 1.5C17.5625 1.8075 17.3075 2.0625 17 2.0625Z" fill="#7B7E8C"/>
    <path d="M6.5002 17.0625C6.2902 17.0625 6.09519 16.95 5.99769 16.755C5.85519 16.4775 5.96771 16.14 6.25271 15.9975L8.9377 14.655V12.75C8.9377 12.4425 9.1927 12.1875 9.5002 12.1875C9.8077 12.1875 10.0627 12.4425 10.0627 12.75V15C10.0627 15.21 9.94269 15.405 9.74769 15.5025L6.74769 17.0025C6.67269 17.04 6.5827 17.0625 6.5002 17.0625Z" fill="#7B7E8C"/>
    <path d="M12.5 17.0625C12.4175 17.0625 12.3275 17.04 12.2525 17.0025L9.25247 15.5025C8.97497 15.36 8.86245 15.0225 8.99745 14.745C9.13995 14.4675 9.47746 14.355 9.75496 14.49L12.755 15.99C13.0325 16.1325 13.145 16.47 13.01 16.7475C12.905 16.95 12.7025 17.0625 12.5 17.0625Z" fill="#7B7E8C"/>
    <path d="M6.12494 8.81226C5.96744 8.81226 5.80245 8.74477 5.68995 8.60977C5.48745 8.36977 5.52494 8.01727 5.76494 7.81477L8.12745 5.84227C8.34495 5.66227 8.62244 5.58727 8.88494 5.63227C9.15494 5.67727 9.38745 5.84225 9.52995 6.08225L10.3174 7.39475L12.5149 5.56477C12.7549 5.36977 13.1074 5.39975 13.3099 5.63975C13.5124 5.87975 13.475 6.23225 13.235 6.43475L10.8724 8.40726C10.6549 8.58726 10.3774 8.66226 10.115 8.61726C9.84495 8.57226 9.61244 8.40727 9.46994 8.16727L8.68245 6.85477L6.48495 8.68475C6.37995 8.76725 6.25244 8.81226 6.12494 8.81226Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Presentation",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
};
</script>