<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.1329 9.68329C10.1079 9.68329 10.0912 9.68329 10.0662 9.68329C10.0246 9.67496 9.96623 9.67496 9.91623 9.68329C7.49956 9.60829 5.67456 7.70829 5.67456 5.36663C5.67456 2.98329 7.61623 1.04163 9.99956 1.04163C12.3829 1.04163 14.3246 2.98329 14.3246 5.36663C14.3162 7.70829 12.4829 9.60829 10.1579 9.68329C10.1496 9.68329 10.1412 9.68329 10.1329 9.68329ZM9.99956 2.29163C8.30789 2.29163 6.92456 3.67496 6.92456 5.36663C6.92456 7.03329 8.22456 8.37496 9.88289 8.43329C9.92456 8.42496 10.0412 8.42496 10.1496 8.43329C11.7829 8.35829 13.0662 7.01663 13.0746 5.36663C13.0746 3.67496 11.6912 2.29163 9.99956 2.29163Z"
			fill="#36373F" />
		<path
			d="M10.1413 18.7917C8.50801 18.7917 6.86634 18.375 5.62467 17.5417C4.46634 16.775 3.83301 15.725 3.83301 14.5834C3.83301 13.4417 4.46634 12.3834 5.62467 11.6084C8.12467 9.95004 12.1747 9.95004 14.658 11.6084C15.808 12.375 16.4497 13.425 16.4497 14.5667C16.4497 15.7084 15.8163 16.7667 14.658 17.5417C13.408 18.375 11.7747 18.7917 10.1413 18.7917ZM6.31634 12.6584C5.51634 13.1917 5.08301 13.875 5.08301 14.5917C5.08301 15.3 5.52467 15.9834 6.31634 16.5084C8.39134 17.9 11.8913 17.9 13.9663 16.5084C14.7663 15.975 15.1997 15.2917 15.1997 14.575C15.1997 13.8667 14.758 13.1834 13.9663 12.6584C11.8913 11.275 8.39134 11.275 6.31634 12.6584Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'FrameIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
