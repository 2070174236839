<template>
  <div class="checkout-page">
    <PopupError :message="alertErrMessage" v-if="isAlertErr" @close="isAlertErr = false" />
    <v-card fixed class="text-left header-mobile-custom pl-2 pt-3" style="top: 0" v-if="mobileView">
      <v-icon @click="backToPreviousPage">mdi-arrow-left</v-icon>
      <span class="ml-2 font-weight-bold">Pembelian</span>
    </v-card>
    <h1 class="checkout-title">Form Pendaftaran</h1>
    <div class="content">
      <div class="card-item card-item-padding" @click="isDetail = !isDetail">
        <div class="d-flex justify-content-between">
          <p class="c-detail">Detail Kelas</p>
          <arrow :down="!isDetail" />
        </div>
        <div class="d-flex gap-2" v-if="isDetail">
          <div>
            <img :src="imageURL" style="margin-right: 5px; border-radius: 15px" width="95" height="95" />
          </div>
          <div>
            <h3 class="class-name">{{ className }}</h3>
            <p class="harga" v-if="!isPrivateClass">Harga</p>
            <span v-if="!isPrivateClass" :class="`price ${isVoucherApplied ? 'disouned' : ''
              }`">{{ price | toCurrency }}</span>
            <div v-if="isPrivateClass">
              <p class="harga">Harga</p>
              <span :class="`price ${isVoucherApplied ? 'disouned' : ''
                }`">{{ privateClassPrice | toCurrency }}</span>
            </div>
            <span class="price" style="margin-left: 10px" v-if="isVoucherApplied">{{ totalPrice | toCurrency }}</span>
          </div>
        </div>
        <div class="class-info" v-if="isDetail && mobileView">
          <div class="class-info-content">
            <div class="mob">
              <Grow fill="#575966" />
              <span class="name">Level</span>
            </div>
            <div class="class-info-detail">
              <span>{{ level }}</span>
            </div>
          </div>
          <div class="class-info-content">
            <div class="mob">
              <DateIcon fill="#575966" />
              <span class="name">Sesi Pelatihan</span>
            </div>
            <div class="class-info-detail">
              <span>{{ session }} sesi</span>
            </div>
          </div>
          <div class="class-info-content">
            <div class="mob">
              <Methode fill="#575966" />
              <span class="name">Methode</span>
            </div>
            <div class="class-info-detail">
              <span>{{ methode | toMethode }}</span>
            </div>
          </div>
        </div>
        <div class="class-info" v-if="isDetail && !mobileView">
          <div class="class-info-content">
            <div>
              <Grow fill="#575966" />
            </div>
            <div class="class-info-detail">
              <span class="name">Level</span>
              <span>{{ level }}</span>
            </div>
          </div>
          <div class="class-info-content" v-if="!isPrakerja">
            <div>
              <DateIcon fill="#575966" />
            </div>
            <div class="class-info-detail">
              <span class="name">Sesi Pelatihan</span>
              <span>{{ session }} sesi</span>
              <span>{{ days }}</span>
              <span>{{ hour }}</span>
            </div>
          </div>
          <div class="class-info-content">
            <div>
              <Methode fill="#575966" />
            </div>
            <div class="class-info-detail">
              <span class="name">Method</span>
              <span>{{ methode | toMethode }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-item-padding">
          <div class="input-box" v-if="!isPrakerja">
            <span>Jadwal</span>
            <v-autocomplete ref="schedule" v-model="schedule" :items="schedules" item-text="date" item-value="date"
              placeholder="Select..." required outlined dense class="mt-3 rounded-sm" :error-messages="scheduleErrors"
              @input="$v.schedule.$touch()" @blur="$v.schedule.$touch()"></v-autocomplete>
          </div>
          <div class="input-box">
            <span>Metode Pembelajaran</span>
            <v-autocomplete ref="learningMethod" v-model="learningMethod" :items="learningMethods" item-text="name"
              item-value="value" placeholder="Select..." required outlined dense :disabled="learningDisabled"
              class="mt-3 rounded-sm" :error-messages="learningMethodErrors" @input="$v.learningMethod.$touch()"
              @blur="$v.learningMethod.$touch()"></v-autocomplete>
          </div>
          <div class="input-box" v-if="isPrakerja">
            <span>Tipe Kelas</span>
            <v-autocomplete ref="classType" v-model="classType" :items="classTypePrakerja" item-text="name"
              item-value="value" placeholder="Select..." required outlined dense class="mt-2 rounded-sm"
              disabled></v-autocomplete>
          </div>
          <div class="input-box" v-if="isBootcamp">
            <span>Tipe Kelas</span>
            <v-autocomplete ref="classType" v-model="classType" :items="classTypeBootcamp" item-text="name"
              item-value="value" placeholder="Select..." required outlined dense class="mt-2 rounded-sm"
              disabled></v-autocomplete>
          </div>
          <div class="input-box" v-if="!isPrakerja && !isBootcamp">
            <span>Tipe Kelas</span>
            <v-autocomplete ref="classType" v-model="classType" :items="classTypes" placeholder="Select..." required
              outlined dense class="mt-2 rounded-sm" v-on:change="changeClassType(`${classType}`)"
              :error-messages="classTypeErrors" @input="$v.classType.$touch()"
              @blur="$v.classType.$touch()"></v-autocomplete>
          </div>
        </div>
        <div class="spacing-ctn"></div>
        <div class="card-item-padding">
          <div class="input-box">
            <span>Titel</span>
            <v-radio-group row ref="gender" v-model="gender" :error-messages="genderErrors" @input="$v.gender.$touch()"
              @blur="$v.gender.$touch()" required>
              <v-radio v-for="(item, idx) in genders" :key="idx" :label="item" :value="item"></v-radio>
            </v-radio-group>
          </div>
          <div class="input-box">
            <span>Nama Lengkap</span>
            <v-text-field v-model="fullName" outlined dense required class="mt-2 rounded-sm"
              :error-messages="fullNameErrors" @input="$v.fullName.$touch()"
              @blur="$v.fullName.$touch()"></v-text-field>
          </div>
          <div class="input-box">
            <span>Nomor Telepon</span>
            <v-row>
              <v-col cols="3">
                <v-text-field label="Kode Negara" outlined dense class="mt-2 rounded-sm" value="+62"
                  disabled></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="phoneNumber" outlined dense required type="number" class="mt-2 rounded-sm"
                  :error-messages="phoneNumberErrors" @input="$v.phoneNumber.$touch()"
                  @blur="$v.phoneNumber.$touch()"></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="input-box">
            <span>Email</span>
            <v-text-field v-model="email" @keydown.space.prevent outlined dense required class="mt-2 rounded-sm"
              :error-messages="emailErrors" @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>
          </div>
          <div class="input-box">
            <span>Tanggal Lahir</span>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="birthdate" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mb-2" v-model="birthdate" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on" :error-messages="birthdateErrors" @input="$v.birthdate.$touch()"
                  @blur="$v.birthdate.$touch()"></v-text-field>
              </template>
              <v-date-picker v-model="birthdate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(birthdate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <div class="input-box">
            <span>Motivasi Mengikuti Pelatihan ini</span>
            <v-textarea outlined v-model="notes" class="mt-2 rounded-sm" name="input-7-4"></v-textarea>
          </div>
        </div>
        <div class="spacing-ctn"></div>
        <div class="card-item-padding">
          <div class="input-box">
            <span>Masukan kode voucher</span>
            <v-text-field v-model="voucherCode" outlined class="mt-2 rounded-sm" dense required
              clear-icon="mdi-close-circle" clearable @click:clear="voucherCode = ''"></v-text-field>
          </div>
          <div class="font-weight-bold min-t" v-if="isVoucherApplied">
            <span style="color: #03ac0e; font-size: 13px">Yay, kamu mendapatkan potongan sebesar
              {{ voucherPrice | toCurrency }}</span>
          </div>
          <div class="font-weight-bold min-t" v-if="!isVoucherValid">
            <span style="color: #ff0000; font-size: 13px">{{
              messageErrorReedemVoucher
            }}</span>
          </div>
        </div>
        <div class="aggrement">
          Dengan mengisi formulir ini saya menyetujui
          <span class="info" @click="privacyPolicy">Kebijakan Privasi</span>
          dan
          <span class="info" @click="termCondition">Syarat dan Ketentuan</span>
          untuk mengikuti program yang saya daftarkan.
        </div>


        <div class="daftar-btn">
          <v-btn :class="`${validationRequired ? 'btn-terapkan' : 'btn-terapkan-disabled'
            } font-weight-bold pa-5`" width="100%" :loading="isLoading" :disabled="isLoading"
            @click.prevent="submitPayment" v-if="!isPrakerja">
            DAFTAR
          </v-btn>
          <v-btn :class="`${validationRequired ? 'btn-terapkan' : 'btn-terapkan-disabled'
            } font-weight-bold pa-5`" width="100%" :loading="isLoading" :disabled="isLoading && !validationRequired"
            v-if="isPrakerja" @click.prevent="submitPrakerkaVoucher">
            DAFTAR
          </v-btn>
        </div>
        <div class="b-spacing"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import PopupError from "@/components/others/Popup.vue";
import Grow from "@/assets/icons/Grow.vue";
import Methode from "@/assets/icons/Methode.vue";
import DateIcon from "@/assets/icons/Date.vue";

import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    fullName: { required },
    phoneNumber: { required },

    birthdate: { required },
    learningMethod: { required },
    // voucherCode: { required },
    schedule: { required },
    gender: { required },
    classType: { required },
    email: { required, email },
  },
  data() {
    return {
      isLoading: false,
      isDetail: true,
      learningDisabled: false,
      imageURL: "",
      notes: "",
      className: "",
      classID: 0,
      hour: "",
      level: "",
      session: 0,
      totalPrice: 0,
      price: 0,
      discountPrice: 0,
      privateClassPrice: 0,
      privateDiscountPrice: 0,
      isPrivateClass: false,
      isVoucherValid: false,
      messageErrorReedemVoucher: "",
      isVoucherApplied: false,
      voucherPrice: 0,
      voucherCode: "",
      days: "",
      methode: "",
      mobileView: false,
      schedule: "",
      learningMethod: "",
      classType: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      gender: "",
      genders: ["Tuan", "Nyonya", "Nona"],
      birthdate: "",
      isDoubleClick: false,
      modal: false,
      schedules: [],
      learningMethods: [{ name: "Online via Zoom", value: 'Online' }, { name: "Luring", value: 'Offline' }],
      classTypes: ["Kelas Public", "Kelas Private"],
      classTypePrakerja: [{
        name: "Kelas Prakerja",
        value: "PRAKERJA_CLASS"
      }],
      classTypeBootcamp: [{
        name: "Kelas Bootcamp",
        value: "BOOTCAMP_CLASS"
      }],
      voucher: {},
      isPrakerja: false,
      isBootcamp: false,
      categoryID: 0,
      isAlertErr: false,
      alertErrMessage: "",
    };
  },
  watch: {
    isAlertErr() {
      if (!this.isAlertErr) return;
      setTimeout(() => {
        this.isAlertErr = false;
      }, 3000);
    },
    email() {
      this.email = this.email.replace(" ", "");
    },
  },
  components: {
    PopupError,
    Arrow,
    Grow,
    Methode,
    DateIcon
  },
  computed: {
    fullNameErrors() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.required &&
        errors.push("Nama lengkap tidak boleh kosong.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email tidak boleh kosong");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push("Nomor telepon tidak boleh kosong.");
      return errors;
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.birthdate.$dirty) return errors;
      !this.$v.birthdate.required &&
        errors.push("Tanggal lahir tidak boleh kosong.");
      return errors;
    },
    learningMethodErrors() {
      const errors = [];
      if (!this.$v.learningMethod.$dirty) return errors;
      !this.$v.learningMethod.required &&
        errors.push("Metode pembelajaran tidak boleh kosong");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Titel tidak boleh kosong");
      return errors;
    },
    scheduleErrors() {
      const errors = []
      if (!this.$v.schedule.$dirty) return errors
      !this.$v.schedule.required && errors.push('Jadwal tidak boleh kosong')
      return errors
    },
    // voucherCodeErrors () {
    //     const errors = []
    //     if (!this.$v.voucherCode.$dirty) return errors
    //     !this.$v.voucherCode.required && errors.push('Kode voucher tidak boleh kosong.')
    //     return errors
    // },
    classTypeErrors() {
      const errors = [];
      if (!this.$v.classType.$dirty) return errors;
      !this.$v.classType.required &&
        errors.push("Tipe kelas tidak boleh kosong");
      return errors;
    },
    validationRequired() {
      if (this.classType == "PRAKERJA_CLASS") {
        if (
          this.voucherCode != "" &&
          this.learningMethod != "" &&
          this.classType != "" &&
          this.gender != "" &&
          this.phoneNumber != "" &&
          this.email != "" &&
          this.birthdate != "" &&
          this.fullName != ""
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.classType == "BOOTCAMP_CLASS") {
        if (
          this.schedule != "" &&
          this.learningMethod != "" &&
          this.classType != "" &&
          this.gender != "" &&
          this.phoneNumber != "" &&
          this.email != "" &&
          this.birthdate != "" &&
          this.fullName != ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.schedule != "" &&
          this.learningMethod != "" &&
          this.classType != "" &&
          this.gender != "" &&
          this.phoneNumber != "" &&
          this.email != "" &&
          this.birthdate != "" &&
          this.fullName != ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 990;
    },
    backToPreviousPage() {
      history.go(-1);
    },
    changeClassType(value) {
      if (value == "Kelas Private") {
        this.isPrivateClass = true;
        if (this.$route.query.category !== 'PUBLIC_TRAINING') {
          this.privateClassPrice = this.price * 0.5;
        }
      } else {
        this.isPrivateClass = false;
        if (this.$route.query.category !== 'PUBLIC_TRAINING') {
          this.privateClassPrice = 0;
        }
      }
      this.getTotalPrice();
    },
    getTotalPrice() {
      if (this.$route.query.category === 'PUBLIC_TRAINING') {
        if (this.isPrivateClass) {
          this.totalPrice = this.privateClassPrice - this.voucherPrice;
        } else {
          this.totalPrice = this.price - this.voucherPrice;
        }
      } else {
        this.totalPrice = this.price + this.privateClassPrice - this.voucherPrice;
      }
    },

    getLocalDb() {
      var data = JSON.parse(localStorage.getItem("checkout"));
      this.price = data.price;
      this.classID = data.class_id;
      this.className = data.class_name;
      this.hour = data.hour;
      this.level = data.level;
      this.session = data.session;
      this.methode = data.methode;
      this.days = data.days;
      this.imageURL = data.image_url;
      this.classType = data.class_type;
      this.categoryID = data.category_id;
      if (this.$route.query.category === 'PUBLIC_TRAINING') {
        this.price = Number(data.price)
        this.privateClassPrice = Number(data.private_price)
        if (data.is_discount) {
          this.price = Number(data.price) - Number(data.discount_price)
          this.privateClassPrice = Number(data.private_price) - Number(data.private_discount_price)
        }
      }

      if (data.class_type == "PRAKERJA_CLASS") {
        this.isPrakerja = true;
      } else if (data.class_type == "BOOTCAMP_CLASS") {
        this.isBootcamp = true;
      }
      if (this.methode != "Online/Offline" && this.methode) {
        this.learningMethod = this.methode
        this.learningDisabled = true
      }
    },
    async submitPrakerkaVoucher() {
      // this.spin_load.val = true
      this.$v.$touch();
      if (!this.validationRequired) {
        this.isAlertErr = true;
        this.alertErrMessage =
          "Ups, HiColers harus melengkapi seluruh data yang diperlukan!";
        return;
      }
      this.isLoading = true
      const confirmRedeem = await this.applyVoucherCode();
      if (!confirmRedeem) {
        this.isLoading = false
        return;
      }
      await this.$http
        .post("/v1/transaction-prakerja", {
          class_id: this.classID,
          schedule: this.voucher.schedule_start_end,
          learning_method: this.learningMethod,
          class_type: this.classType,
          full_name: this.fullName,
          phone_number: this.phoneNumber,
          email: this.email,
          gender: this.gender,
          birthday: this.birthdate,
          motivation_note: this.notes,
          voucher_code: this.voucherCode,
          discount: this.voucherPrice,
          price: this.price,
          total_price: this.totalPrice,
          status: "PAID",
          dp_platform: this.voucher.dp_platform,
          course_code: this.voucher.course_code,
          invoice_number: this.voucher.invoice_code,
          schedule_from_prakerja: this.voucher.schedule_start_end,
        })
        .then(() => {
          this.$router.push("/success")
          this.isLoading = false
        })
        .catch((err) => {
          console.error(err.response)
          this.isLoading = false
        });
    },
    async submitPayment() {
      this.$v.$touch();
      this.isLoading = true
      if (this.phoneNumber.length < 10) {
        this.isLoading = false
        this.isAlertErr = true
        this.alertErrMessage = "Nomor telepon tidak valid"
        return;
      }
      if (this.voucherCode) {
        const confirmVoucher = await this.applyVoucherCode();
        if (!confirmVoucher) {
          this.isLoading = false
          return;
        }
      }
      if (!this.$v.$error) {
        if (this.isDoubleClick) {
          setTimeout(() => {
            this.isDoubleClick = false
          }, 3000)
          return
        }
        const [startInput, endInput] = this.schedule.split(" - ");
        const startISO = moment.utc(startInput, "DD MMM YYYY").toISOString().replace(".000", "");
        const endISO = moment.utc(endInput, "DD MMM YYYY").toISOString().replace(".000", "");

        const result = this.schedules.find(item => item.start_date === startISO && item.end_date === endISO) || { batch_number: 0 };

        this.isDoubleClick = true
        await this.$http
          .post("/v1/transaction", {
            class_id: this.classID,
            schedule: this.schedule,
            learning_method: this.learningMethod,
            class_type: this.classType,
            batch: result.batch_number,
            full_name: this.fullName,
            phone_number: this.phoneNumber,
            email: this.email,
            gender: this.gender,
            birthday: this.birthdate,
            motivation_note: this.notes,
            voucher_code: this.voucherCode,
            discount: this.voucherPrice,
            price: this.price,
            total_price: this.totalPrice,
            status: "CREATED",
          })
          .then((response) => {
            this.isLoading = false
            window.open(response.data.data.transaction.url, '_self')
          })
          .catch((err) => {
            console.error(err.response)
            this.isLoading = false
          });
      }
    },
    async applyVoucherCode() {
      this.messageErrorReedemVoucher = "";
      try {
        const response = await this.$http.get(
          "/v1/voucher/" + this.voucherCode
        );
        this.voucher = response.data.data.voucher;
        this.voucherPrice = this.voucher.discount;
        this.isVoucherApplied = true;
        this.isVoucherValid = true;
        this.getTotalPrice();
        return true;
      } catch {
        this.isVoucherValid = false;
        this.isVoucherApplied = false;
        this.messageErrorReedemVoucher = "Voucher tidak valid";
        return false;
      }
    },

    async getSchedule() {
      await this.$http
        .get(`/v1/schedule/class/${this.classID}`)
        .then((response) => {
          response.data.data.schedules.forEach((element) => {
            this.schedules.push({
              ...element,
              date:
                this.format_date(element.start_date) +
                " - " +
                this.format_date(element.end_date),

            });
          });
        })
        .catch((err) => console.error(err.response));
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).utc().format("DD MMM YYYY");
      }
    },
    privacyPolicy() {
      this.$router.push("/privacy-policy");
    },
    termCondition() {
      this.$router.push("/term-condition");
    },

  },
  mounted() {
    this.getLocalDb();
    this.handleView();
    this.getTotalPrice();
    if (!this.isPrakerja) {
      this.getSchedule();
    }
    this.$utils.scrollTop()
    this.$gtag.pageview(this.$route);
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.checkout-title {
  text-align: center;
  margin-top: 100px;
  font-size: 30px;
  font-weight: bolder;
}

.checkout-page .content {
  width: 50%;
  margin: 50px auto;
}

.c-detail {
  font-weight: bolder;
  font-size: 17px;

}

.class-name {
  font-weight: bolder !important;
  line-height: 28px;
  font-size: 24px;
  margin-bottom: 8px;
}

.input-box span {
  display: block;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: -3px;
  font-family: 'Poppins', sans-serif !important;
}

.spacing-ctn {
  margin-top: -20px;
  background-color: #f5f6fa;
  height: 10px;
}

.btn-terapkan {
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #000080 !important;
}

.btn-terapkan-disabled {
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #acafbf !important;
}

.checkout-page {
  font-family: "Poppins" !important;
}

.title-info {
  font-size: 23px;
  font-weight: 900 !important;
}

.card-item {
  margin-top: 20px;
  border-radius: 26px !important;
  box-shadow: 1px 4px 5px 2px rgba(13, 28, 113, 0.1);
}

.card-item-padding {
  padding: 20px 40px;
}

.card-item .harga {
  margin: 0;
  margin-top: 10px;
  color: #7b7e8c;
}

.card-item .price {
  font-weight: bolder;
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px;
}

.class-info {
  margin-top: 18px;
  display: flex;
  gap: 70px;
}

.class-info-content {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.class-info-content span {
  font-family: 'Poppins', sans-serif !important;

}

.class-info-content .mob {
  display: flex;
  align-items: center;
}

.class-info-detail {
  display: flex;
  flex-direction: column;
}

.class-info-detail .name {
  color: #575966;
  font-size: 16px;
}

.container-custom {
  max-width: 1150px;
}

.checkout-box {
  display: flex;
  gap: 30px;
}

.checkout-box-left {
  width: 65%;
  position: relative;
}

.rounded-lg {
  border-radius: 30px !important;
}

.aggrement {
  font-size: 15px;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
}

.aggrement .info {
  color: #000080;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;
  background-color: white !important;
}

.min-t {
  margin-top: -20px;
}

.daftar-btn {
  width: 40%;
  margin: 30px auto;
}

.b-spacing {
  height: 2px;
}

.rounded-sm {
  border-radius: 11px !important;
}

.checkout-box-right {
  width: 35%;
}

.gray--text {
  color: gray;
}

.disouned {
  text-decoration: line-through;
  color: rgb(252, 31, 31);
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .checkout-page .content {
    width: 90%;
  }

  .checkout-box {
    display: block;
  }

  .checkout-box-left {
    width: 100%;
  }

  .checkout-box-right {
    width: 100%;
  }

  .col-width {
    max-width: 100%;
    margin-left: 0px;
  }

  .row-block {
    display: block;
  }

  .alert-custom {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-item-padding {
    padding: 20px;
  }

  .daftar-btn {
    width: 80%;
  }

  .aggrement {
    width: 95%;
  }

  .checkout-title {
    display: none !important;
    margin-top: 35px;
  }

  .checkout-page .content {
    margin: 20px auto;
  }

  .class-info-content {
    margin-top: 8px;
    justify-content: space-between;
  }

  .class-info {
    display: block;
  }

  .class-info-detail {
    text-align: right;
  }
}
</style>
