<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.7583 12.5167C12.2749 12.5167 11.7499 12.3584 11.2249 12.0334L10.1249 11.3667C10.0749 11.3334 9.92492 11.3334 9.87492 11.3667L8.77492 12.0334C7.87492 12.5834 6.99159 12.6667 6.28325 12.2667C5.59159 11.875 5.20825 11.0917 5.20825 10.0667V4.19169C5.20825 2.15835 6.31659 1.04169 8.33325 1.04169H11.6666C13.6833 1.04169 14.7916 2.15835 14.7916 4.19169V10.0667C14.7916 11.1 14.4083 11.8834 13.7166 12.2667C13.4249 12.4334 13.0999 12.5167 12.7583 12.5167ZM9.99992 10.0917C10.2749 10.0917 10.5499 10.1584 10.7749 10.2917L11.8749 10.9584C12.3666 11.2584 12.8166 11.3334 13.0999 11.175C13.3749 11.0167 13.5333 10.6084 13.5333 10.0584V4.19169C13.5333 2.85835 12.9749 2.29169 11.6583 2.29169H8.32492C7.00825 2.29169 6.44992 2.85835 6.44992 4.19169V10.0667C6.44992 10.6167 6.60825 11.025 6.88325 11.1834C7.16659 11.3417 7.61659 11.2667 8.10825 10.9667L9.20825 10.3C9.44992 10.1584 9.72492 10.0917 9.99992 10.0917Z"
			fill="#36373F" />
		<path
			d="M12.5001 18.9584H7.50008C2.97508 18.9584 1.04175 17.0084 1.04175 12.4417V9.91669C1.04175 6.03336 2.44175 4.06669 5.57508 3.54169C5.90841 3.48336 6.23342 3.71669 6.29175 4.05836C6.35008 4.40003 6.11675 4.71669 5.77508 4.77503C3.26675 5.19169 2.28341 6.63336 2.28341 9.91669V12.4417C2.28341 16.2834 3.69175 17.7084 7.49175 17.7084H12.4917C16.2917 17.7084 17.7001 16.2834 17.7001 12.4417V9.91669C17.7001 6.58336 16.6834 5.14169 14.0667 4.75003C13.7251 4.70003 13.4917 4.38336 13.5417 4.04169C13.5917 3.70003 13.9084 3.46669 14.2501 3.51669C17.5001 4.00003 18.9501 5.96669 18.9501 9.91669V12.4417C18.9584 17.0084 17.0251 18.9584 12.5001 18.9584Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'BookmarkIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
