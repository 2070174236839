<template>
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 3.04883H2C1.59 3.04883 1.25 2.70883 1.25 2.29883C1.25 1.88883 1.59 1.54883 2 1.54883H22C22.41 1.54883 22.75 1.88883 22.75 2.29883C22.75 2.70883 22.41 3.04883 22 3.04883Z" fill="#7B7E8C"/>
    <path d="M16.67 22.6388C16.54 22.8988 16.27 23.0488 16 23.0488C15.89 23.0488 15.77 23.0188 15.67 22.9688L12 21.1388L8.33001 22.9688C8.23001 23.0188 8.11001 23.0488 8.00001 23.0488C7.73001 23.0488 7.46001 22.8988 7.33001 22.6388C7.14001 22.2588 7.29001 21.8088 7.67001 21.6288L11.25 19.8388V17.2988H12.75V19.8388L16.33 21.6288C16.71 21.8088 16.86 22.2588 16.67 22.6388Z" fill="#7B7E8C"/>
    <path d="M3 2.29883V14.3988C3 16.2988 4 17.2988 5.9 17.2988H18.1C20 17.2988 21 16.2988 21 14.3988V2.29883H3ZM16.98 8.87883L13.83 11.5088C13.54 11.7488 13.17 11.8488 12.82 11.7888C12.46 11.7288 12.15 11.5088 11.96 11.1888L10.91 9.43883L7.98 11.8788C7.84 11.9988 7.67 12.0488 7.5 12.0488C7.29 12.0488 7.07 11.9588 6.92 11.7788C6.65 11.4588 6.7 10.9888 7.02 10.7188L10.17 8.08883C10.46 7.84883 10.83 7.74883 11.18 7.80883C11.54 7.86883 11.85 8.08883 12.04 8.40883L13.09 10.1588L16.02 7.71883C16.34 7.44883 16.81 7.49883 17.08 7.81883C17.34 8.13883 17.3 8.60883 16.98 8.87883Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Presentation2",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
};
</script>