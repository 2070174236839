<template>
  <div style="background-color: #F5F6F9;">
    <div class="container container-size">
      <div class="navigation">
        <span class="nav-active" @click="$router.push('/event')">Event</span>
        <Arrow :width="16" direct="left" />
        <span>Detail Event</span>
      </div>
      <div class="event-content">
        <div class="event-content-l">
          <img
            :src="event.img_urls.desktop.product_detail"
            alt="Banner"
            class="event-img"
          />
          <div class="event-info">
            <div class="event-ctn-label">
              <span>{{ event.category.name }}</span>
            </div>
            <h1 class="line-height-normal" style="font-size: 28px;">{{ event.name }} {{ event.batch ? "Batch " + event.batch : '' }}</h1>
            <p class="description" v-html="event.description"></p>
            <h2 class="mekanisme-text">Mekanisme Kelas</h2>
            <div class="timeline-bootcamp">
              <div class="info-schadule">
                <div class="info-schadule-ctn">
                  <GrowIc :width="25" :height="25" />
                  <span>{{ event.level }}</span>
                </div>
                <div class="info-schadule-ctn">
                  <PeopleIc :width="25" :height="25" />
                  <span>{{
                    event.participant_quota
                      ? `${totalRegistrant(total_registrants) >= 4 ? total_registrants + " /" : ""} ${event.participant_quota}  Peserta` 
                      : "Tidak terbatas"
                  }}</span>
                </div>
                <div class="info-schadule-ctn">
                  <DateIc :width="25" :height="25" />
                  <span>{{ event.total_session }} Sesi</span>
                </div>
                <div class="info-schadule-ctn">
                  <MethodeIc :width="25" :height="25" />
                  <span>{{ event.method | toMethode }}</span>
                </div>
              </div>
              <hr color="#D3D6E1" style="margin: 16px 0;" />
              <div class="info-schadule" style="display: block">
                <div class="info-schadule-ctn">
                  <DateIc :width="25" :height="25" />
                  <span>Jadwal Tayang</span>
                </div>
                <div class="shadule">
                  <div v-for="(item, idx) in event.session_schedules" :key="idx">
                    <div class="schedule-time">
                      <VerifyIc :width="25" :height="25" />
                      <span
                      >{{ moment(item.date_start) }}
                      {{
                        item.date_end ? "- " + moment(item.date_end) : ""
                        }}</span
                      >
                      <EclipseIc :width="8" :height="8" />
                      <span
                        >{{ event.session_schedules[0].start_time }} -
                        {{ event.session_schedules[0].end_time }} WIB</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="info-schadule mt-10"
                style="display: block"
                v-if="event.method === 'Offline'"
              >
                <div class="info-schadule-ctn mb-2">
                  <MapIc :width="25" fill="#7B7E8C" />
                  <span style="margin-top: -3px">Lokasi Pelaksanaan</span>
                </div>
                <div class="ml-8">
                  <h3>{{ event.location_name }}</h3>
                  <p>{{ event.location_address }}</p>
                  <p v-if="event.location_map">
                    <a :href="event.location_map">{{ event.location_map }}</a>
                  </p>
                </div>
              </div>
            </div>
            <div style="margin-top: 64px;" v-if="event.syllabuses.length > 0">
              <h2 class="mekanisme-text">Materi Silabus</h2>
              <div class="materi-silabus-ctn">
                <div
                  class="materi-silabus"
                  v-for="(item, idx) in event.syllabuses"
                  :key="idx"
                >
                <div class="schedule-sub-title-ctn">
                  <span class="sub-title">Sesi {{ idx + 1 }}</span>
                  <div class="schedule-time-silabus">
                    <span
                    >{{ moment(event.session_schedules[idx].date_start) }}
                    {{
                      event.session_schedules[idx].date_end ? "- " + moment(event.session_schedules[idx].date_end) : ""
                      }}</span
                    >
                    <EclipseIc :width="8" :height="8" />
                    <span
                      >{{ event.session_schedules[idx].start_time }} -
                      {{ event.session_schedules[idx].end_time }} WIB</span
                    >
                  </div>
                </div>
                <hr color="#D3D6E1" style="margin: 12px 0;"/>
                <ul>
                  <li class="silabus-text" v-for="(item2, idx2) in item" :key="idx2">{{ item2 }}</li>
                </ul>
                </div>
              </div>
            </div>

            <div style="margin-top: 64px;">
              <h2 class="mekanisme-text" v-if="event.instructors.length">
                Narasumber
              </h2>
              <div class="instructor-list-ctn">
                <div
                  v-for="item2 in event.instructors"
                  :key="item2.id"
                  class="narasumber"
                >
                  <div>
                    <img
                      :src="$utils.loadImage(item2.image_url)"
                      alt="Profile"
                      class="narasumber-img"
                    />
                  </div>
                  <div class="instructor-detail">
                    <div class="instructor-name">
                      <h2>{{ item2.name }}</h2>
                      <span class="sub-title-narasumber"
                        >{{ item2.position }}
                        {{ item2.company ? "at " + item2.company : "" }}</span
                      >
                    </div>
                    <p class="description-2">{{ item2.description }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 64px;">
              <h2 class="mekanisme-text">Benefit</h2>
              <div class="benefit-list-ctn">
                <div
                  v-for="(item, idx) in event.benefits"
                  :key="idx"
                  class="benefit-list"
                >
                  <VerifyIc :width="24" :height="24" />
                  <span>{{ item }}</span>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="event-content-r">
          <div class="event-price-detail">
            <div class="event-ctn-label">
              <span>{{ event.category.name }}</span>
            </div>
            <h2 class="line-height-normal">{{ event.name }}</h2>
            <div class="event-outer-ctn-info">
              <div class="event-ctn-info" style="margin-top: 24px;">
                <DateIcon :width="18" :height="18" />
                <span class="name">{{ moment(event.session_schedules[0].date_start) }}</span>
              </div>
              <div class="event-ctn-info">
                <Clock :width="18" :height="18" />
                <span class="name"
                  >{{ event.session_schedules[0].start_time }} - {{ event.session_schedules[0].end_time }} WIB</span
                >
              </div>
              <div class="event-ctn-info">
                <Participants :width="18" :height="18" />
                <span class="name"
                  >{{ event.participant_quota > 0 ? `${event.participant_quota} peserta` : 'Tidak terbatas' }}</span
                >
              </div>
              <div class="event-ctn-info">
                <Methode :width="18" :height="18" />
                <span class="name">{{ event.method | toMethode }}</span>
              </div>
              <div class="event-ctn-info" v-if="event.instructors.length">
                <Speaker :width="18" :height="18" fill="#7B7E8C" />
                <span class="name"
                  >{{ event.instructors[0].name
                  }}<span v-if="event.instructors.length > 1"
                    >, {{ event.instructors.length - 1 }}+ lainnya</span
                  ></span>
              </div>
            </div>
            <div style="height: 50px; margin-top: 24px;">
              <div v-if="checkIsEarlyBird(event)">
                <span class="ebp-text">Early Bird Promo</span>
                <div class="after-price-ctn">
                  <span class="discount-price">{{ event.price != 0 ? toCurrency(event.price) : '' }}</span>
                  <span class="event-price">{{ (event.price - event.discount_price) ? toCurrency(event.price - event.discount_price) : "GRATIS" }}</span>
                </div>
              </div>
              <div class="event-price" v-else>
                {{ event.price ? toCurrency(event.price) : "GRATIS" }}
              </div>
              </div>
              <div v-if="event.expired.length"
                style="display: flex; justify-content: center; margin-top: 24px;"
              >
                <Button
                  name="DAFTAR"
                  :bold="true"
                  width="312px"
                  @click="() => toCheckout(event)"
                />
              </div>
              <div v-else
                style="display: flex; justify-content: center; margin-top: 24px;"
              >
                <button class="btn-disable"
                  disabled
                  @click="() => toCheckout(event)">
                  <CloseSlash :width="20" :height="20" />
                  <span>Telah Usai</span>
                </button>
              </div>
              <div class="event-due-date" v-if="event.expired.length">
                <div class="event-due-date-ctn">
                  <span class="event-due-date-key">{{ event.expired[0] }}</span>
                  <span class="event-due-date-value">Hari</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span class="event-due-date-key">{{ event.expired[1] }}</span>
                  <span class="event-due-date-value">Jam</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span class="event-due-date-key">{{ event.expired[2] }}</span>
                  <span class="event-due-date-value">Menit</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span class="event-due-date-key">{{ event.expired[3] }}</span>
                  <span class="event-due-date-value">Detik</span>
                </div>
              </div>
              <div v-else style="height: 48px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/others/Button.vue"
import moment from "moment";

import Arrow from "@/assets/icons/Arrow.vue";
import Clock from "@/assets/icons/Clock2.vue";
import DateIcon from "@/assets/icons/Calendar2.vue";
import Speaker from "@/assets/icons/UsersOctagon.vue";
import Methode from "@/assets/icons/Presentation.vue";
import MethodeIc from "@/assets/icons/Presentation2.vue";
import DateIc from "@/assets/icons/Calendar3.vue";
import GrowIc from "@/assets/icons/Level.vue";
import Participants from "@/assets/icons/Participants.vue"
import PeopleIc from "@/assets/icons/Participants2.vue";
import MapIc from "@/assets/icons/Map.vue";
import EclipseIc from "@/assets/icons/Eclipse.vue"
import VerifyIc from "@/assets/icons/Verify.vue";
import CloseSlash from "@/assets/icons/CloseSlash.vue";

export default {
  name: "EventDetailDesktop",
  components: {
    Button,
    Arrow,
    DateIcon,
    Clock,
    Methode,
    Speaker,
    MethodeIc,
    DateIc,
    GrowIc,
    Participants,
    PeopleIc,
    MapIc,
    EclipseIc,
    VerifyIc,
    CloseSlash,
  },
  data() {
    return {
      event: {
        name: "",
        learning_url: "",
        caption: "",
        img_urls: {
          desktop: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
          mobile: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
        },
        description: "",
        method: "",
        total_session: 0,
        day_session: "",
        benefits: [],
        session_schedules: [],
        instructors: [],
        registration_close_date: "",
        price: 0,
        syllabuses: [],
        expired: [],
      },
      isFullRegistrant: false,
      session_schedules: [],
      isAlertErr: false,
      total_registrants: 0
    };
  },
  methods: {
    async getEventDetail() {
      try {
        const resp = await this.$http.get("/v2/event/" + this.$route.params.id);
        const item = resp.data.data.event;
        this.event = {
          ...item,
          session_schedules: item.session_schedules
            ? item.session_schedules
              ? item.session_schedules
              : [
                  {
                    date_end: "",
                    date_start: "",
                    end_time: "",
                    start_time: "",
                  },
                ]
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        };
        this.session_schedules = this.event.session_schedules
        if (
          this.event.session_schedules.length === 1 &&
          this.event.session_schedules[0].date_start &&
          this.event.session_schedules[0].date_end
        ) {
          this.sessionSchedulesDateStartToEnd();
        }
        this.getTotalRegistran()
      } catch {
        this.$router.push('/event')
      }
    },
    async getTotalRegistran() {
      try {
        const resp = await this.$http.get(`/v1/event/${this.event.id}/registrant`);
        this.total_registrants = resp.data.data.total_registrants  
        if (this.total_registrants >= this.event.participant_quota && this.event.participant_quota !== 0) {
          this.isFullRegistrant = true
        }
      } catch (error) {
        console.log(error);
      }
    },
    totalRegistrant(total) { 
      return (total / 100) * 100
    },
    toCheckout() {
      this.$router.push("/checkout/event/" + this.event.slug_name);
    },
    toKonsultasi() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    moment2(date) {
      return moment(date).format("DD MMM YYYY");
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date() ) {
        return true
      } else {
        return false
      }
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.event.registration_close_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.event.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.event.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    sessionSchedulesDateStartToEnd() {
      const getDaysArray = function (s, e) {
        for (
          var a = [], d = new Date(s);
          d <= new Date(e);
          d.setDate(d.getDate() + 1)
        ) {
          a.push(new Date(d));
        }
        return a;
      };
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      const end = new Date(this.event.session_schedules[0].date_end);
      const now = new Date(this.event.session_schedules[0].date_start);
      const distance = end - now;
      const days = Math.floor(distance / _day);
      for (let i = 0; i < days; i++) {
        const daylist = getDaysArray(new Date(now), new Date(end));
        this.session_schedules = daylist.map((v) => ({
          date_end: "",
          date_start: v.toISOString().slice(0, 10),
          end_time: "",
          start_time: "",
        }));
      }
    },
  },
  mounted() {
    this.getEventDetail();
    this.countDownTimer();
    window.scrollTo(0, 0);
  },
};
</script>
<style src="./eventDetail.css" scoped></style>
