<template>
        <AccelerationProgramDekstop v-if="!isMobile" />
        <AccelerationProgramMobile v-else />
</template>

<script>
import AccelerationProgramDekstop from '@/components/corporate/acceleration-program/desktop/AccelerationProgram.vue';
import AccelerationProgramMobile from '@/components/corporate/acceleration-program/mobile/AccelerationProgram.vue';

export default{
    components: {
        AccelerationProgramDekstop,
        AccelerationProgramMobile
    },
    metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: { lang: "id", amp: true},
        meta: [
          { name:"keywords", content:"Digital Akselerasi Program,HiColleagues,Digital Akselerasi Program HiColleagues,Corporate HiColleagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = window.innerWidth <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
}
</script>

<style scoped>


</style>