<template>
  <div class="container-size mb-5">
    <v-container class="py-10">
      <div class="f-search" v-if="mobileView">
        <div class="f-search-ic">
          <img
            src="/img/other/search.png"
            style="width: 100%"
            alt="Search Ic"
          />
        </div>
        <input
          type="text"
          class="f-input"
          placeholder="Cari Artikel"
          :v-model="title"
          @keyup="getBlogs"
        />
      </div>
      <div
        class="main-art"
        @click="$router.push('/blog/' + popular_article.slug_name)"
      >
        <img
          loading="lazy"
          :src="popular_article.thumbnail_url"
          alt="Image banner"
        />
        <div class="main-art-ctn">
          <h2 class="art-title">
            {{ popular_article.title }}
          </h2>
          <p
            v-html="$minimizeText(popular_article.meta_description ? popular_article.meta_description : popular_article.body)"
            class="body-content"
          ></p>
          <div class="author" v-if="!mobileView">
            <div class="author-info">
              <span class="font-poppins">{{ onDateFormatting(popular_article.date_release) }}</span>
              <span class="circle"></span>
              <span class="font-poppins" v-if="popular_article.reading_minute">Dibaca {{ popular_article.reading_minute }} menit</span>
            </div>
          </div>
        </div>
      </div>

      <div class="art-content">
        <h5 class="font-poppins mb-2">Semua Postingan</h5>
        <div class="art-content-list">
          <div
            class="card"
            v-for="(item, idx) in blogs"
            :key="idx"
            @click="$router.push('/blog/' + item.slug_name)"
          >
            <img
              loading="lazy"
              class="card-img"
              :src="item.thumbnail_url"
              alt="content"
            />
            <div class="card-content">
              <p class="title-berita">{{ $minimizeText(item.title, 150) }}</p>
              <p
                v-if="!mobileView"
                v-html="$minimizeText(item.meta_description ? item.meta_description : item.body)"
                class="body-art-item"
              ></p>
              <div class="spacing-author" v-if="!mobileView"></div>
              <div class="author bottom-author">
                <div class="author-info">
                  <span class="font-poppins">{{ onDateFormatting(item.date_release) }}</span>
                  <span class="circle"></span>
                  <span class="font-poppins" v-if="popular_article.reading_minute">Dibaca {{ item.reading_minute }} menit</span>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-2" v-if="mobileView && isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-row justify="center mt-10" v-if="!mobileView">
        <v-col>
          <Pagination
              @pageChanged="onPageChanged"
              :currentPage="currentPage"
              :totalPages="total"
           />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Pagination from '@/components/others/Pagination.vue'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      isLoading: false,
      slugName: "",
      title: "",
      blogs: [],
      mobileView: false,
      isLimitScrol: false,
      popular_article: {
        id: 0,
        title: "",
        slug_name: "",
        image_url: "",
        body: "",
        date_release: "",
      },
      best_article: [],
      mounth_list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },

  methods: {
    async getLastesBlog() {
      try {
        const resp = await this.$http.get("/v1/blog/latest");
        this.popular_article = resp.data.data.blog;
      } catch (err) {
        console.log(err);
      }
    },
    async getBlogs() {
      await this.$http
        .get(`/v1/blog?title=${this.title}&page=${this.currentPage}&per_page=9`)
        .then((response) => {
          if (this.title) {
            this.blogs = response.data.data?.blogs;
          } else {
            this.blogs = [];
            response.data.data?.blogs.map((item, idx) => {
              if (idx > 0) {
                this.blogs.push(item);
              }
            });
          }
          this.total = parseInt(response.data.data.total / 12) + 1;
        })
        .catch((err) => console.error(err.response));
    },
    onDateFormatting(dateStr) {
      const date = new Date(dateStr);
      return `${date.getDate()} ${
        this.mounth_list[date.getMonth()]
      } ${date.getFullYear()}`;
    },
    handleView() {
      if (window.innerWidth <= 990) {
        this.mobileView = true;
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getBlogs()
    },
    async getNextPage() {
      if (this.isLimitScrol) return
      this.isLoading = true;
      try {
        const resp = await this.$http.get(
          `/v1/blog?title=${this.title}&page=${this.currentPage}&per_page=9`
        );
        if (!resp.data.data?.blogs.length) {
          this.isLimitScrol = true
        }
        this.blogs.push(...resp.data.data?.blogs);
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    setupInviniteScroll() {
      if (!this.mobileView) return;
      window.onscroll = () => {
        if (window.scrollY + window.innerHeight + 5 >= document.body.scrollHeight) {
          this.currentPage++;
          this.getNextPage();
        }
      };
    },
  },
  created() {
    document.title = "Blog";
  },
  mounted() {
    this.getBlogs();
    this.getLastesBlog();
    this.setupInviniteScroll();
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { name:"keywords", content: "Artikel,Blog,HiCollagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
};
</script>

<style scoped>
.blog-div {
  width: 360px;
  height: 214px;
}
.art-title {
  line-height: 32px;
}
.main-art {
  display: flex;
  gap: 30px;
  cursor: pointer;
  margin-top: 20px;
}
.main-art-ctn {
  max-width: 45%;
}

.main-art img {
  width: 50%;
  border-radius: 25px;
  height: 300px;
  object-fit: cover;
}

.author {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.author img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
}
.author-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.author-title {
  margin: 0;
  font-weight: bold;
  font-size: 17px;
}
.author-info {
  display: flex;
  gap: 10px;
  font-size: 14px;
  align-items: center;
}
.title-berita {
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}
.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: gray;
}
.art-content {
  margin-top: 70px;
  font-size: 26px;
}
.art-content-list {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}
.bottom-author {
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.spacing-author {
  height: 50px;
}
.card {
  border: 1px solid #e7e8f0;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  width: 23%;
}
.card-img {
  width: 100%;
  height: 190px;
  border-radius: 25px 25px 0 0;
}
.card-content {
  padding: 10px;
}
.btn-see-all {
  max-width: 300px;
  color: #162ca2;
  font-size: 18px;
  text-align: center;
  border: 1px solid #162ca2;
  border-radius: 17px;
  height: 50px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.navigation {
  display: flex;
  gap: 10px;
  margin-bottom: -40px;
  align-items: center;
}
.active-nav {
  font-weight: bolder !important;
  color: #162ca2;
  cursor: pointer;
  font-size: 17px;
}

.f-search {
  background-color: #f5f6fa;
  width: 100%;
  height: 40px;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
  border-radius: 50px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.body-content {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
}
.body-art-item {
  margin: 10px 0;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}
@media only screen and (max-width: 900px) {
  .main-art {
    display: block !important;
    margin-top: 0;
  }
  .main-art img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
@media only screen and (max-width: 800px) {
  .card {
    gap: 10px;
    display: flex;
    width: 100%;
  }
  .art-content {
    margin-top: 20px;
  }
  .main-art-ctn {
    max-width: 100%;
  }
  .title-berita {
    font-size: 14px;
    line-height: 20px;
  }
  .bottom-author {
    position: static;
    margin-top: -3px;
  }
  .card-img {
    max-width: 130px;
    height: 90px;
    width: 100%;
    border-radius: 16px !important;
  }
  .author-info span {
    font-size: 12px;
  }
  .art-content-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 470px) {
  .art-title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-content {
    font-size: 14px;
  }

  .circle {
    width: 5px;
    height: 5px;
  }
}
</style>
