<template>    
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.36968 8.715C7.34718 8.715 7.33218 8.715 7.30968 8.715C7.27218 8.7075 7.21968 8.7075 7.17468 8.715C4.99968 8.6475 3.35718 6.9375 3.35718 4.83C3.35718 2.685 5.10468 0.9375 7.24968 0.9375C9.39468 0.9375 11.1422 2.685 11.1422 4.83C11.1347 6.9375 9.48468 8.6475 7.39218 8.715C7.38468 8.715 7.37718 8.715 7.36968 8.715ZM7.24968 2.0625C5.72718 2.0625 4.48218 3.3075 4.48218 4.83C4.48218 6.33 5.65218 7.5375 7.14468 7.59C7.18968 7.5825 7.28718 7.5825 7.38468 7.59C8.85468 7.5225 10.0097 6.315 10.0172 4.83C10.0172 3.3075 8.77218 2.0625 7.24968 2.0625Z" fill="#7B7E8C"/>
    <path d="M12.9048 8.8125C12.8823 8.8125 12.8598 8.8125 12.8373 8.805C12.5298 8.835 12.2148 8.6175 12.1848 8.31C12.1548 8.0025 12.3423 7.725 12.6498 7.6875C12.7398 7.68 12.8373 7.68 12.9198 7.68C14.0148 7.62 14.8698 6.72 14.8698 5.6175C14.8698 4.4775 13.9473 3.555 12.8073 3.555C12.4998 3.5625 12.2448 3.3075 12.2448 3C12.2448 2.6925 12.4998 2.4375 12.8073 2.4375C14.5623 2.4375 15.9948 3.87 15.9948 5.625C15.9948 7.35 14.6448 8.745 12.9273 8.8125C12.9198 8.8125 12.9123 8.8125 12.9048 8.8125Z" fill="#7B7E8C"/>
    <path d="M7.37721 16.9125C5.90721 16.9125 4.42971 16.5375 3.31221 15.7875C2.26971 15.0975 1.69971 14.1525 1.69971 13.125C1.69971 12.0975 2.26971 11.145 3.31221 10.4475C5.56221 8.955 9.20721 8.955 11.4422 10.4475C12.4772 11.1375 13.0547 12.0825 13.0547 13.11C13.0547 14.1375 12.4847 15.09 11.4422 15.7875C10.3172 16.5375 8.84721 16.9125 7.37721 16.9125ZM3.93471 11.3925C3.21471 11.8725 2.82471 12.4875 2.82471 13.1325C2.82471 13.77 3.22221 14.385 3.93471 14.8575C5.80221 16.11 8.95221 16.11 10.8197 14.8575C11.5397 14.3775 11.9297 13.7625 11.9297 13.1175C11.9297 12.48 11.5322 11.865 10.8197 11.3925C8.95221 10.1475 5.80221 10.1475 3.93471 11.3925Z" fill="#7B7E8C"/>
    <path d="M14.2546 15.5625C13.9921 15.5625 13.7596 15.3825 13.7071 15.1125C13.6471 14.805 13.8421 14.5125 14.1421 14.445C14.6146 14.3475 15.0496 14.16 15.3871 13.8975C15.8146 13.575 16.0471 13.17 16.0471 12.7425C16.0471 12.315 15.8146 11.91 15.3946 11.595C15.0646 11.34 14.6521 11.16 14.1646 11.0475C13.8646 10.98 13.6696 10.68 13.7371 10.3725C13.8046 10.0725 14.1046 9.87751 14.4121 9.94501C15.0571 10.0875 15.6196 10.3425 16.0771 10.695C16.7746 11.22 17.1721 11.9625 17.1721 12.7425C17.1721 13.5225 16.7671 14.265 16.0696 14.7975C15.6046 15.1575 15.0196 15.42 14.3746 15.5475C14.3296 15.5625 14.2921 15.5625 14.2546 15.5625Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Participants",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
  },
};
</script>