<template>
  <div style="margin-top: 63px">
    <div class="hero">
      <div class="hero-ctn">
        <h1>Cerita Alumni</h1>
        <p>
          Berbagai cerita alumni program pelatihan akselerasi digital
          Upskilling, New Skilling, & Reskilling bersama HiColleagues
        </p>
      </div>
    </div>
    <div style="background-color: #f5f6fa">
      <div class="container">
        <div class="card" style="padding-bottom: 60px">
          <div
            class="items"
            v-for="(item, idx) in stories"
            :key="idx"
            @click="$router.push(`/alumni-stories/${item.id}`)"
          >
            <div style="height: 240px">
              <img
                :src="item.image_url"
                alt=""
                style="width: 100%; height: 100%; border-radius: 20px 20px 0 0"
              />
            </div>
            <div class="content">
              <div style="height: 70px">
                <h2>{{ item.title }}</h2>
              </div>
              <p class="description-class">{{ item.caption }}</p>
            </div>
          </div>
        </div>
        <EmptyData
          v-if="!stories || !stories.length"
          message="Belum ada Cerita Alumni saat ini"
        />
        <v-row justify="center mt-10" v-if="total > 1">
                <v-col>
                    <Pagination
              @pageChanged="onPageChanged"
              :currentPage="currentPage"
              :totalPages="total"
           />
                </v-col>
            </v-row>
      </div>
    </div>
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 40px">
        <v-col cols="7">
          <div class="call-center-title">
            Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam
            Transformasi Digital Berkelanjutan
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan
            konsultasi 24/7 Customer Service HiColleagues
          </div>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4" class="call-center-button" @click="sendWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import EmptyData from "../../others/EmptyData.vue";
import Pagination from '@/components/others/Pagination.vue'

export default {
  name: "DesktopAlumniStories",
  components: {
    EmptyData,
    Pagination
  },
  data() {
    return {
      stories: [],
      currentPage: 1,
      total: 1,
    };
  },
  methods: {
    sendWa() {
      window.open("https://wa.me/6281234567890", "_blank");
    },
    page(act) {
      if (act === "prev") {
        if (this.currentPage <= 1) return;
        this.currentPage--;
      } else {
        if (this.currentPage >= this.total) return;
        this.currentPage++;
      }
      this.getStories()
    },
    async getStories() {
      try {
        const resp = await this.$http.get(
          `/v1/alumni-stories?page=${this.currentPage}&per_page=12`
        );
        this.stories = resp.data.data.storiess;
        if (resp.data.data.total)
          this.total = Math.floor(resp.data.data.total / 9) + 1;
      } catch (err) {
        console.log(err);
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getStories()
    },
  },
  mounted() {
    this.getStories();
  },
};
</script>

<style scoped src="./alumniStories.css"></style>