<template>
  <div style="color: #36373f; margin-top: 63px">
    <popup :message="alertErrMessage" v-if="isAlertErr" :isError="isError" @close="isAlertErr = false" />
    <div class="hero">
      <div class="content">
        <h1>Bangun Masa Depan Perusahaan melalui Peningkatan Skill Talenta</h1>
        <p>
          Perluas skala bisnis dalam dinamika transformasi digital dengan
          Upskilling &/ New Skilling talenta perusahaan melalui layanan
          Corporate Training HiColleagues sebagai mitra terpercaya & kredibel
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection('collab_training')">
            KOLABORASI SEKARANG
          </button>
          <button class="call" @click="sendToWa">HUBUNGI KAMI</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/corporate/tech-main.png" width="520" height="" alt="" />
      </div>
    </div>
    <div class="container-fade">
      <div class="left-fade"></div>
      <div class="right-fade"></div>
    </div>
    <div class="creditbility">
      <div class="partner">
        <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
        <div class="card" style="overflow-x: none !important">
          <div v-for="(partner, index) in partners" :key="index">
            <div class="logo-card-partner-2">
              <!-- <img src="/img/partner/astrapay.png" width="160" /> -->
              <img :src="partner.img_url_web" width="160" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="content">
        <h1 class="ttl">Layanan Corporate Training HiColleagues</h1>
        <div>
          Sesuaikan kebutuhan perusahaan melalui program unggulan HiColleagues
          dan berikan dampak ke peningkatan performa bisnis Anda
        </div>
      </div>
      <div class="list-service">
        <!-- Loop -->
        <div v-for="(data, index) in courseOffer" :key="index" class="data">
          <!-- Image -->
          <div>
            <img :src="data.img" alt="" style="width: 54px; height: 54px; border-radius: 100%">
          </div>
          <!-- Title and Description -->
          <div style="display: flex; flex-direction: column; gap: 0.2rem">
            <!-- Title -->
            <div style="font-weight: 750; font-size: 1rem">
              {{ data.title }}
            </div>
            <!-- Description -->
            <div style="font-size: 0.8rem; text-align: justify">
              {{ data.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course">
      <div class="ctn">
        <h1 style="font-size: 32px; color: #36373f; margin: 0">
          Program Unggulan Akselerasi Skill Digital
        </h1>
        <div>
          Rekomendasi program pelatihan untuk akselerasi skill talenta digital
          dan peningkatan performa talenta perusahaan
        </div>
      </div>
      <v-tabs color="#575966" grow>
        <v-tab v-for="item in programTabs" :key="item.value" :value="item.value" @click="chooseTab(item)" class="tab">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <!-- Card -->
      <v-window v-model="program">
        <v-window-item v-for="index in programTabs.length" :key="index">
          <div style="
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 20px;
            ">
            <div v-for="(data, idx) in courseMaterial" :key="idx">
              <v-card elevation="0" class="taba">
                <!-- Title -->
                <div class="d-flex align-center ddd">
                  <MessageIcon />
                  <div style="font-weight: 700">{{ data.title }}</div>
                </div>
                <div class="nnn">
                  <!-- Description -->
                  <div class="sub-header text-justify" style="font-size: 14px">
                    {{ data.description }}
                  </div>
                  <!-- Material -->
                  <div class="ccc">Cakupan Pembelajaran</div>
                  <!-- Material Loop -->
                  <ul style="">
                    <li v-for="(item2, idx2) in data.scope_learning" :key="idx2" style="font-size: 14px">
                      {{ item2.name }}
                    </li>
                  </ul>
                </div>
              </v-card>
            </div>
          </div>
        </v-window-item>
      </v-window>
      <!-- Promo -->
      <v-card elevation="0" class="card-box-info">
        <v-row style="padding: 40px">
          <v-col cols="8">
            <div class="data1">
              Belum Menemukan Program Sesuai Kebutuhan <br />
              Perusahaan Anda?
            </div>
            <div>
              Konsultasikan kebutuhan perusahaan Anda dan kami siap mangakomodir
              melalui layanan <br />
              <span style="font-weight: 700">Training Need Analysis</span>
              untuk memaksimalkan proyeksi bisnis Anda.
            </div>
          </v-col>
          <v-col cols="4" class="ctn-konsultasi" @click="sendToWa">
            <v-btn class="btnn" elevation="0"> KONSULTASI SEKARANG </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div style="padding: 60px">
      <!-- USP -->
      <v-row>
        <v-col cols="6" style="padding-left: 46px">
          <div style="padding-right: 20px">
            <div style="font-size: 28px; font-weight: 700">
              Mengapa Memilih HiColleagues sebagai Mitra Corporate Training
            </div>
            <div style="margin-top: 12px">
              HiColleagues telah membuktikan melalui <b>100+ pelatihan yang telah
                dilaksanakan</b> dan memberi dampak positif terhadap akselerasi skill
              talenta mitra
            </div>
          </div>
          <div style="
              margin-top: 80px;
              width: 90%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
            ">
            <img src="/img/corporate/tech/main-2.png" width="100%" />
          </div>
        </v-col>
        <v-col cols="6" style="padding-left: 20px">
          <v-col v-for="(data, index) in WhyChoose" :key="index">
            <v-row>
              <div style="padding-top: 18px; margin-right: 8px">
                <img :src="`/img/corporate/w-${index + 1}.png`" alt="aaa"
                  style="width: 42px; height: 42px;border-radius: 100%; ">
              </div>
              <v-col cols="10">
                <div style="
                    font-size: 18px;
                    font-weight: 700;
                    margin-top: 8px;
                    margin-bottom: 6px;
                  ">
                  {{ data.title }}
                </div>
                <div class="sub-heading" style="font-size: 13px">
                  {{ data.desc }}
                </div>
              </v-col>
              <div style="
                  border-bottom: 1px #d3d6e1 solid;
                  width: 100%;
                  padding-top: 10px;
                  margin-top: 10px;
                  margin-bottom: 20px;
                " v-if="index != 5"></div>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <StepCollaboration title="Langkah Kolaboratif Kemitraan" :steps="steps" />

      <Partner title="HiColleagues Partner" :partners="dataMitra" />
      <portofolio title="Proyek yang Telah Berjalan" :portfolios="portfolios" />
      <Testimony title="Testimoni Kepuasan Klien Kami" :testimonies="testimony" />

      <div class="form-konsultasi" id="collab_training">
        <div class="header text-center">
          <h1>Formulir Pendaftaran Konsultasi</h1>
        </div>
        <div class="sub-heading text-center" style="margin-top: -30px">
          <p>
            Langkah tepat optimasi bisnis melalui layanan Corporate Service
            bersama HiColleagues
          </p>
          <v-form class="text-start" style="margin-top: 60px">
            <v-row>
              <v-col cols="6">
                <label class="form-label" for="company">Nama Perusahaan<span
                    style="color: rgba(184, 15, 10, 1)">*</span></label>
                <v-text-field class="form-input text-form" name="company" v-model="form.corporate_name" outlined
                  placeholder="Masukkan Nama Perusahaan"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="form-label" for="website">Website Perusahaan</label>
                <v-text-field class="form-input text-form" v-model="form.web_url" name="website" outlined
                  placeholder="Masukkan Alamat Website Perusahaan">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label class="form-label" for="pic-name">Nama Lengkap PIC<span
                    style="color: rgba(184, 15, 10, 1)">*</span></label>
                <v-text-field class="form-input text-form" name="pic-name" v-model="form.full_name" outlined
                  placeholder="Masukkan Nama Lengkap PIC"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="form-label" for="occupation">Okupasi PIC</label>
                <v-text-field class="form-input text-form" name="occupation" v-model="form.job_pic" outlined
                  placeholder="Masukkan Okupasi PIC">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label class="form-label" for="email">Email PIC<span
                    style="color: rgba(184, 15, 10, 1)">*</span></label>
                <v-text-field class="form-input text-form" name="email" v-model="form.email" outlined
                  placeholder="Masukkan Email PIC"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="form-label" for="phone-number">No. Whatsapp PIC<span
                    style="color: rgba(184, 15, 10, 1)">*</span></label>
                <v-text-field class="form-input text-form" name="phone-number" outlined
                  placeholder="Masukkan No. Whatsapp PIC" type="number" v-model="form.phone_number" hide-spin-buttons>
                  <template v-slot:prepend-inner>
                    <v-row class="d-flex align-center mt-0 ml-7">
                      <img src="/img/other/flag-indonesia.png" alt="" width="30" height="20" style="
                          box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1);
                        " />
                      <span style="color: #575966" class="mx-3">+62</span>
                    </v-row>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="form-collab">
                <label class="form-label" for="collaboration">Proyeksi Kolaborasi/Kemitraan</label>
                <v-text-field class="form-input text-form" solo flat background-color="rgba(247, 249, 253, 1)"
                  placeholder="Corporate Training" disabled />
              </v-col>
            </v-row>
            <v-row style="margin-bottom: -30px">
              <v-col cols="6">
                <label class="form-label">Kategori Kolaborasi
                  <span style="color: #c63c38">*</span></label>
                <v-select flat outlined color="#ACAFBF" persistent-hint placeholder="Pilih Kategori Pelatihan"
                  v-model="program" :items="courseOffer" :item-text="'title'" :item-value="'value'"
                  @change="getCourseMaterial" style="margin-top: 10px" />
              </v-col>
              <v-col cols="6">
                <label class="form-label">Program Pelatihan
                  <span style="color: #c63c38">*</span></label>
                <v-select flat outlined color="#ACAFBF" persistent-hint placeholder="Pilih Program Pelatihan"
                v-model="form.program" :items="courseMaterial" :item-text="'title'" :item-value="'title'" style="margin-top: 10px" />
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center mt-3">
              <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
                <template v-slot:label>
                  <p class="label-checkbox">
                    Dengan mengirim formulir ini saya menyetujui<a href="/term-condition">
                      Syarat & Ketentuan </a>untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                  </p>
                </template>
              </v-checkbox>
            </v-row>
            <v-row class="d-flex justify-center align-center mt-6">
              <v-btn elevation="0" :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`" @click="onSubmit">KIRIM
              </v-btn>
              -
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: 10px">
            <v-expansion-panels flat class="faq-card" style="background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <ArrowIcon />
                    </div>
                    <div v-else>
                      <ArrowIcon :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="margin-left: 10px">
              <div class="btn-stoke-blue" @click="$router.push('/faq')">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Components
import ArrowIcon from "@/assets/icons/Arrow.vue";
import StepCollaboration from "../../StepCollaboration.vue";
import Testimony from "../../Testimony.vue";
import Portofolio from "../../Portofolio.vue";
import Partner from "../../Partner.vue";
import Popup from "@/components/others/Popup.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';

export default {
  name: "CorporateTraining",
  components: {
    ArrowIcon,
    StepCollaboration,
    Testimony,
    Portofolio,
    Partner,
    Popup,
    ArrowLong
  },
  data() {
    return {
      generalTraining: true,
      certifiedTraining: false,
      program: 0,
      courseOffer: [
        {
          img: "/img/corporate/tech-4.png",
          title: "General Corporate Training",
          desc: "Berbasis pada pelatihan dan pengembangan skill talenta untuk meningkatkan performa perusahaan. Pelatihan mencakup pengetahuan, keterampilan, dan sikap, sementara pengembangan berfokus pada karir jangka panjang. Training Needs Analysis diupayakan dalam mewujudkan program berjalan secara maksimal",
          value: 0,
        },
        {
          img: "/img/corporate/tech-3.png",
          title: "Certified Corporate Training",
          desc: "Berbasis pada pelatihan talenta melalui Upskilling dan New Skilling untuk meningkatkan performa perusahaan yang mencakup pengetahuan, keterampilan, dan sikap. Menerapkan Training Needs Analysis dan sertifikasi dari lisensi global sebagai bukti tercapainya program dan validasi peningkatan performa talenta.",
          value: 1,
        },
      ],
      programTabs: [
        {
          title: "General Corp. Training Program",
          value: 0,
        },
        {
          title: "Certified Corp. Training Program",
          value: 1,
        },
      ],
      WhyChoose: [
        {
          id: 1,
          title: "Telah dipercaya leh 30+ Perusahaan Besar Di Indonesia",
          desc: "HiColleagues telah dipercaya oleh 30+ perusahaan besar di Indonesia dikarenakan berhasil mengembangkan SDM pada perusahaan sehingga siap dalam menghadapi transformasi digital.",
        },
        {
          id: 2,
          title: "Pelatihan Sertifikasi dari Instansi Global",
          desc: "Selain upskilling dan new skilling, perusahaan memiliki kesempatan untuk memilih pelatihan sekaligus mendapat sertifikasi dari Instansi global.",
        },
        {
          id: 3,
          title: "Waktu Pelatihan Fleksibel",
          desc: "Pelatihan fleksibel dapat secara online maupun offline dan dapat disesuaikan tanpa mengganggu waktu kerja.",
        },
        {
          id: 4,
          title: "Instruktur Profesional",
          desc: "Training dengan instruktur yang berpengalaman dibidangnya.",
        },
        {
          id: 5,
          title: "Kurikulum up-to-date",
          desc: "Kurikulum terbaru dan silabus kurikulum dapat disesuaikan dengan kebutuhan perusahaan.",
        },
        {
          id: 6,
          title: "Metode Pembelajaran yang Interaktif",
          desc: "Metode pembelajaran secara praktikal (80% praktik dan 20% teori).",
        },
      ],
      courseMaterial: [],
      steps: [
        {
          image_url: "/img/corporate/kolab-img.png",
          title: "Validasi Konsep Pelatihan",
          description:
            "Perancangan konsep pelatihan yang HiColleagues tawarkan akan divalidasi atau disetujui oleh mitra baik dari sisi silabus, mekanisme, & biaya pelatihan sebagai bentuk dimulainya kerjasama antar kedua belah pihak.",
        },
        {
          image_url: "/img/corporate/kolab-img.png",
          title: "Pelaksanaan Pelatihan",
          description:
            "Program pelatihan akan diselenggarakan oleh profesional tim HiColleagues. Memastikan agar program kerjasama ini berjalan dengan maksimal, efektif, dan efisien sesuai kebutuhan atau ekspektasi dari mitra perusahaan.",
        },
        {
          image_url: "/img/corporate/kolab-img.png",
          title: "Laporan, Penilaian, & Sertifikat Pelatihan",
          description:
            "Secara berkala tim HiColleagues akan memberikan laporan mengenai penyelenggaraan program sebagai bentuk komitmen yang akuntabel. Hal ini didukung juga dengan adanya penilaian & penerbitan sertifikat bagi masing-masing peserta.",
        },
        {
          image_url: "/img/corporate/kolab-img.png",
          title: "Analisis Pasca Pelatihan",
          description:
            "Pasca pelaksanaan program pelatihan tim HiColleagues melaksanaan analisis mengenai penyelenggaraan, capaian hasil, hingga ulasan sebagai bentuk validasi penyempurnaan strategi ke depan bagi mitra.",
        },
      ],
      portfolios: [],
      testimony: [],
      questions: [],
      partners: [],
      dataMitra: [],
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "TRAINING",
        web_url: "",
        job_pic: "",
      },
    };
  },
  watch: {
    program() {
      this.getCourseMaterial();
    },
  },
  methods: {
    chooseTab(item) {
      this.program = item.value;
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=TRAINING");
        this.partners = resp.data.data.logos;
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=TRAINING&page=1&per_page=9"
        );
        this.testimony = resp.data.data.testimonies;
        while (this.testimony.length % 3 !== 0) {
          this.testimony.push("");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getMitra() {
      try {
        const resp = await this.$http.get(
          "/v1/partner?category=TRAINING&page=1&per_page=9"
        );
        this.dataMitra = resp.data.data.partners;
      } catch (err) {
        console.log(err);
      }
    },
    async getCourseMaterial() {
      try {
        const resp = await this.$http.get(
          `/v1/corporate-training?category=${this.program ? "CERTIFIED" : "GENERAL"
          }`
        );
        this.courseMaterial = resp.data.data.corporate_trainings;
        this.form.program = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getPortofolio() {
      try {
        const resp = await this.$http.get(
          "/v1/project-portfolio?category=TRAINING&is_main=true"
        );
        this.portfolios = resp.data.data.project_portfolios;
      } catch (err) {
        console.log(err);
      }
    },
    duptlicatePartners() {
      this.partners = this.partners.concat(
        this.partners,
        this.partners,
        this.partners
      );
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=TRAINING&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false;
      this.form.full_name = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.corporate_name = "";
      this.form.category = "";
      this.form.web_url = "";
      this.form.job_pic = "";
      this.form.program = "";
      this.notes = "";
      this.program = "";
    },
  },
  mounted() {
    this.getFaq();
    this.getTestimoni();
    this.getClientLogo();
    this.getCourseMaterial();
    this.getPortofolio();
    this.getMitra();
  },
};
</script>

<style src="./corporateTraining.css" scoped></style>