<template>
  <div :class="`accordion ${bgPanel}`">
    <!-- Accordion Header -->
    <div class="accordion-header" @click="toggleAccordion">
      <h4 class="accordion-title">{{ title }}</h4>
    </div>

    <!-- Animated Accordion Content -->
    <transition
      name="accordion"
      @enter="startTransition"
      @leave="endTransition"
    >
      <div v-if="active" class="accordion-content">
        <div class="faq-answer" v-html="description"></div>
        <div :class="bgPanel != 'bgWhite' ? bgPanel : 'bgWhite'" @click="toggleAccordion" style="text-align: center; margin-top: 10px;">
          <Arrow v-if="active" class="icon-rotate" />
        </div>
      </div>
    </transition>
    
    <div v-if="!active" @click="toggleAccordion" :class="bgPanel != 'bgWhite' ? bgPanel : 'bgWhite'" style="text-align: center; margin-top: 10px;">
      <Arrow :down="!active" class="icon"/>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";

export default {
  name: "Accordion",
  components: { Arrow },
  props: {
    title: String,
    description: String,
    active: Boolean,
    bgPanel: {
      type: String,
      default: 'bgWhite'
    }, 
  },
  methods: {
    toggleAccordion() {
      this.$emit("toggle");
    },
    startTransition(element) {
      const height = element.scrollHeight;
      element.style.height = '0px';
      // Force reflow
      element.offsetHeight;
      element.style.height = height + 'px';
    },
    endTransition(element) {
      element.style.height = element.scrollHeight + 'px';
      // Force reflow
      element.offsetHeight;
      element.style.height = '0px';
    }
  },
};
</script>

<style scoped>
.accordion {
  border-radius: 20px;
  padding: 16px 24px;
  margin-top: 16px;
  overflow: hidden;
}

.bgWhite {
  background-color: white;
}

.bgAliceBlue {
  background-color: #F1F6FF;
}

.accordion-title {
  line-height: 22px;
  font-size: 15px;
  color: #36373f;
}

.accordion-content {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.faq-answer {
  font-size: 14px;
  color: #575966;
  font-weight: 400;
  margin-top: 10px;
}

.icon-accordion-container {
  background-color: white;
}

/* Arrow icon animations */
.icon {
  transition: transform 0.3s ease;
}

.icon-rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

/* Accordion animations */
.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  opacity: 0;
  height: 0;
}
</style>