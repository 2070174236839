<template>
  <div class="mt-10">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      :isError="isError"
      @close="isAlertErr = false"
    />

    <div style="background-color: #162ca2">
      <v-img
        src="/img/corporate/corporate.png"
        width="100%"
        style="border-radius: 0px 0px 0px 120px"
      />
      <v-spacer style="height: 25px" />
      <div style="padding-inline: 34px">
        <div class="hero">
          <div style="font-style: italic; color: #ffde59">
            One-Stop Solution
          </div>
          Optimasi Bisnis pada Transformasi Digital
        </div>
        <v-spacer style="height: 25px" />
        <div class="hero-content">
          Perluas skala bisnis perusahaan Anda dalam dinamika transformasi
          digital melalui layanan Corporate Service HiColleagues sebagai mitra
          akselerasi digital terpercaya & kredibel
        </div>
        <v-spacer style="height: 25px" />
        <v-btn
          class="btn"
          block
          style="background-color: #ffde59; color: #162ca2"
          elevation="0"
          @click="scrollToSection('corporate_form')"
        >
          KONSULTASI SEKARANG
        </v-btn>
        <v-spacer style="height: 10px" />
        <v-btn
          class="btn"
          block
          style="background-color: #fff; color: #36373f"
          elevation="0"
          @click="sendToWa"
        >
          HUBUNGI KAMI
        </v-btn>
      </div>
      <v-spacer style="height: 40px" />
    </div>
    <div style="background-color: #162ca2">
      <div
        style="
          background-color: #fff;
          border-radius: 26px 26px 0px 0px;
          padding-inline: 34px;
        "
      >
        <v-spacer style="height: 37px" />
        <div class="lg-heading">
          HiColleagues telah Dipercaya Perusahaan Ternama
        </div>
        <v-spacer style="height: 20px" />
        <div
          class="d-flex overflow-x-hidden overflow-y-hidden"
          style="height: 100%"
        >
          <v-col
            cols="6"
            v-for="(data, idx) in partners"
            :key="idx"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container
            "
          >
            <v-img :src="data.img_url_mobile" contain />
          </v-col>
          <v-col
            cols="6"
            v-for="partner in partners"
            :key="partner.title"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container-2
            "
          >
            <v-img :src="'/img/corporate/' + partner.img" contain />
          </v-col>
        </div>
        <v-spacer style="height: 40px" />
        <div class="lg-heading">
          Sejak 2020 HiColleagues Berkomitmen sebagai Mitra Akselerasi Digital
          Terpercaya
        </div>
        <v-spacer style="height: 10px" />
        <div class="lg-content">
          Inovasi & Layanan HiColleagues terbukti memberikan dampak signifikan
          terhadap skala pertumbuhan perusahaan mitra
        </div>
        <v-spacer style="height: 20px" />
        <v-row>
          <v-col
            v-for="(statistic, idx) in statistics"
            :key="idx"
            cols="6"
            class="d-flex flex-column align-center"
          >
            <components :is="statistic.img" width="30" height="30"></components>
            <div class="md-heading">
              {{ statistic.number }}
            </div>
            <div class="md-content">
              {{ statistic.desc }}
            </div>
          </v-col>
        </v-row>
        <v-spacer style="height: 40px" />
      </div>
    </div>
    <div style="background-color: #fff">
      <div class="layar">
        <v-spacer style="height: 40px" />
        <div class="md-heading">
          Layanan Akselerasi Transformasi Digital Kami
        </div>
        <v-spacer style="height: 10px" />
        <v-col
          style="padding: 20px 0px"
          v-for="(keyFeature, idx) in keyFeatures"
          :key="idx"
        >
          <v-img :src="keyFeature.image" width="100%" />
          <v-spacer style="height: 10px" />
          <div class="sm-heading">{{ keyFeature.title }}</div>
          <v-spacer style="height: 10px" />
          <div class="md-content" style="text-align: justify">
            {{ keyFeature.desc }}
          </div>
          <v-btn
            class="btn py-4 mt-5"
            block
            style="background-color: #162ca2; color: #fff"
            elevation="0"
            @click="$router.push(keyFeature.link)"
          >
            {{ keyFeature.link ? "PELAJARI SELENGKAPNYA" : "COMING SOON" }}
            <ArrowRight class="ml-4" />
          </v-btn>
        </v-col>
        <v-spacer style="height: 40px" />
      </div>
    </div>
    <div style="background-color: #fff; padding-inline: 34px">
      <v-spacer style="height: 40px" />
      <div class="lg-heading">Program Akselerasi Digital</div>
      <div class="lg-content">
        Rekomendasi program pelatihan untuk akselerasi skill talenta digital
      </div>
      <v-spacer style="height: 20px" />
      <v-card
        style="border-radius: 16px; margin-bottom: 20px"
        v-for="(data, idx) in listAcceleration"
        :key="idx"
        @click="
          $router.push(`/corporate/acceleration/detail/${data.slug_name}`)
        "
      >
        <v-img :src="data.image_url" width="100%" />
        <v-row class="d-flex justify-end collaboration_feed-warp">
          <div class="d-flex justify-end collaboration_feed-warp-image">
            <v-img :src="data.collaboration_feed" contain />
          </div>
        </v-row>
        <div
          style="
            background-color: #fff;
            padding: 15px 19px;
            border-radius: 16px;
            margin-bottom: 5px;
            z-index: 2;
            position: relative;
            top: -40px;
          "
        >
          <v-col>
            <v-row class="card-title" style="margin-bottom: 8px">{{
              data.name
            }}</v-row>
            <v-row style="gap: 6px">
              <div
                :style="{
                  backgroundColor: '#F1F6FF',
                  padding: '10px',
                  fontSize: '10px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  lineHeight: '10px',
                  fontWeight: '400',
                  marginBottom: '20px',
                }"
              >
                {{ data.type }}
              </div>
              <div
                :style="{
                  backgroundColor: categoryColor[idx],
                  padding: '10px',
                  fontSize: '10px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  lineHeight: '10px',
                  fontWeight: '400',
                  marginBottom: '20px',
                }"
              >
                {{ data.class_type }}
              </div>
              <div
                :style="{
                  backgroundColor: categoryColor[idx],
                  padding: '10px',
                  fontSize: '10px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  lineHeight: '10px',
                  fontWeight: '400',
                  marginBottom: '20px',
                }"
              >
                {{ toMethode(data.method) }}
              </div>
            </v-row>
            <v-row
              style="text-align: justify"
              class="md-content description-class"
            >
              {{ data.description }}
            </v-row>
            <v-row
              v-if="!data.is_discount"
              style="
                color: #162ca2;
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                margin-top: 28px;
              "
            >
              {{ data.price | toCurrency }}
            </v-row>
            <v-row v-else>
              <v-col style="margin-top: 8px; padding: 0px">
                <div
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    text-decoration: line-through;
                    color: #b80f0a;
                  "
                >
                  {{ data.price | toCurrency }}
                </div>
                <div
                  style="
                    color: #162ca2;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 32px;
                  "
                >
                  {{ toDiscount(data) }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-card>
      <v-btn
        class="btn"
        block
        style="background-color: #162ca2; color: #fff"
        elevation="0"
        @click="$router.push('/corporate/acceleration')"
      >
        Lihat Selengkapnya
      </v-btn>
    </div>
    <!-- Why Choose HiColleagues -->
    <div
      style="
        background-color: #f1f6ff;
        padding: 40px 36px;
        border-radius: 26px;
        color: #36373f;
      "
    >
      <!-- Title -->
      <div
        style="
          font-weight: 700;
          font-size: 18px;
          text-align: center;
          margin-bottom: 30px;
        "
      >
        Mengapa memilih HiColleagues
      </div>
      <!-- Card -->
      <v-card
        v-for="(data, index) in whyChooseHiCol"
        :key="index"
        style="
          background-color: #ffffff;
          border-radius: 16px;
          padding: 20px;
          margin-bottom: 20px;
        "
        elevation="0"
      >
        <!-- Img -->
        <div>
          <v-img :src="`/img/corporate/${data.img}.png`" width="40px" />
        </div>
        <v-spacer style="height: 10px" />
        <!-- Content -->
        <div>
          <!-- Title -->
          <div style="font-weight: 700; font-size: 17px">
            {{ data.title }}
          </div>
          <v-spacer style="height: 6px" />
          <!-- Description -->
          <div style="font-size: 10px">
            {{ data.desc }}
          </div>
        </div>
      </v-card>
    </div>
    <v-spacer style="height: 40px" />
    <!-- Testimony -->
    <div>
      <!-- Title -->
      <div class="title_and_description">Testimoni Kepuasan Klien Kami</div>
      <!-- Card -->
      <v-window v-model="testimonyModel" style="padding: 0px 20px">
        <v-window-item
          v-for="index in testimony.length"
          :key="index"
          style="padding: 10px; height: 220px"
        >
          <v-card
            v-for="(data, index) in slicedTestimony(testimonyModel)"
            :key="index"
            style="
              background-color: #ffffff;
              box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
              border-radius: 16px;
              padding: 17px 20px;
              height: 190px;
            "
          >
            <v-row>
              <!-- Message -->
              <v-col cols="12">
                <div class="feedback">“{{ data.feedback }}”</div>
              </v-col>
              <!-- User -->
              <v-col cols="12">
                <v-row>
                  <!-- Image -->
                  <v-col cols="3">
                    <v-img
                      :src="$utils.loadImage(data.image_url)"
                      width="60"
                      height="60"
                      style="border-radius: 50%"
                    />
                  </v-col>
                  <!-- Name and Position -->
                  <v-col cols="9">
                    <div style="font-size: 12px; font-weight: 700">
                      {{ data.name }}
                    </div>
                    <div style="font-size: 10px">
                      {{ data.job }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
      <!-- Pagination -->
      <v-row
        style="margin: 0px 10px; padding: 0px 10px"
        class="d-flex align-center"
      >
        <v-col cols="8">
          <div class="slider">
            <div
              class="indicator"
              :style="{
                width: `${sliderIndicatorTestimony}%`,
                transform: `translateX(${indicatorValueTestimony}%)`,
                transition: `transform 0.5s`,
              }"
            ></div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="d-flex"
          style="justify-content: flex-end; gap: 11px"
        >
          <v-btn
            v-for="index in 2"
            fab
            :key="index"
            @click="index === 1 ? testimonyPrev() : testimonyNext()"
            class="btn-mobile-slider-corp"
            elevation="0"
          >
            <ArrowIcon :direct="index === 1 ? 'right' : 'left'" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-spacer style="height: 60px" />
    <!-- Form -->
    <div id="corporate_form">
      <!-- Title and Description -->
      <div style="padding: 0px 20px; margin-bottom: 46px">
        <!-- Title -->
        <div class="title_and_description">Formulir Pendaftaran Konsultasi</div>
        <!-- Description -->
        <div style="text-align: center; font-size: 12px">
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </div>
      </div>
      <!-- Form -->
      <v-form style="padding: 0px 30px">
        <v-row>
          <!-- Company Name -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Nama Perusahaan
            </div>
            <v-text-field
              flat
              outlined
              color="#ACAFBF"
              v-model="form.corporate_name"
              placeholder="Masukkan Nama Perusahaan"
              style="margin-top: 14px"
            />
          </v-col>
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Website Perusahaan
            </div>
            <v-text-field
              flat
              outlined
              color="#ACAFBF"
              v-model="form.web_url"
              placeholder="Masukkan Alamat Website Perusahaan"
              style="margin-top: 14px"
            />
          </v-col>
          <!-- Full Name PIC -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Nama Lengkap PIC
            </div>
            <v-text-field
              flat
              outlined
              v-model="form.full_name"
              color="#ACAFBF"
              placeholder="Masukkan Nama Lengkap PIC"
              style="margin-top: 14px"
            />
          </v-col>
          <!-- Full Name PIC -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Email PIC
            </div>
            <v-text-field
              flat
              outlined
              color="#ACAFBF"
              v-model="form.email"
              placeholder="Masukkan Email PIC"
              style="margin-top: 14px"
            />
          </v-col>
          <!-- Occupation PIC -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Okupasi PIC
            </div>
            <v-text-field
              flat
              outlined
              v-model="form.job_pic"
              color="#ACAFBF"
              placeholder="Masukkan Okupasi PIC"
              style="margin-top: 14px"
            />
          </v-col>
          <!-- Phone Number PIC -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              No. Whatsapp PIC
            </div>
            <v-text-field
              flat
              outlined
              color="#ACAFBF"
              v-model="form.phone_number"
              placeholder="Masukkan No. Whatsapp PIC"
              style="margin-top: 14px"
            >
              <template v-slot:prepend-inner>
                <v-row class="d-flex align-center ml-1 mt-0">
                  <img src="/img/corporate/flag.png" />
                  <div
                    style="
                      color: #575966;
                      font-size: 11px;
                      margin-top: -5px;
                      margin-right: 3px;
                    "
                  >
                    +62
                  </div>
                </v-row>
              </template>
            </v-text-field>
          </v-col>
          <!-- Collaboration Needs -->
          <v-col cols="12" style="margin-bottom: -30px">
            <div class="form-label" style="font-weight: 600; font-size: 14px">
              Kebutuhan Kolaborasi
            </div>
            <v-select
              flat
              outlined
              :items="categories"
              :item-text="'name'"
              :item-value="'value'"
              v-model="form.category"
              color="#ACAFBF"
              placeholder="Pilih Layanan Kolaborasi / Kemitraan"
              style="margin-top: 14px"
            />
          </v-col>
        </v-row>
        <!-- Terms and Condition -->
        <v-row>
          <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
            <template v-slot:label>
              <h5 style="font-size: 12px" class="label-checkbox">
                Dengan mengirim formulir ini saya menyetujui<a
                  href="/term-condition"
                  style="color: #162ca2; text-decoration-line: none"
                >
                  Syarat & Ketentuan </a
                >untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
              </h5>
            </template>
          </v-checkbox>
        </v-row>
        <v-spacer style="height: 36px" />
        <!-- Send -->
        <v-row>
          <v-btn
            width="100%"
            height="50px"
            elevation="0"
            :color="!is_confirm ? '#ACAFBF' : '#162CA2'"
            @click="onSubmit"
            class="form-konsultasi-btn"
          >
            Kirim
          </v-btn>
        </v-row>
      </v-form>
    </div>
    <v-spacer style="height: 60px" />
    <!-- FAQ -->
    <div class="FAQ">
      <!-- Title -->
      <div class="FAQ_Title">Frequently Asked Question</div>
      <!-- Card -->
      <div class="faq">
        <div class="content">
          <div class="row">
            <div class="container">
              <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
            </div>
            <div
              class="btn-stoke-blue cursor-pointer mt-5"
              @click="$router.push('/faq')"
            >
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from "@/components/ilustration/ArrowRight.vue";
import Popup from "@/components/others/Popup.vue";
import ArrowIcon from "@/assets/icons/Arrow.vue";
import BuildIc from "@/assets/icons/corporate/BuildIc.vue";
import BookIc from "@/assets/icons/corporate/BookIc.vue";
import PeopleIc from "@/assets/icons/corporate/PeopleIc.vue";
import PeopleGroupIc from "@/assets/icons/corporate/PeopleGroupIc.vue";
import ArrowLong from "@/assets/icons/ArrowLong.vue";
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  name: "CorporateMobile",
  components: {
    ArrowRight,
    Popup,
    ArrowIcon,
    BuildIc,
    BookIc,
    PeopleIc,
    ArrowLong,
    PeopleGroupIc,
    AccordeonFaq
  },
  data() {
    return {
      partners: [],
      questions: [],
      statistics: [
        {
          img: BuildIc,
          number: "30+",
          desc: "Perusahaan top di Indonesia telah puas terhadap layanan HiColleagues",
        },
        {
          img: BookIc,
          number: "50+",
          desc: "Program pelatihan konstruktif & kredibel untuk kebutuhan perusahaan mitra",
        },
        {
          img: PeopleGroupIc,
          number: "1.000+",
          desc: "Peserta telah menyelesaikan & puas terhadap pelatihan HiColleagues",
        },
        {
          img: PeopleIc,
          number: "100+",
          desc: "Tenaga kerja berkualitas telah ditempatkan di perusahaan mitra ternama",
        },
      ],
      keyFeatures: [
        {
          title: "Corporate Collaboration",
          desc: "Kolaborasi efektif dalam bersinergi dan berinovasi bersama perusahaan mitra dalam mencapai tujuan bisnis melalui program-program unggulan seperti Workshop, Webinar, Mini Bootcamp, Bootcamp, dan General Training yang dirancang untuk kebutuhan masyarakat luas atau segmentasi tertentu.",
          image: "/img/corporate/mask1.png",
          link: "/corporate/collaboration", //
        },
        {
          title: "Corporate Training",
          desc: "Program kemitraan pelatihan dalam pengembangan keterampilan tenaga kerja/karyawan perusahaan mitra melalui Upskilling dan New-Skilling yang tersedia dalam 2 kategori pelatihan yakni General Corporate Training dan Certified Corporate Training.",
          image: "/img/corporate/mask2.png",
          link: "/corporate/training",
        },
        {
          title: "Talent as a Service",
          desc: "Program kemitraan dalam pemenuhan kebutuhan tenaga kerja atau talenta digital perusahaan mitra sebagai langkah efisien dari proses rekrutmen kandidat dan manajemen tenaga kerja yang selaras dengan kebutuhan dan tujuan bisnis yang diinginkan.",
          image: "/img/corporate/mask3.png",
          link: "/corporate/talent-service", //
        },
        {
          title: "Technology Service",
          desc: "Akselerasi pengembangan produk digital perusahaan mitra melalui pemenuhan dan manajemen sumber daya manusia atau talenta digital, implementasi proses pengembangan produk, layanan IT Consulting hingga IT Support sesuai kebutuhan dan tujuan bisnis yang diinginkan.",
          image: "/img/corporate/mask4.png",
          link: "/corporate/services", //
        },
      ],
      whyChooseHiCol: [
        {
          id: 1,
          img: "materi_relevan",
          title: "Akselerasi Pengembangan Perusahaan",
          desc: "Berikan peluang untuk strategi yang tepat dan inovasi terbaru dalam pengembangan profesional yang membantu perjalanan bisnis Anda.",
        },
        {
          id: 2,
          img: "keterlibatan_langsung",
          title: "100% Menyesuaikan Kebutuhan Klien",
          desc: "Pelayanan yang disesuaikan dengan berbagai ukuran tim dan skala bisnis sesuai dengan kebutuhan dan perkembangan seiring waktu.",
        },
        {
          id: 3,
          img: "intensive_program",
          title: "Reputable dan Terpercaya",
          desc: "Pengalaman menghadirkan solusi bagi klien yang membuat kami memiliki sertifikasi yang menjamin kualitas mutu layanan berbagai kebutuhan bisnis.",
        },
        {
          id: 4,
          img: "intensive_program",
          title: "Tim Profesional",
          desc: "Tim profesional dan berdedikasi yang siap memberikan solusi prioritas terhadap kebutuhan spesifik untuk mencapai tujuan efektif bisnis Anda.",
        },
        {
          id: 5,
          img: "profesional_talent",
          title: "Performa Laporan Kinerja Akuntabel",
          desc: "Laporan kinerja kami memberikan gambaran lengkap tentang performa perusahaan mitra secara transparan dan terperinci.",
        },
        {
          id: 6,
          img: "portfolio",
          title: "Pelayanan 24/7",
          desc: "Tim support & manajemen layanan bantuan tersedia selama 24 jam dalam 7 hari untuk melayani setiap kebutuhan perusahaan.",
        },
      ],
      testimony: [],
      categories: [
        {
          name: "Corporate Collaboration",
          value: "COLLABORATION",
        },
        {
          name: "Corporate Training",
          value: "TRAINING",
        },
        {
          name: "Talent as a Service",
          value: "TALENT_SERVICE",
        },
        {
          name: "Tech Service",
          value: "TECH_SERVICE",
        },
      ],
      testimonyModel: 0,
      indicatorValueTestimony: 0,
      sliderIndicatorTestimony: 0,
      listAcceleration: [],
      categoryColor: ["#F1F6FF", "#FAECEB", "#FAF6E7"],
      is_confirm: false,
      alertErrMessage: "",
      isAlertErr: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "",
        web_url: "",
        job_pic: "",
      },
    };
  },
  methods: {
    testimonyNext() {
      if (this.testimonyModel < this.testimony.length - 1) {
        this.testimonyModel += 1;
        this.indicatorValueTestimony += this.sliderIndicatorTestimony + 50;
      }

      if (this.indicatorValueTestimony === 100) {
        return;
      }
    },
    testimonyPrev() {
      if (this.testimonyModel > 0) {
        this.testimonyModel -= 1;
      }

      if (this.indicatorValueTestimony === 0) {
        return;
      }

      this.indicatorValueTestimony -= this.sliderIndicatorTestimony + 50;
    },
    slicedTestimony(index) {
      return [this.testimony[index]];
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=CORPORATE&page=1&per_page=3"
        );
        this.testimony = resp.data.data.testimonies;
        console.log(resp.data.data.testimonies);
      } catch (err) {
        console.log(err);
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=CORPORATE");
        this.partners = resp.data.data.logos;
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    duptlicatePartners() {
      this.partners = this.partners.concat(
        this.partners,
        this.partners,
        this.partners
      );
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false;
      this.form.full_name = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.corporate_name = "";
      this.form.category = "";
      this.form.web_url = "";
      this.form.job_pic = "";
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=CORPORATE&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getMainClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6?is_main=true");
        this.listAcceleration = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya",
        "_blank"
      );
    },
  },
  mounted() {
    this.getFaq();
    this.getMainClass();
    this.getClientLogo();
    this.getTestimoni();
  },
  beforeMount() {
    this.sliderIndicatorTestimony = 37;
  },
};
</script>

<style src="./corporate.css" scoped></style>
