<template>
  <bootcamp-dekstop v-if="!isMobile" />
  <bootcamp-mobile v-else />
</template>
<script>
import BootcampDekstop from '@/components/bootcamp/desktop/Bootcamp.vue';
import BootcampMobile from '@/components/bootcamp/mobile/Bootcamp.vue';
export default {
  name:"Bootcamp",
  components: {
    BootcampDekstop,
    BootcampMobile
  },
  metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.$route.meta.title,
      htmlAttrs: {
        lang: "id",
        amp: true,
      },
      meta: [
        { name:"keywords", content: "Bootcamp,HiColleagues,Bootcamp HiColleagues"},
        { name: "description", content: this.$route.meta.description},
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.$route.meta.title,
            "url": thisPath,
            "potentialAction": {
              "@type": "SearchAction",
              "target": thisPath
            }
          }
        }
      ]
    };
  },
  data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = window.innerWidth <= 990;
      },
    },
    mounted() {
      this.handleView();
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      sessionStorage.setItem('nav-item-active', 0)
    },
}
</script>