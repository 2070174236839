<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 30.3332C8.09329 30.3332 1.66663 23.9065 1.66663 15.9998C1.66663 8.09317 8.09329 1.6665 16 1.6665C23.9066 1.6665 30.3333 8.09317 30.3333 15.9998C30.3333 23.9065 23.9066 30.3332 16 30.3332ZM16 3.6665C9.19996 3.6665 3.66663 9.19984 3.66663 15.9998C3.66663 22.7998 9.19996 28.3332 16 28.3332C22.8 28.3332 28.3333 22.7998 28.3333 15.9998C28.3333 9.19984 22.8 3.6665 16 3.6665Z"
      fill="#36373F"
    />
    <path
      d="M15.9998 24.3328C15.7198 24.3328 15.4531 24.2929 15.2264 24.2129C12.3598 23.2395 7.81311 19.7462 7.81311 14.5462C7.81311 11.7729 10.0531 9.51953 12.8131 9.51953C14.0131 9.51953 15.1198 9.93286 16.0131 10.6795C16.9064 9.93286 18.0264 9.51953 19.1998 9.51953C21.9598 9.51953 24.1998 11.7729 24.1998 14.5462C24.1998 19.7329 19.6531 23.2262 16.7731 24.2129C16.5464 24.2929 16.2664 24.3328 15.9998 24.3328ZM12.7998 11.5328C11.1465 11.5328 9.79978 12.8929 9.79978 14.5595C9.79978 19.2129 14.2931 21.7995 15.8664 22.3328C15.9198 22.3462 16.0531 22.3462 16.1064 22.3328C17.6798 21.7995 22.1731 19.2129 22.1731 14.5595C22.1731 12.8929 20.8264 11.5328 19.1731 11.5328C18.2398 11.5328 17.3464 11.9862 16.7864 12.7462C16.4131 13.2528 15.5598 13.2528 15.1864 12.7462C14.6264 11.9728 13.7598 11.5328 12.7998 11.5328Z"
      fill="#36373F"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartCircle",
  props: {
    width: {
      type: String,
      default: "32",
    },
    height: {
      type: String,
      default: "32",
    },
  },
};
</script>
