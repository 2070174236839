<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.66675 4.79169C6.32508 4.79169 6.04175 4.50835 6.04175 4.16669V1.66669C6.04175 1.32502 6.32508 1.04169 6.66675 1.04169C7.00841 1.04169 7.29175 1.32502 7.29175 1.66669V4.16669C7.29175 4.50835 7.00841 4.79169 6.66675 4.79169Z"
			fill="#36373F" />
		<path
			d="M13.3333 4.79169C12.9916 4.79169 12.7083 4.50835 12.7083 4.16669V1.66669C12.7083 1.32502 12.9916 1.04169 13.3333 1.04169C13.6749 1.04169 13.9583 1.32502 13.9583 1.66669V4.16669C13.9583 4.50835 13.6749 4.79169 13.3333 4.79169Z"
			fill="#36373F" />
		<path
			d="M17.0834 8.19995H2.91675C2.57508 8.19995 2.29175 7.91662 2.29175 7.57495C2.29175 7.23328 2.57508 6.94995 2.91675 6.94995H17.0834C17.4251 6.94995 17.7084 7.23328 17.7084 7.57495C17.7084 7.91662 17.4251 8.19995 17.0834 8.19995Z"
			fill="#36373F" />
		<path
			d="M13.3333 18.9584H6.66667C3.625 18.9584 1.875 17.2084 1.875 14.1667V7.08335C1.875 4.04169 3.625 2.29169 6.66667 2.29169H13.3333C16.375 2.29169 18.125 4.04169 18.125 7.08335V14.1667C18.125 17.2084 16.375 18.9584 13.3333 18.9584ZM6.66667 3.54169C4.28333 3.54169 3.125 4.70002 3.125 7.08335V14.1667C3.125 16.55 4.28333 17.7084 6.66667 17.7084H13.3333C15.7167 17.7084 16.875 16.55 16.875 14.1667V7.08335C16.875 4.70002 15.7167 3.54169 13.3333 3.54169H6.66667Z"
			fill="#36373F" />
		<path
			d="M7.08333 12.0834C6.975 12.0834 6.86666 12.0584 6.76666 12.0167C6.66666 11.975 6.57501 11.9167 6.49167 11.8417C6.41667 11.7584 6.35832 11.6667 6.31666 11.5667C6.27499 11.4667 6.25 11.3584 6.25 11.25C6.25 11.0334 6.34167 10.8167 6.49167 10.6584C6.57501 10.5834 6.66666 10.525 6.76666 10.4834C6.91666 10.4167 7.08334 10.4 7.25001 10.4334C7.30001 10.4417 7.35 10.4584 7.4 10.4834C7.45 10.5 7.5 10.525 7.55 10.5584C7.59166 10.5917 7.63333 10.625 7.67499 10.6584C7.70833 10.7 7.74999 10.7417 7.77499 10.7834C7.80832 10.8334 7.83334 10.8834 7.85001 10.9334C7.87501 10.9834 7.89168 11.0334 7.90001 11.0834C7.90834 11.1417 7.91667 11.1917 7.91667 11.25C7.91667 11.4667 7.82499 11.6834 7.67499 11.8417C7.51666 11.9917 7.3 12.0834 7.08333 12.0834Z"
			fill="#36373F" />
		<path
			d="M10.0001 12.0833C9.78341 12.0833 9.56676 11.9917 9.40842 11.8417C9.37509 11.8 9.34176 11.7583 9.30843 11.7167C9.27509 11.6667 9.25007 11.6167 9.2334 11.5667C9.2084 11.5167 9.19174 11.4667 9.18341 11.4167C9.17507 11.3584 9.16675 11.3083 9.16675 11.25C9.16675 11.1417 9.19174 11.0333 9.2334 10.9333C9.27507 10.8333 9.33342 10.7417 9.40842 10.6584C9.64175 10.425 10.0168 10.35 10.3168 10.4833C10.4251 10.525 10.5084 10.5834 10.5917 10.6584C10.7417 10.8167 10.8334 11.0333 10.8334 11.25C10.8334 11.3083 10.8251 11.3584 10.8168 11.4167C10.8084 11.4667 10.7918 11.5167 10.7668 11.5667C10.7501 11.6167 10.7251 11.6667 10.6917 11.7167C10.6584 11.7583 10.6251 11.8 10.5917 11.8417C10.5084 11.9167 10.4251 11.975 10.3168 12.0167C10.2168 12.0584 10.1084 12.0833 10.0001 12.0833Z"
			fill="#36373F" />
		<path
			d="M7.08333 15C6.975 15 6.86666 14.975 6.76666 14.9333C6.66666 14.8917 6.57501 14.8333 6.49167 14.7583C6.41667 14.675 6.35832 14.5916 6.31666 14.4833C6.27499 14.3833 6.25 14.275 6.25 14.1666C6.25 13.95 6.34167 13.7333 6.49167 13.575C6.57501 13.5 6.66666 13.4416 6.76666 13.4C7.075 13.2666 7.44166 13.3416 7.67499 13.575C7.70833 13.6166 7.74999 13.6583 7.77499 13.7C7.80832 13.75 7.83334 13.8 7.85001 13.85C7.87501 13.9 7.89168 13.95 7.90001 14.0083C7.90834 14.0583 7.91667 14.1166 7.91667 14.1666C7.91667 14.3833 7.82499 14.6 7.67499 14.7583C7.51666 14.9083 7.3 15 7.08333 15Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'CalendarIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
