<template>
  <div class="banner-container">
    <div v-for="(banner, index) in banners" :key="index" class="banner-item">
      <img :src="banner.img_link_url_mobile" alt="" class="banner-img" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BannerMobile',
  props: {
    banners: Array,
    default: () => []
  },
}
</script>

<style src="./css/bannerMobile.css" scoped></style>