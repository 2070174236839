<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99991 18.5416C9.31658 18.5416 8.63324 18.3249 8.06657 17.8999L3.72491 14.6499C2.91658 14.0416 2.43326 13.0749 2.43326 12.0666V1.46655H17.5666V12.0666C17.5666 13.0749 17.0832 14.0416 16.2749 14.6499L11.9333 17.8999C11.3666 18.3249 10.6832 18.5416 9.99991 18.5416ZM3.68326 2.70822V12.0582C3.68326 12.6749 3.98324 13.2666 4.47491 13.6416L8.81657 16.8916C9.51657 17.4166 10.4916 17.4166 11.1916 16.8916L15.5333 13.6416C16.0249 13.2666 16.3249 12.6749 16.3249 12.0582V2.70822H3.68326Z"
      fill="#36373F"
    />
    <path
      d="M18.3333 2.70825H1.66666C1.32499 2.70825 1.04166 2.42492 1.04166 2.08325C1.04166 1.74159 1.32499 1.45825 1.66666 1.45825H18.3333C18.675 1.45825 18.9583 1.74159 18.9583 2.08325C18.9583 2.42492 18.675 2.70825 18.3333 2.70825Z"
      fill="#36373F"
    />
    <path
      d="M13.3334 7.29175H6.66669C6.32502 7.29175 6.04169 7.00841 6.04169 6.66675C6.04169 6.32508 6.32502 6.04175 6.66669 6.04175H13.3334C13.675 6.04175 13.9584 6.32508 13.9584 6.66675C13.9584 7.00841 13.675 7.29175 13.3334 7.29175Z"
      fill="#36373F"
    />
    <path
      d="M13.3334 11.4583H6.66669C6.32502 11.4583 6.04169 11.1749 6.04169 10.8333C6.04169 10.4916 6.32502 10.2083 6.66669 10.2083H13.3334C13.675 10.2083 13.9584 10.4916 13.9584 10.8333C13.9584 11.1749 13.675 11.4583 13.3334 11.4583Z"
      fill="#36373F"
    />
  </svg>
</template>

<script>
export default {
  name: "Bookmark2",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
