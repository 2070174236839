<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4998 18.9583C5.55817 18.9583 1.5415 14.9417 1.5415 9.99999C1.5415 5.05832 5.55817 1.04166 10.4998 1.04166C15.4415 1.04166 19.4582 5.05832 19.4582 9.99999C19.4582 14.9417 15.4415 18.9583 10.4998 18.9583ZM10.4998 2.29166C6.24984 2.29166 2.7915 5.74999 2.7915 9.99999C2.7915 14.25 6.24984 17.7083 10.4998 17.7083C14.7498 17.7083 18.2082 14.25 18.2082 9.99999C18.2082 5.74999 14.7498 2.29166 10.4998 2.29166Z"
      fill="#7B7E8C"
    />
    <path
      d="M4.58337 16.4587C4.42503 16.4587 4.2667 16.4003 4.1417 16.2753C3.90003 16.0337 3.90003 15.6337 4.1417 15.392L15.8084 3.72535C16.05 3.48368 16.45 3.48368 16.6917 3.72535C16.9334 3.96701 16.9334 4.36701 16.6917 4.60868L5.02503 16.2753C4.90003 16.4003 4.7417 16.4587 4.58337 16.4587Z"
      fill="#7B7E8C"
    />
  </svg>
</template>

<script>
export default {
  name: "star-icon",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>
