<template>
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.19 2.29883H7.81C4.17 2.29883 2 4.46883 2 8.10883V16.4788C2 20.1288 4.17 22.2988 7.81 22.2988H16.18C19.82 22.2988 21.99 20.1288 21.99 16.4888V8.10883C22 4.46883 19.83 2.29883 16.19 2.29883ZM7.63 18.4488C7.63 18.8588 7.29 19.1988 6.88 19.1988C6.47 19.1988 6.13 18.8588 6.13 18.4488V16.3788C6.13 15.9688 6.47 15.6288 6.88 15.6288C7.29 15.6288 7.63 15.9688 7.63 16.3788V18.4488ZM12.75 18.4488C12.75 18.8588 12.41 19.1988 12 19.1988C11.59 19.1988 11.25 18.8588 11.25 18.4488V14.2988C11.25 13.8888 11.59 13.5488 12 13.5488C12.41 13.5488 12.75 13.8888 12.75 14.2988V18.4488ZM17.87 18.4488C17.87 18.8588 17.53 19.1988 17.12 19.1988C16.71 19.1988 16.37 18.8588 16.37 18.4488V12.2288C16.37 11.8188 16.71 11.4788 17.12 11.4788C17.53 11.4788 17.87 11.8188 17.87 12.2288V18.4488ZM17.87 9.06883C17.87 9.47883 17.53 9.81883 17.12 9.81883C16.71 9.81883 16.37 9.47883 16.37 9.06883V8.09883C13.82 10.7188 10.63 12.5688 7.06 13.4588C7 13.4788 6.94 13.4788 6.88 13.4788C6.54 13.4788 6.24 13.2488 6.15 12.9088C6.05 12.5088 6.29 12.0988 6.7 11.9988C10.07 11.1588 13.07 9.38883 15.45 6.88883H14.2C13.79 6.88883 13.45 6.54883 13.45 6.13883C13.45 5.72883 13.79 5.38883 14.2 5.38883H17.13C17.17 5.38883 17.2 5.40883 17.24 5.40883C17.29 5.41883 17.34 5.41883 17.39 5.43883C17.44 5.45883 17.48 5.48883 17.53 5.51883C17.56 5.53883 17.59 5.54883 17.62 5.56883C17.63 5.57883 17.63 5.58883 17.64 5.58883C17.68 5.62883 17.71 5.66883 17.74 5.70883C17.77 5.74883 17.8 5.77883 17.81 5.81883C17.83 5.85883 17.83 5.89883 17.84 5.94883C17.85 5.99883 17.87 6.04883 17.87 6.10883C17.87 6.11883 17.88 6.12883 17.88 6.13883V9.06883H17.87Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Level",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  }
}
</script>

<style></style>