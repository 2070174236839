<template>
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.75 3.85889V2.29889C16.75 1.88889 16.41 1.54889 16 1.54889C15.59 1.54889 15.25 1.88889 15.25 2.29889V3.79889H8.74999V2.29889C8.74999 1.88889 8.40999 1.54889 7.99999 1.54889C7.58999 1.54889 7.24999 1.88889 7.24999 2.29889V3.85889C4.54999 4.10889 3.23999 5.71889 3.03999 8.10889C3.01999 8.39889 3.25999 8.63889 3.53999 8.63889H20.46C20.75 8.63889 20.99 8.38889 20.96 8.10889C20.76 5.71889 19.45 4.10889 16.75 3.85889Z" fill="#7B7E8C"/>
    <path d="M20 10.1389H4C3.45 10.1389 3 10.5889 3 11.1389V17.2989C3 20.2989 4.5 22.2989 8 22.2989H16C19.5 22.2989 21 20.2989 21 17.2989V11.1389C21 10.5889 20.55 10.1389 20 10.1389ZM9.21 18.5089C9.11 18.5989 9 18.6689 8.88 18.7189C8.76 18.7689 8.63 18.7989 8.5 18.7989C8.37 18.7989 8.24 18.7689 8.12 18.7189C8 18.6689 7.89 18.5989 7.79 18.5089C7.61 18.3189 7.5 18.0589 7.5 17.7989C7.5 17.5389 7.61 17.2789 7.79 17.0889C7.89 16.9989 8 16.9289 8.12 16.8789C8.36 16.7789 8.64 16.7789 8.88 16.8789C9 16.9289 9.11 16.9989 9.21 17.0889C9.39 17.2789 9.5 17.5389 9.5 17.7989C9.5 18.0589 9.39 18.3189 9.21 18.5089ZM9.42 14.6789C9.37 14.7989 9.3 14.9089 9.21 15.0089C9.11 15.0989 9 15.1689 8.88 15.2189C8.76 15.2689 8.63 15.2989 8.5 15.2989C8.37 15.2989 8.24 15.2689 8.12 15.2189C8 15.1689 7.89 15.0989 7.79 15.0089C7.7 14.9089 7.63 14.7989 7.58 14.6789C7.53 14.5589 7.5 14.4289 7.5 14.2989C7.5 14.1689 7.53 14.0389 7.58 13.9189C7.63 13.7989 7.7 13.6889 7.79 13.5889C7.89 13.4989 8 13.4289 8.12 13.3789C8.36 13.2789 8.64 13.2789 8.88 13.3789C9 13.4289 9.11 13.4989 9.21 13.5889C9.3 13.6889 9.37 13.7989 9.42 13.9189C9.47 14.0389 9.5 14.1689 9.5 14.2989C9.5 14.4289 9.47 14.5589 9.42 14.6789ZM12.71 15.0089C12.61 15.0989 12.5 15.1689 12.38 15.2189C12.26 15.2689 12.13 15.2989 12 15.2989C11.87 15.2989 11.74 15.2689 11.62 15.2189C11.5 15.1689 11.39 15.0989 11.29 15.0089C11.11 14.8189 11 14.5589 11 14.2989C11 14.0389 11.11 13.7789 11.29 13.5889C11.39 13.4989 11.5 13.4289 11.62 13.3789C11.86 13.2689 12.14 13.2689 12.38 13.3789C12.5 13.4289 12.61 13.4989 12.71 13.5889C12.89 13.7789 13 14.0389 13 14.2989C13 14.5589 12.89 14.8189 12.71 15.0089Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Calendar3",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  }
}
</script>

<style></style>