<template>
  <div>
    <div class="sub-banner">
      <div class="card-img">
        <img src="/img/corporate/main.png" alt="" width="100%" />
      </div>
      <h1 class="title-banner font-poppins">
        Tingkatkan Performa Karir melalui Program Akselerasi
      </h1>
      <h3 class="sub-title-banner font-poppins">
        Perluas potensi diri dengan pelatihan konstruktif dan kredibel sebagai
        solusi penunjang profesionalisme karir bersama HiColleagues
        #CiptakanMasaDepan
      </h3>
      <div class="hero-button">
        <v-btn
          depressed
          color="#FFDE59"
          class="button-daftar"
          @click="scrollToClass"
        >
          <span class="text-capitalize font-weight-bold blue-text font-poppins"
            >DAFTAR SEKARANG</span
          >
        </v-btn>
        <v-btn depressed color="white" class="button-daftar" @click="sendToWa">
          <span class="text-capitalize font-weight-bold blue-text font-poppins"
            >KONSULTASI GRATIS</span
          >
        </v-btn>
      </div>
    </div>

    <div class="border-banner-bottom"></div>
    <div
      class="container bg-white-radius"
      style="text-align: center; padding-top: 60px"
    >
      <h3 class="sub-title-span font-poppins">
        HiColleagues telah Dipercaya Perusahaan Ternama
      </h3>
      <div class="mb-10 mt-5 card-bootcamp">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div>
            <img :src="n.image" width="67" height="67" />
          </div>
          <div>
            <div class="title-2">
              {{ n.title }}
            </div>
            <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-of-acceleration">
      <div class="container">
        <h2 class="text-center my-0 mt-4">Program Akselerasi Digital</h2>
        <p class="text-center my-0">
          Rekomendasi program pelatihan untuk akselerasi skill talenta digital
          dan peningkatan performa karir cemerlang
        </p>
        <div class="card" style="padding-bottom: 60px; display: block">
          <div
            class="items"
            v-for="(item, idx) in accelerations"
            :key="idx"
            @click="
              $router.push(`/corporate/acceleration/detail/${item.slug_name}`)
            "
          >
            <div style="height: 240px">
              <img
                :src="item.image_url"
                alt=""
                style="width: 100%; height: 100%; border-radius: 20px 20px 0 0"
              />
              <div class="label">
                <img :src="item.collaboration_feed" alt="" style="width: 80%" />
              </div>
            </div>
            <div class="content">
              <h2>{{ item.name }}</h2>
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.type">
                  <p>{{ item.type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="item.class_type">
                  <p>{{ item.class_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>method</p>
                </div>
              </div>
              <p class="description-class">{{ item.description }}</p>
              <p
                style="color: #b80f0a; font-size: 16px; margin: 0"
                v-if="item.is_discount"
              >
                <del>{{ item.discount | toCurrency }}</del>
              </p>

              <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                {{ item.price | toCurrency }}
              </h3>
              <h3 style="color: #162ca2; margin: 0" v-else>
                {{ toDiscount(item) }}
              </h3>
            </div>

            <div class="due-date">
              <!-- <span style="font-size: 15px">{{
                item.registration_close_date | formatDate
              }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 style="text-align: center">Apa kata Alumni</h2>
    <v-window v-model="testimonyModel" style="padding: 0px 20px">
      <v-window-item
        v-for="index in testimony.length"
        :key="index"
        style="padding: 10px; height: 220px"
      >
        <v-card
          v-for="(data, index) in slicedTestimony(testimonyModel)"
          :key="index"
          style="
            background-color: #ffffff;
            box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
            border-radius: 16px;
            padding: 17px 20px;
            height: 190px;
          "
        >
          <v-row>
            <!-- Message -->
            <v-col cols="12">
              <div class="feedback">“{{ data.feedback }}”</div>
            </v-col>
            <!-- User -->
            <v-col cols="12">
              <v-row>
                <!-- Image -->
                <v-col cols="3">
                  <v-img
                    :src="$utils.loadImage(data.image_url)"
                    width="60"
                    height="60"
                    style="border-radius: 50%"
                  />
                </v-col>
                <!-- Name and Position -->
                <v-col cols="9">
                  <div style="font-size: 12px; font-weight: 700">
                    {{ data.name }}
                  </div>
                  <div style="font-size: 10px">
                    {{ data.job }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
    </v-window>
    <!-- Pagination -->
    <v-row
      style="margin: 0px 10px; padding: 0px 10px"
      class="d-flex align-center"
    >
      <v-col cols="8">
        <div class="slider">
          <div
            class="indicator"
            :style="{
              width: `${sliderIndicatorTestimony}%`,
              transform: `translateX(${indicatorValueTestimony}%)`,
              transition: `transform 0.5s`,
            }"
          ></div>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex"
        style="justify-content: flex-end; gap: 11px"
      >
        <v-btn
          v-for="index in 2"
          fab
          :key="index"
          @click="index === 1 ? testimonyPrev() : testimonyNext()"
          class="btn-mobile-slider-corp"
          elevation="0"
        >
          <Arrow :direct="index === 1 ? 'right' : 'left'" />
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer style="height: 60px" />

    <div
      style="
        background-color: #f1f6ff;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        padding: 40px 26px;
      "
    >
      <!-- Title -->
      <div
        style="
          font-weight: 700;
          font-size: 18px;
          text-align: center;
          margin-bottom: 20px;
          color: #36373f;
        "
      >
        Frequently Asked Question
      </div>
      <!-- Card -->
      <div class="faq" style="margin: 0; padding: 20px 0px">
        <div class="content">
          <div class="row">
            <div class="container">
              <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
            </div>
            <div
              class="btn-stoke-blue cursor-pointer mt-5"
              @click="$router.push('/faq')"
            >
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  name: "AccelerationProgramMobile",
  components: {
    Arrow,
    AccordeonFaq
  },
  data() {
    return {
      benefits: [
        {
          title: "Intensive Program",
          desc: "Durasi program relatif singkat & mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
          image: "/img/bootcamp/ic_intensive_program.png",
        },
        {
          title: "Highly  Relevant Case",
          desc: "Pelatihan berbasis 80% praktik & project based learning terhadap kasus yang sangat relevan dalam perkembangan dunia industri digital terkini",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
          image: "/img/bootcamp/ic_competent_trainer.png",
        },
        {
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
          image: "/img/bootcamp/ic_sertifikat_kompetensi.png",
        },
        {
          title: "Perluas Jangkauan Potensi Karir",
          desc: "Kredibilitas dan sertifikasi yang diperoleh dari program akselerasi dapat membuat peserta menjadi kandidat yang lebih menarik bagi perusahaan-perusahaan ternama.",
          image: "/img/bootcamp/ic_installment.png",
        },
      ],
      accelerations: [
        {
          image_url: "/img/",
          collaboration_feed: "test feed",
          name: "name",
          type: "type",
          class_type: "kelas tipe",
          method: "method",
          description: "description",
          price: 0,
          is_discount: true,
          registration_close_date: "12-10-2024",
        },
      ],
      showDesc: [],
      questions: [],
      testimony: [],
      testimonyModel: 0,
      indicatorValueTestimony: 0,
      sliderIndicatorTestimony: 0,
    };
  },
  methods: {
    testimonyNext() {
      if (this.testimonyModel < this.testimony.length - 1) {
        this.testimonyModel += 1;
        this.indicatorValueTestimony += this.sliderIndicatorTestimony + 50;
      }

      if (this.indicatorValueTestimony === 100) {
        return;
      }
    },
    testimonyPrev() {
      if (this.testimonyModel > 0) {
        this.testimonyModel -= 1;
      }

      if (this.indicatorValueTestimony === 0) {
        return;
      }

      this.indicatorValueTestimony -= this.sliderIndicatorTestimony + 50;
    },
    slicedTestimony(index) {
      return [this.testimony[index]];
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=ACCELERATION&page=1&per_page=3"
        );
        this.testimony = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    async getMainClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6");
        this.accelerations = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    scrollToClass() {
      window.scrollTo(0, 2000);
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=ACCELERATION&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
  },
  mounted() {
    this.getMainClass();
    this.getFaq();
    this.getTestimoni();
  },
  beforeMount() {
    this.sliderIndicatorTestimony = 37;
  },
};
</script>

<style src="./accelerationProgram.css" scoped></style>
