<template>
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 17.0625C5.0525 17.0625 1.4375 13.4475 1.4375 9C1.4375 4.5525 5.0525 0.9375 9.5 0.9375C13.9475 0.9375 17.5625 4.5525 17.5625 9C17.5625 13.4475 13.9475 17.0625 9.5 17.0625ZM9.5 2.0625C5.675 2.0625 2.5625 5.175 2.5625 9C2.5625 12.825 5.675 15.9375 9.5 15.9375C13.325 15.9375 16.4375 12.825 16.4375 9C16.4375 5.175 13.325 2.0625 9.5 2.0625Z" fill="#7B7E8C"/>
    <path d="M12.2825 11.9475C12.185 11.9475 12.0875 11.925 11.9975 11.865L9.67248 10.4775C9.09498 10.1325 8.66748 9.37501 8.66748 8.70751V5.63251C8.66748 5.32501 8.92248 5.07001 9.22998 5.07001C9.53748 5.07001 9.79248 5.32501 9.79248 5.63251V8.70751C9.79248 8.97751 10.0175 9.37501 10.25 9.51001L12.575 10.8975C12.845 11.055 12.9275 11.4 12.77 11.67C12.6575 11.85 12.47 11.9475 12.2825 11.9475Z" fill="#7B7E8C"/>
  </svg>
</template>
<script>
export default {
  name: "Clock2",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
  },
};
</script>
