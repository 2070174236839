<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.9999 6.59148C14.9749 6.59148 14.9583 6.59148 14.9333 6.59148H14.8916C13.3166 6.54148 12.1416 5.32481 12.1416 3.82481C12.1416 2.29147 13.3916 1.0498 14.9166 1.0498C16.4416 1.0498 17.6916 2.29981 17.6916 3.82481C17.6833 5.33314 16.5083 6.54981 15.0083 6.59981C15.0083 6.59147 15.0083 6.59148 14.9999 6.59148ZM14.9166 2.29148C14.0749 2.29148 13.3916 2.97481 13.3916 3.81648C13.3916 4.64148 14.0333 5.30815 14.8583 5.34148C14.8666 5.33315 14.9333 5.33315 15.0083 5.34148C15.8166 5.29981 16.4416 4.63314 16.4499 3.81648C16.4499 2.97481 15.7666 2.29148 14.9166 2.29148Z"
			fill="#36373F" />
		<path
			d="M15.0083 12.7331C14.6833 12.7331 14.3583 12.7081 14.0333 12.6497C13.6916 12.5914 13.4666 12.2664 13.5249 11.9247C13.5833 11.5831 13.9083 11.3581 14.2499 11.4164C15.2749 11.5914 16.3583 11.3997 17.0833 10.9164C17.4749 10.6581 17.6833 10.3331 17.6833 10.0081C17.6833 9.68307 17.4666 9.3664 17.0833 9.10807C16.3583 8.62473 15.2583 8.43307 14.2249 8.6164C13.8833 8.68307 13.5583 8.44973 13.4999 8.10807C13.4416 7.7664 13.6666 7.44141 14.0083 7.38307C15.3666 7.14141 16.7749 7.39973 17.7749 8.0664C18.5083 8.55807 18.9333 9.25807 18.9333 10.0081C18.9333 10.7497 18.5166 11.4581 17.7749 11.9581C17.0166 12.4581 16.0333 12.7331 15.0083 12.7331Z"
			fill="#36373F" />
		<path
			d="M4.9749 6.59169C4.96657 6.59169 4.95824 6.59169 4.95824 6.59169C3.45824 6.54169 2.28324 5.32502 2.2749 3.82502C2.2749 2.29168 3.5249 1.04169 5.0499 1.04169C6.5749 1.04169 7.8249 2.29169 7.8249 3.81669C7.8249 5.32502 6.6499 6.54169 5.1499 6.59169L4.9749 5.96669L5.03324 6.59169C5.01657 6.59169 4.99157 6.59169 4.9749 6.59169ZM5.05824 5.34169C5.10824 5.34169 5.1499 5.34169 5.1999 5.35002C5.94157 5.31669 6.59157 4.65002 6.59157 3.82502C6.59157 2.98335 5.90824 2.30002 5.06657 2.30002C4.2249 2.30002 3.54157 2.98335 3.54157 3.82502C3.54157 4.64168 4.1749 5.30002 4.98324 5.35002C4.99157 5.34169 5.0249 5.34169 5.05824 5.34169Z"
			fill="#36373F" />
		<path
			d="M4.96675 12.7331C3.94175 12.7331 2.95841 12.4581 2.20008 11.9581C1.46675 11.4664 1.04175 10.7581 1.04175 10.0081C1.04175 9.2664 1.46675 8.55807 2.20008 8.0664C3.20008 7.39973 4.60841 7.14141 5.96675 7.38307C6.30841 7.44141 6.53342 7.7664 6.47508 8.10807C6.41675 8.44973 6.09175 8.68307 5.75008 8.6164C4.71675 8.43307 3.62508 8.62473 2.89175 9.10807C2.50008 9.3664 2.29175 9.68307 2.29175 10.0081C2.29175 10.3331 2.50842 10.6581 2.89175 10.9164C3.61675 11.3997 4.70008 11.5914 5.72508 11.4164C6.06675 11.3581 6.39175 11.5914 6.45008 11.9247C6.50842 12.2664 6.28342 12.5914 5.94175 12.6497C5.61675 12.7081 5.29175 12.7331 4.96675 12.7331Z"
			fill="#36373F" />
		<path
			d="M9.99994 12.8171C9.97494 12.8171 9.95827 12.8171 9.93327 12.8171H9.8916C8.3166 12.7671 7.1416 11.5504 7.1416 10.0504C7.1416 8.51706 8.3916 7.27539 9.9166 7.27539C11.4416 7.27539 12.6916 8.52539 12.6916 10.0504C12.6833 11.5587 11.5083 12.7754 10.0083 12.8254C10.0083 12.8171 10.0083 12.8171 9.99994 12.8171ZM9.9166 8.51706C9.07494 8.51706 8.3916 9.2004 8.3916 10.0421C8.3916 10.8671 9.03327 11.5337 9.85827 11.5671C9.8666 11.5587 9.93327 11.5587 10.0083 11.5671C10.8166 11.5254 11.4416 10.8587 11.4499 10.0421C11.4499 9.20873 10.7666 8.51706 9.9166 8.51706Z"
			fill="#36373F" />
		<path
			d="M9.99974 18.9664C8.99974 18.9664 7.99974 18.7081 7.22474 18.1831C6.49141 17.6914 6.06641 16.9914 6.06641 16.2414C6.06641 15.4998 6.48307 14.7831 7.22474 14.2914C8.78307 13.2581 11.2247 13.2581 12.7747 14.2914C13.5081 14.7831 13.9331 15.4831 13.9331 16.2331C13.9331 16.9748 13.5164 17.6914 12.7747 18.1831C11.9997 18.6998 10.9997 18.9664 9.99974 18.9664ZM7.91641 15.3414C7.52474 15.5998 7.31641 15.9248 7.31641 16.2498C7.31641 16.5748 7.53307 16.8914 7.91641 17.1498C9.04141 17.9081 10.9497 17.9081 12.0747 17.1498C12.4664 16.8914 12.6747 16.5664 12.6747 16.2414C12.6747 15.9164 12.4581 15.5998 12.0747 15.3414C10.9581 14.5831 9.04974 14.5914 7.91641 15.3414Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'PeopleIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
