<template>
	<div class="class-list-card" @click="handleClick">
		<img :src="data.thumbnail_url" class="class-list-img-dd" />
		<div class="class-list-content">
			<div class="info-dev">
				<div style="background-color: #f1f6ff">
					<p>{{ data.level }}</p>
				</div>
				<div style="background-color: #faf6e7">
					<p>{{ data.method || 'Online/Offline' }}</p>
				</div>
				<div
					style="background-color: #faeceb"
					v-if="data.count_session">
					<p>{{ data.count_session }} Sesi</p>
				</div>
			</div>
			<div style="height: 45px;">
				<h3 class="name-class">
					{{ data.name }}
				</h3>
			</div>
			<div style="height: 65px;">
			<p class="description-class">
				{{ data.description }}
			</p>
		</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardClassPublicTrainingComponenet',
	props: {
		data: {
			type: Object,
			required: true,
		},
		onClick: {
			type: Function,
			required: false,
		},
	},
	methods: {
		handleClick() {
			this.onClick(this.data.slug_name);
		},
	},
};
</script>

<style src="./css/cardClass.css" scoped></style>
