<template>
  <div style="margin-top: 30px">
    <div class="container">
      <img loading="lazy" :src="portfolio.image_url" class="img-main-dekstop" />
      <h2 class="title-b1">
        {{ portfolio.program }}
      </h2>

      <div class="mt-5 info-head">
        <div>
          <p class="client">
            Clien
          </p>
          <p class="client-val">
            {{ portfolio.title }}
          </p>
        </div>
        <div>
          <p class="timline">
            Timeline
          </p>
          <p class="timeline-val">
            {{$utils.getDate(portfolio.start_date, 'YYYY')}}
          </p>
        </div>
        <div>
          <p class="device">
            Device
          </p>
          <p class="device-val">
            {{ portfolio.device.map(item => item.name).join(", ") }}
          </p>
        </div>
        <div>
          <p class="link">
            Link
          </p>
          <p class="link-val" @click="toRedirect(portfolio.link_project)">{{ portfolio.link_project }}</p>
        </div>
      </div>
      <div>
        <h2 class="detail-proyek">
          Detail Proyek
        </h2>
        <div class="text-justify" v-html="portfolio.body"></div>
      </div>

      <div>
        <h2 class="responbility">
          Tanggung Jawab Kami
        </h2>
        <div class="text-justify" v-html="portfolio.responsibility"></div>
      </div>
      <div v-if="portfolio.scope_project.length">
        <h2 class="linkup-proyek">
          Lingkup Proyek
        </h2>
        <div class="linkup-proyek-list">
          <div v-for="(item, idx) in portfolio.scope_project" :key="idx">
            <div class="pt-5 ctn-b1">
              <img loading="lazy" :src="item.value" :alt="`Gambar secop ${idx + 1}`" style="border-radius: 999px" />
              <p class="ctn-b2">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="tech">
        <h2 >
          Teknologi
        </h2>
        <div style="display: flex; gap: 10px; overflow-x: auto;">
          <div class="mt-5 tech-ctn" v-for="(item, idx) in portfolio.technology" :key="idx">
            <div class="cont">
              <img loading="lazy" :src="item.value" :alt="`Gambar secop ${idx + 1}`" />
            </div>

            <p class="bb">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2 class="dokumentasi">
          Dokumentasi
        </h2>
        <img v-for="(item, idx) in portfolio.key_feature" :key="idx" loading="lazy" class="img-main-dekstop"
          :src="item.img" :alt="`Gambar dokumentasi ${idx + 1}`" />
      </div>


    </div>

    <v-card style="margin-top: 80px" elevation="0" class="call-center-container">
      <v-row style="padding: 20px 8px">
        <v-col cols="12">
          <div class="call-center-title">
            Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam
            Transformasi Digital Berkelanjutan
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan
            konsultasi 24/7 Customer Service HiColleagues
          </div>
        </v-col>
        <v-col cols="12" class="call-center-button" @click="sendWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// import Arrow from "@/assets/icons/Arrow.vue";

export default {
  name: "MobileDetailAlumniStories",
  components: {
    // Arrow,
  },
  data() {
    return {
      portfolio: {
        image_url: "",
        title: "",
        label: "",
        body: "",
        program: "",
        category: "SERVICES",
        start_date: "",
        responsibility: "",
        link_project: "",
        scope_project: [],
        key_feature: [],
        technology: [],
        device: [],
        created_at: ""
      },
    };
  },
  methods: {
    sendWa() {
      window.open("https://wa.me/6281234567890", "_blank");
    },
    toRedirect(to) {
      window.open(to);
    },
    async getPortfolioDetail() {
      try {
        const resp = await this.$http.get(
          `/v1/project-portfolio/${this.$route.params.id}`
        );
        this.portfolio = resp.data.data.project_portfolio;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getPortfolioDetail();
  },
};
</script>
<style scoped src="./detail.css"></style>
