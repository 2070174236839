<template>
  <div>
    <div style="background-color: #162CA2" class="">
      <img src="/img/kampusmerdeka/img-hero.png" width="100%"
        style="border-radius: 0px 0px 0px 120px; margin-top: 50px;" />
      <v-spacer style="height: 25px" />
      <div style="padding-inline: 34px">
        <div class="hero">
          <div style="font-style: italic; color: #ffde59">
            Mulai Karir Cemerlang
          </div>
          melalui Program Magang MSIB
        </div>
        <v-spacer style="height: 25px" />
        <div class="hero-content">
          Kampus Merdeka Cycle 7 merupakan cara terbaik mewujudkan karir di masa depan melalui kemerdekaan belajar
          sesuai bidang yang kamu minati
        </div>
        <v-spacer style="height: 25px" />
        <v-btn class="btn" block style="background-color: #ffde59; color: #162ca2" elevation="0" @click="scrollToClass">
          LIHAT PROGRAM
        </v-btn>
      </div>
      <v-spacer style="height: 40px" />
    </div>
    <div style="background-color: white">

      <div style="
          background-color: #fff;
          border-radius: 26px 26px 0px 0px;
          padding-inline: 34px;
        ">
        <v-spacer style="height: 37px" />
        <h2 class="my-0 text-center">
          Program Magang MSIB HiColleagues
        </h2>
        <p class="my-0 text-center">Awali bidang karir yang kamu minati dengan rekomendasi program-program Magang
          pilihan!</p>
        <div class="list-program-magang">
          <div class="content-mb" v-for="(item, idx) in dataMsib" :key="idx"
            @click="goToMagang(item.registration_link)">
            <img :src="item.img_urls.desktop" alt="Banner" />
            <div class="pa-4">
              <h3 class="name-program font poppins">{{ item.name }}</h3>
              <p style="font-size: 13px; color: gray">
                Digital Acceleration Project
              </p>
              <p class="program-type" style="font-size: 13px; text-align: center">
                {{ item.activity_type | capitalize }}
              </p>
              <p class="body-summary font-poppins">{{ item.summary }}</p>
              <div class="info-more">
                <span class="qouta font poppins">
                  <svg style="padding-bottom: 5px" width="30" height="30" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 10.75C11.2583 10.75 10.5333 10.5301 9.91661 10.118C9.29993 9.70596 8.81928 9.12029 8.53545 8.43506C8.25163 7.74984 8.17736 6.99584 8.32206 6.26841C8.46675 5.54098 8.8239 4.8728 9.34835 4.34835C9.8728 3.8239 10.541 3.46675 11.2684 3.32206C11.9958 3.17736 12.7498 3.25162 13.4351 3.53545C14.1203 3.81928 14.706 4.29993 15.118 4.91661C15.5301 5.5333 15.75 6.25832 15.75 7C15.75 7.99456 15.3549 8.94839 14.6517 9.65165C13.9484 10.3549 12.9946 10.75 12 10.75ZM12 4.75C11.555 4.75 11.12 4.88196 10.75 5.1292C10.38 5.37643 10.0916 5.72783 9.92127 6.13896C9.75098 6.5501 9.70642 7.0025 9.79323 7.43896C9.88005 7.87541 10.0943 8.27632 10.409 8.59099C10.7237 8.90566 11.1246 9.11995 11.561 9.20677C11.9975 9.29359 12.4499 9.24903 12.861 9.07873C13.2722 8.90843 13.6236 8.62005 13.8708 8.25004C14.118 7.88002 14.25 7.44501 14.25 7C14.25 6.40326 14.0129 5.83097 13.591 5.40901C13.169 4.98705 12.5967 4.75 12 4.75Z"
                      fill="#7B7E8C" />
                    <path
                      d="M16.58 20.25H7.41999C6.96159 20.2491 6.51058 20.1344 6.10742 19.9162C5.70425 19.698 5.36153 19.3832 5.10999 19C4.86179 18.6221 4.7117 18.1883 4.67329 17.7379C4.63488 17.2874 4.70935 16.8345 4.88999 16.42C5.49124 15.0338 6.48457 13.8536 7.7478 13.0246C9.01102 12.1956 10.489 11.7539 12 11.7539C13.511 11.7539 14.989 12.1956 16.2522 13.0246C17.5154 13.8536 18.5087 15.0338 19.11 16.42C19.2906 16.8345 19.3651 17.2874 19.3267 17.7379C19.2883 18.1883 19.1382 18.6221 18.89 19C18.6384 19.3832 18.2957 19.698 17.8926 19.9162C17.4894 20.1344 17.0384 20.2491 16.58 20.25ZM12 13.25C10.7822 13.249 9.59048 13.6032 8.57094 14.2693C7.5514 14.9354 6.74829 15.8844 6.25999 17C6.17921 17.187 6.14694 17.3913 6.16616 17.5941C6.18539 17.7969 6.2555 17.9915 6.36999 18.16C6.47881 18.3282 6.62645 18.4678 6.80048 18.567C6.97451 18.6662 7.16983 18.7221 7.36999 18.73H16.53C16.7387 18.7302 16.9441 18.6781 17.1275 18.5786C17.3109 18.479 17.4665 18.3351 17.58 18.16C17.7027 17.996 17.7817 17.8035 17.8097 17.6007C17.8376 17.3978 17.8137 17.1911 17.74 17C17.2517 15.8844 16.4486 14.9354 15.429 14.2693C14.4095 13.6032 13.2178 13.249 12 13.25Z"
                      fill="#7B7E8C" />
                  </svg>

                  {{ item.quota }} Mentee</span>
                <div class="info-more">
                  <CheckIc :width="20" :height="20" />
                  <span class="certificate font poppins">Bersertifikat</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-center mb-5">Apa itu Magang MSIB?</h2>
        <img src="/img/kampusmerdeka/img-magang.png" width="100%" alt="" style="border-radius: 20px" />
        <div class="card-magang">
          <p style="text-align: justify; margin-bottom: 40px">
            Magang Bersertifikat adalah bagian dari program Kampus Merdeka yang
            bertujuan untuk memberikan kesempatan kepada mahasiswa untuk belajar
            dan mengembangkan diri melalui aktivitas di luar kelas perkuliahan
            namun tetap diakui sebagai bagian dari perkuliahan. <br> Di program Magang
            Bersertifikat mahasiswa akan mendapatkan pengalaman kerja di
            industri/profesi nyata selama 1-2 semester. Dengan pembelajaran &
            bertanggung jawab langsung terhadap real project, mahasiswa akan
            mengembangkan hard skills maupun soft skills yang membuat mahasiswa
            lebih siap memasuki dunia kerja dan karirnya.
          </p>
          <button class="btn-magang" @click="redirectToKM">
            PELAJARI LEBIH LANJUT
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.19 11.71C22.1521 11.6207 22.0978 11.5393 22.03 11.47L15.03 4.46997C14.8878 4.33749 14.6998 4.26537 14.5055 4.26879C14.3112 4.27222 14.1258 4.35093 13.9884 4.48835C13.851 4.62576 13.7723 4.81114 13.7688 5.00545C13.7654 5.19975 13.8375 5.38779 13.97 5.52997L19.69 11.25H3.5C3.30109 11.25 3.11032 11.329 2.96967 11.4696C2.82902 11.6103 2.75 11.8011 2.75 12C2.75 12.1989 2.82902 12.3896 2.96967 12.5303C3.11032 12.671 3.30109 12.75 3.5 12.75H19.69L13.97 18.47C13.8295 18.6106 13.7507 18.8012 13.7507 19C13.7507 19.1987 13.8295 19.3893 13.97 19.53C14.1106 19.6704 14.3012 19.7493 14.5 19.7493C14.6988 19.7493 14.8894 19.6704 15.03 19.53L22.03 12.53C22.0978 12.4606 22.1521 12.3792 22.19 12.29C22.2296 12.1984 22.2501 12.0997 22.2501 12C22.2501 11.9002 22.2296 11.8015 22.19 11.71Z"
                fill="white" />
            </svg>
          </button>
        </div>
        <div class="collaboratif">
          <h3 class="text-center my-4">Langkah Mudah Gabung Program Magang MSIB HiColleagues</h3>
          <div class="desc">
            <div class="card-content" v-for="(data, index) in steps" :key="index">
              <div class="card-img">
                <img :src="data.image_url" width="300" alt="" style="margin-top: -20px" />
              </div>
              <div class="desc">
                <h3>{{ data.title }}</h3>
                <p v-html="data.description"></p>
              </div>
            </div>
          </div>
          <div class="indicator-container">
            <div style="width: 10px; height: 10px"></div>
            <div class="indicator">
              <svg :style="{ transform: `translateX(${indicator * 15}px)` }" width="25" height="10" viewBox="0 0 25 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="10" rx="5" fill="#162CA2" />
              </svg>
              <svg :style="{ transform: `translateX(${-indicator2 * 30}px` }" width="10" height="10" viewBox="0 0 10 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
              <svg :style="{ transform: `translateX(${-indicator3 * 30}px` }" width="10" height="10" viewBox="0 0 10 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
            </div>

            <div class="btn-arrow">
              <div class="btn-card">
                <img src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftStep" />
              </div>
              <div class="btn-card">
                <img src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightStep" />
              </div>
            </div>
          </div>
        </div>
        <h2 class="text-center">Kenapa Harus Magang MSIB Bersama HiColleagues?</h2>
        <div v-for="n in whChooseHc" :key="n.id" class="content-card">
          <div class="left-span">
            <img :src="n.img" width="50" height="50" style="border-radius: 50%" />
          </div>
          <div>
            <b class="title-2" style="font-family: Poppins; text-align: left">
              {{ n.name }}
            </b>
            <div class="desc mt-2" style="font-family: Poppins; text-align: left">
              {{ n.desc }}
            </div>
          </div>
        </div>

      </div>
      <div class="linimasa">
        <h2 style="text-align: center; padding-top: 50px; padding-bottom: 15px">
          Linimasa Kegiatan
        </h2>
        <div class="activity-schedule">
          <div v-for="(item, idx) in scheduleMagang" :key="idx" :style="idx != 0 ? `border-top: 1px solid #D3D6E1` : ''"
            class="activity-schedule-ctn">
            <h4 class="font-poppins">{{ item.name }}</h4>
            <div style="font-family: Poppins; font-size: 16px" v-html="item.value"></div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; width: 90%; margin: auto;" class="mt-4">
          <button class="btn-magang" @click="redirectToHicol">
            MAGANG HiCOLLEAGUES
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.19 11.71C22.1521 11.6207 22.0978 11.5393 22.03 11.47L15.03 4.46997C14.8878 4.33749 14.6998 4.26537 14.5055 4.26879C14.3112 4.27222 14.1258 4.35093 13.9884 4.48835C13.851 4.62576 13.7723 4.81114 13.7688 5.00545C13.7654 5.19975 13.8375 5.38779 13.97 5.52997L19.69 11.25H3.5C3.30109 11.25 3.11032 11.329 2.96967 11.4696C2.82902 11.6103 2.75 11.8011 2.75 12C2.75 12.1989 2.82902 12.3896 2.96967 12.5303C3.11032 12.671 3.30109 12.75 3.5 12.75H19.69L13.97 18.47C13.8295 18.6106 13.7507 18.8012 13.7507 19C13.7507 19.1987 13.8295 19.3893 13.97 19.53C14.1106 19.6704 14.3012 19.7493 14.5 19.7493C14.6988 19.7493 14.8894 19.6704 15.03 19.53L22.03 12.53C22.0978 12.4606 22.1521 12.3792 22.19 12.29C22.2296 12.1984 22.2501 12.0997 22.2501 12C22.2501 11.9002 22.2296 11.8015 22.19 11.71Z"
                fill="white" />
            </svg>
          </button>
        </div>
      </div>
      <h2 class="text-center my-0 mt-4">Apa Kata Alumni Kami</h2>
      <v-window v-model="testimonyModel" style="padding: 0px 20px">
        <v-window-item v-for="index in testimony.length" :key="index" style="padding: 10px; height: 220px">
          <v-card v-for="(data, index) in slicedTestimony(testimonyModel)" :key="index" style="
              background-color: #ffffff;
              box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
              border-radius: 16px;
              padding: 17px 20px;
              height: 190px;
            ">
            <div style="height: 100px;">
              <div class="feedback">“{{ data.feedback }}”</div>
            </div>
            <v-row>
              <!-- Image -->
              <v-col cols="3">
                <v-img :src="$utils.loadImage(data.image_url)" width="60" height="60" style="border-radius: 50%" />
              </v-col>
              <!-- Name and Position -->
              <v-col cols="9">
                <div style="font-size: 12px; font-weight: 700">
                  {{ data.name }}
                </div>
                <div style="font-size: 10px">
                  {{ data.job }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
      <!-- Pagination -->
      <v-row style="margin: 0px 10px; padding: 0px 10px" class="d-flex align-center">
        <v-col cols="8">
          <div class="slider">
            <div class="indicator" :style="{
          width: `${sliderIndicatorTestimony}%`,
          transform: `translateX(${indicatorValueTestimony}%)`,
          transition: `transform 0.5s`,
        }"></div>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex" style="justify-content: flex-end; gap: 11px">
          <v-btn v-for="index in 2" fab :key="index" @click="index === 1 ? testimonyPrev() : testimonyNext()"
            class="btn-mobile-slider-corp" elevation="0">
            <Arrow :direct="index === 1 ? 'right' : 'left'" />
          </v-btn>
        </v-col>
      </v-row>
      <div class="faqbg">
        <div class="faq">
          <h1>Frequently Asked Question</h1>
          <div class="container">
            <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
          <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')" style="background-color: white; margin-top: 30px;">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIc from "@/assets/icons/Check.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  name: "KampusMerdekaMobile",
  components: {
    CheckIc,
    Arrow,
    AccordeonFaq,
    ArrowLong
  },
  data() {
    return {
      steps: [
        {
          image_url: "/img/kampusmerdeka/langkah1.png",
          title: "Registrasi Akun Kampus Merdeka",
          description: "Pastikan HiColers telah terdaftar sebagai mahasiswa/i aktif dan memiliki akun pada platform Kampus Merdeka. Kunjungi website <a href='https://kampusmerdeka.kemdikbud.go.id/'>https://kampusmerdeka.kemdikbud.go.id/</a> untuk mendaftar dan melengkapi data-data pribadi yang diperlukan.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah2.png",
          title: "Persiapan Dokumen Administrasi",
          description: "Unggah dokumen wajib sebagai syarat pendaftaran program MSIB seperti Surat Rekomendasi (Universitas), SPTJM, CV, Portofolio, Transkrip Nilai, KTP, serta Sertifikat Pengalaman Organisasi/Prestasi untuk penilaian kelayakan dan verifikasi identitas mahasiswa.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah3.png",
          title: "Melamar di Platform Kampus Merdeka",
          description: "Daftar ke berbagai lowongan mitra melalui platform Kampus Merdeka, memilih program dan mitra perusahaan yang diinginkan, dan mengajukan pendaftaran secara online pada platform.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah4.png",
          title: "Proses Rekrutmen dan Seleksi",
          description: "Seleksi Adminstratif (CV & Portofolio), Test Potensi Akademik, Interview & Praktik Uji Keterampilan merupakan tahapan seleksi sebagai langkah dalam mencari kandidat sesuai kualifikasi.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah5.png",
          title: "Proses Offering Kandidat Mentee",
          description: "Hasil seleksi diumumkan melalui email/situs resmi Kampus Merdeka. Apabila lolos, HiColers mendapatkan penawaran resmi dari Kampus Merdeka & melakukan konfirmasi untuk memulai pelaksanaan program.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah6.png",
          title: "National Onboarding",
          description: "Program orientasi nasional bagi mahasiswa yang telah dinyatakan lolos dan diterima dalam program MSIB Kampus Merdeka. Kegiatan ini mencakup pelatihan, pembekalan materi, serta berbagai informasi penting mengenai proses awal magang.",
        },
        {
          image_url: "/img/kampusmerdeka/langkah7.png",
          title: "Pelaksanaan Program",
          description: "Mentee memulai program magang sesuai jadwal yang telah ditentukan dan ditempatkan sesuai departemen yang telah dipilih, sehingga dapat berkontribusi dalam mengerjakan rill project, berkolaborasi & dibimbing oleh mentor berpengalaman.",
        },
      ],
      questions: [],
      testimonies: [],
      scheduleMagang: [
        {
          name: "26 April - 10 Juni 2024",
          value:
            "<h4>Pendaftaran Magang</h4>Mahasiswa melakukan pendaftaran ke program yang diminati melalui platform <a href='https://kampusmerdeka.kemdikbud.go.id/' class='font-poppins' style='color: #132791'>Kampus Merdeka</a> dan mengumpulkan dokumennya secara lengkap pada saat pendaftaran",
        },
        {
          name: "26 April - 12 Juni 2024",
          value:
            "<h4>Verifikasi Dokumen Mahasiswa oleh Perguruan Tinggi</h4>Dokumen mahasiswa harus diverifikasi oleh PIC Prodi/Fakultas dan direkomendasikan Pimpinan Perguruan Tinggi untuk dapat mengikuti MSIB",
        },
        {
          name: "13 Juni - 12 Juli 2024",
          value:
            "<h4>Proses Seleksi Mahasiswa</h4>Mitra menyeleksi Mahasiswa dan melakukan offering pada platform, pastikan Anda mengecek email dan nomor handphone secara berkala",
        },
        {
          name: "6 Agustus - 20 Desember 2024",
          value: "<h4>Pelaksanaan Program</h4>",
        },
      ],
      benefitMsib: [
        {
          img: "/img/kampusmerdeka/grow_up.png",
          name: "Keberlanjutan Karir",
          desc: "Peluang yang lebih besar untuk diterima sebagai karyawan di tempat magang",
        },
        {
          img: "/img/kampusmerdeka/puzzel.png",
          name: "Keterlibatan Langsung",
          desc: "Pengalaman kerja yang berharga untuk digunakan setelah lulus dari Universitas",
        },
        {
          img: "/img/kampusmerdeka/work.png",
          name: "Gambaran Nyata Dunia Kerja",
          desc: "Pengetahuan tentang praktik terbaik dalam Industri dan sektor yang diminati",
        },
        {
          img: "/img/kampusmerdeka/connent.png",
          name: "Bangun & Perluas Koneksi",
          desc: "Jaringan & hubungan dalam industri tempat magang",
        },
      ],
      indicator: 0,
      indicator2: 0,
      indicator3: 0,
      whChooseHc: [
        {
          img: "/img/kampusmerdeka/img-msib-1.png",
          name: "Keterlibatan Langsung dalam Proyek Nyata",
          desc: "Mentee terlibat langsung dalam perancangan proyek aktif perusahaan, merupakan sarana yang baik dalam implikatif keterampilan dan berproses melalui pengalaman nyata.",
        },
        {
          img: "/img/kampusmerdeka/img-msib-2.png",
          name: "Mentor berpengalaman",
          desc: "Program dibimbing oleh Project Leads sebagai mentor yang memiliki pengalaman dan masif dalam mengembangkan hingga berkolaborasi dengan tim.",
        },
        {
          img: "/img/kampusmerdeka/img-msib-3.png",
          name: "Habbit Kerja Positif",
          desc: "Berkomitmen dalam membangun team work dan suasana kerja yang positif, serta manajemen kerja yang terstruktur sehingga baik untuk Mentee dalam berproses & tumbuh bersama.",
        },
        {
          img: "/img/kampusmerdeka/img-msib-3.png",
          name: "Portfolio Building",
          desc: "Membangun dan mengembangkan portfolio yang memiliki nilai lebih dari real case & real project sebagai bekal dalam karir.",
        },
        {
          img: "/img/kampusmerdeka/img-msib-4.png",
          name: "Bangun dan Perluas Koneksi",
          desc: "Membangun jaringan dan memperluas koneksi dalam keberlangsungan program baik secara linier maupun cross linier sebagai bekal memasuki industri kerja nyata.",
        },
        {
          img: "/img/kampusmerdeka/img-msib-5.png",
          name: "Career Path Consultation",
          desc: "Ruang terbuka untuk Mentee konsultasi dan mencari tahu seputar peluang karir hingga keadaan industri terkini baik dengan Mentor maupun figur lain dalam perusahaan.",
        },
      ],
      dataMsib: [],
      testimonyModel: 0,
      indicatorValueTestimony: 0,
      sliderIndicatorTestimony: 0,
      testimony: [],
    };
  },
  methods: {
    goToMagang(link) {
      window.open(link)
    },
    redirectToKM() {
      window.open("https://kampusmerdeka.kemdikbud.go.id/program/magang/detail")
    },
    redirectToHicol() {
      window.open("https://kampusmerdeka.kemdikbud.go.id/profile/mitra/5d36e167-295a-45aa-b8b3-8abc02c96033/21b11866-f0dc-11ee-9496-32b7bdeb41bc")
    },
    scrollToClass() {
      window.scrollTo(0, 600);
    },
    testimonyNext() {
      if (this.testimonyModel < this.testimony.length - 1) {
        this.testimonyModel += 1;
        this.indicatorValueTestimony += this.sliderIndicatorTestimony + 50;
      }

      if (this.indicatorValueTestimony === 100) {
        return;
      }
    },
    testimonyPrev() {
      if (this.testimonyModel > 0) {
        this.testimonyModel -= 1;
      }

      if (this.indicatorValueTestimony === 0) {
        return;
      }

      this.indicatorValueTestimony -= this.sliderIndicatorTestimony + 50;
    },
    slicedTestimony(index) {
      return [this.testimony[index]];
    },
    scrollRightStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft += cardWidth * 1.07;
      if (this.indicator < 2) {
        this.indicator++;
        if (this.indicator2 < 1) {
          this.indicator2++;
        }
      }
      if (this.indicator === 2 && this.indicator3 < 1) {
        this.indicator3++;
      }
    },
    scrollLeftStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft -= cardWidth * 1.07;
      if (this.indicator === 1) {
        this.indicator2--;
      }
      if (this.indicator > 0) {
        this.indicator--;
      }
      if (this.indicator3 < 2 && this.indicator > 0) {
        this.indicator3--;
      }
    },
    async getDataMsib() {
      try {
        const resp = await this.$http.get("/v1/kampus-merdeka?program_type=MB");
        this.dataMsib = resp.data.data.kampus_merdekas;
      } catch (error) {
        console.log(error);
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=KAMPUS_MERDEKA&page=1&per_page=9"
        );
        this.testimony = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get("/v1/faq?category=KAMPUS_MERDEKA");
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    this.getDataMsib();
    this.getTestimoni();
    this.getFaq();
    this.sliderIndicatorTestimony = 37;

  },
};
</script>

<style src="./kampusMerdeka.css" scoped></style>
