<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 18.9583C9.44172 18.9583 8.87505 18.8166 8.37505 18.5249L3.42505 15.6666C2.42505 15.0833 1.80005 14.0083 1.80005 12.8499V7.14997C1.80005 5.99164 2.42505 4.91664 3.42505 4.3333L8.37505 1.47498C9.37505 0.891642 10.6167 0.891642 11.625 1.47498L16.575 4.3333C17.575 4.91664 18.2 5.99164 18.2 7.14997V12.8499C18.2 14.0083 17.575 15.0833 16.575 15.6666L11.625 18.5249C11.125 18.8166 10.5584 18.9583 10 18.9583ZM10 2.29163C9.65838 2.29163 9.30838 2.3833 9.00005 2.5583L4.05005 5.41663C3.43338 5.77496 3.05005 6.43331 3.05005 7.14997V12.8499C3.05005 13.5583 3.43338 14.225 4.05005 14.5833L9.00005 17.4416C9.61672 17.8 10.3834 17.8 11 17.4416L15.95 14.5833C16.5667 14.225 16.95 13.5666 16.95 12.8499V7.14997C16.95 6.44164 16.5667 5.77496 15.95 5.41663L11 2.5583C10.6917 2.3833 10.3417 2.29163 10 2.29163Z"
			fill="#36373F" />
		<path
			d="M10 9.79151C8.58335 9.79151 7.43335 8.6415 7.43335 7.22483C7.43335 5.80817 8.58335 4.6582 10 4.6582C11.4167 4.6582 12.5667 5.80817 12.5667 7.22483C12.5667 8.6415 11.4167 9.79151 10 9.79151ZM10 5.9082C9.27502 5.9082 8.68335 6.49983 8.68335 7.22483C8.68335 7.94983 9.27502 8.54151 10 8.54151C10.725 8.54151 11.3167 7.94983 11.3167 7.22483C11.3167 6.49983 10.725 5.9082 10 5.9082Z"
			fill="#36373F" />
		<path
			d="M13.3334 14.5086C12.9917 14.5086 12.7084 14.2253 12.7084 13.8836C12.7084 12.7336 11.4917 11.792 10.0001 11.792C8.50841 11.792 7.29175 12.7336 7.29175 13.8836C7.29175 14.2253 7.00841 14.5086 6.66675 14.5086C6.32508 14.5086 6.04175 14.2253 6.04175 13.8836C6.04175 12.0419 7.81675 10.542 10.0001 10.542C12.1834 10.542 13.9584 12.0419 13.9584 13.8836C13.9584 14.2253 13.6751 14.5086 13.3334 14.5086Z"
			fill="#36373F" />
	</svg>
</template>

<script>
export default {
	name: 'UserOctagonIcon',
	props: {
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
};
</script>
