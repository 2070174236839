<template>
    <div class="pagination">
      <button
        @click="changePage(currentPage - 1)"
        style="color: #575966; display: flex; align-items: center"
      >
        <arrow strokeColor="#575966" direct="right" /> Sebelumnya
      </button>
      <div v-if="currentPage > 2">
        <button @click="changePage(1)">1</button>
        <button>&hellip;</button>
      </div>
      <button
        v-for="page in visiblePages"
        :key="page"
        :class="{ active: currentPage === page }"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
      <div v-if="currentPage < totalPages - 1">
        <button v-if="shouldShowDots">&hellip;</button>
        <button @click="changePage(totalPages)">{{ totalPages }}</button>
      </div>
  
      <button
        @click="changePage(currentPage + 1)"
        style="color: #575966; display: flex; align-items: center"
      >
        Selanjutnya <arrow strokeColor="#575966" direct="left" />
      </button>
    </div>
  </template>
  
  <script>
  import Arrow from "@/assets/icons/Arrow.vue";
  export default {
    components: {
      Arrow,
    },
    props: {
      currentPage: {
        type: Number,
        required: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
    },
    computed: {
      visiblePages() {
        if (this.totalPages <= 3) {
          return this.pages;
        }
  
        if (this.currentPage <= 2) {
          return [1, 2, 3];
        } else if (this.currentPage >= this.totalPages - 1) {
          return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
        } else {
          return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
        }
      },
      shouldShowDots() {
        return this.totalPages > 3 && this.visiblePages.length < this.totalPages;
      },
      pages() {
        const pages = [];
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
        return pages;
      },
    },
    methods: {
      changePage(page) {
        if (page > this.totalPages) return;
        if (page < 1) return;
        this.$emit("pageChanged", page);
      },
    },
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .pagination button {
    background-color: #ffffff;
    border: none;
    color: #36373f;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.25rem;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .pagination button.active {
    background-color: #162ca2;
    color: white;
    border-radius: 8px;
  }
  </style>
  