<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9998 7.90977C17.9698 7.90977 17.9498 7.90977 17.9198 7.90977H17.8698C15.9798 7.84977 14.5698 6.38977 14.5698 4.58977C14.5698 2.74977 16.0698 1.25977 17.8998 1.25977C19.7298 1.25977 21.2298 2.75977 21.2298 4.58977C21.2198 6.39977 19.8098 7.85977 18.0098 7.91977C18.0098 7.90977 18.0098 7.90977 17.9998 7.90977ZM17.8998 2.74977C16.8898 2.74977 16.0698 3.56977 16.0698 4.57977C16.0698 5.56977 16.8398 6.36977 17.8298 6.40977C17.8398 6.39977 17.9198 6.39977 18.0098 6.40977C18.9798 6.35977 19.7298 5.55977 19.7398 4.57977C19.7398 3.56977 18.9198 2.74977 17.8998 2.74977Z"
      fill="#36373F"
    />
    <path
      d="M18.01 15.2797C17.62 15.2797 17.23 15.2497 16.84 15.1797C16.43 15.1097 16.16 14.7197 16.23 14.3097C16.3 13.8997 16.69 13.6297 17.1 13.6997C18.33 13.9097 19.63 13.6797 20.5 13.0997C20.97 12.7897 21.22 12.3997 21.22 12.0097C21.22 11.6197 20.96 11.2397 20.5 10.9297C19.63 10.3497 18.31 10.1197 17.07 10.3397C16.66 10.4197 16.27 10.1397 16.2 9.72966C16.13 9.31966 16.4 8.92966 16.81 8.85966C18.44 8.56966 20.13 8.87965 21.33 9.67965C22.21 10.2697 22.72 11.1097 22.72 12.0097C22.72 12.8997 22.22 13.7497 21.33 14.3497C20.42 14.9497 19.24 15.2797 18.01 15.2797Z"
      fill="#36373F"
    />
    <path
      d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z"
      fill="#36373F"
    />
    <path
      d="M5.96 15.2797C4.73 15.2797 3.55 14.9497 2.64 14.3497C1.76 13.7597 1.25 12.9097 1.25 12.0097C1.25 11.1197 1.76 10.2697 2.64 9.67965C3.84 8.87965 5.53 8.56966 7.16 8.85966C7.57 8.92966 7.84 9.31966 7.77 9.72966C7.7 10.1397 7.31 10.4197 6.9 10.3397C5.66 10.1197 4.35 10.3497 3.47 10.9297C3 11.2397 2.75 11.6197 2.75 12.0097C2.75 12.3997 3.01 12.7897 3.47 13.0997C4.34 13.6797 5.64 13.9097 6.87 13.6997C7.28 13.6297 7.67 13.9097 7.74 14.3097C7.81 14.7197 7.54 15.1097 7.13 15.1797C6.74 15.2497 6.35 15.2797 5.96 15.2797Z"
      fill="#36373F"
    />
    <path
      d="M11.9998 15.3805C11.9698 15.3805 11.9498 15.3805 11.9198 15.3805H11.8698C9.97982 15.3205 8.56982 13.8605 8.56982 12.0605C8.56982 10.2205 10.0698 8.73047 11.8998 8.73047C13.7298 8.73047 15.2298 10.2305 15.2298 12.0605C15.2198 13.8705 13.8098 15.3305 12.0098 15.3905C12.0098 15.3805 12.0098 15.3805 11.9998 15.3805ZM11.8998 10.2205C10.8898 10.2205 10.0698 11.0405 10.0698 12.0505C10.0698 13.0405 10.8398 13.8405 11.8298 13.8805C11.8398 13.8705 11.9198 13.8705 12.0098 13.8805C12.9798 13.8305 13.7298 13.0305 13.7398 12.0505C13.7398 11.0505 12.9198 10.2205 11.8998 10.2205Z"
      fill="#36373F"
    />
    <path
      d="M11.9998 22.7597C10.7998 22.7597 9.59978 22.4497 8.66978 21.8197C7.78978 21.2297 7.27979 20.3897 7.27979 19.4897C7.27979 18.5997 7.77978 17.7397 8.66978 17.1497C10.5398 15.9097 13.4698 15.9097 15.3298 17.1497C16.2098 17.7397 16.7198 18.5797 16.7198 19.4797C16.7198 20.3697 16.2198 21.2297 15.3298 21.8197C14.3998 22.4397 13.1998 22.7597 11.9998 22.7597ZM9.49979 18.4097C9.02979 18.7197 8.77979 19.1097 8.77979 19.4997C8.77979 19.8897 9.03979 20.2697 9.49979 20.5797C10.8498 21.4897 13.1398 21.4897 14.4898 20.5797C14.9598 20.2697 15.2098 19.8797 15.2098 19.4897C15.2098 19.0997 14.9498 18.7197 14.4898 18.4097C13.1498 17.4997 10.8598 17.5097 9.49979 18.4097Z"
      fill="#36373F"
    />
  </svg>
</template>
<script>
export default {
  name: "MapIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
