import moment from "moment";

export default {
  loadImage(image) {
    if (image && image != "-") return image
    return '/img/other/ic_profile.png'
  },
  paginate(totalData, itemsPerPage) {
    const numPages = Math.ceil(totalData / itemsPerPage);
    const pages = [];

    for (let i = 0; i < numPages; i++) {
      const start = i * itemsPerPage;
      const end = Math.min(start + itemsPerPage, totalData);
      pages.push({ page: i + 1, start: start, end: end });
    }

    return pages[pages.length - 1].page;
  },
  scrollTop() {
    window.scrollTo(0, 0)
  },
  getMonthDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let months = ((end.getFullYear() - start.getFullYear()) * 12) + (end.getMonth() - start.getMonth());
    let days = end.getDate() - start.getDate();

    if (days < 0) {
      months--;
      days += new Date(end.getFullYear(), end.getMonth(), 0).getDate(); // Tambah hari dari bulan sebelumnya
    }
    return `${months} Bulan ${days} Hari`
  },
  formatPrice(number) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number).replace(',00', '');
  },
  toMethode(value) {
    if (!value) return
    if (value.toLowerCase() === "online") {
      return "Daring (via Zoom)"
    } else if (value.toLowerCase() === "offline") {
      return "Luring"
    } else {
      return "Online / Offline"
    }
  },
  formatDate(date) {
    return moment(date).utc().format("dddd, DD/MM/YYYY");
  },
  getDate(date, format) {
    if (!format) format = "DD-MM-YYYY"
    return moment(date).utc().format(format);
  },
  calculateDuration(start_date, end_date) {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    // Menghitung selisih bulan dan hari
    let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    let remainingDays = endDate.getDate() - startDate.getDate();

    // Jika hari negatif, kurangi 1 bulan dan tambahkan jumlah hari di bulan sebelumnya
    if (remainingDays < 0) {
      totalMonths--;
      const previousMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 0); // Hari terakhir bulan sebelumnya
      remainingDays += previousMonth.getDate();
    }

    return `${totalMonths} Bulan, ${remainingDays} Hari`

  },
  redirect(link) {
    window.open(link)
  }
}