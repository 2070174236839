<template>
  <div style="margin-top: 50px">
    <!-- Hero -->
    <div class="faq-hero">
      <!-- Text -->
      <div class="faq-hero-sec">
        <!-- Title -->
        <div style="font-weight: 700; font-size: 30px">
          FREQUENTLY ASKED QUESTION
        </div>
        <!-- Description -->
        <div style="font-weight: 400; font-size: 13px">
          Temukan beragam informasi yang kamu butuhkan seputar program HiColleagues di sini!
        </div>
      </div>

    </div>
    <div style="background-color: #F5F6F9;">
    <v-row class="container" style="margin: 0 !important">
      <v-col>
        <div style="gap: 10px; display: flex; overflow-x: auto;">
          <v-chip v-for="(item, idx) in program" :key="idx" :class="[
            selectedProgram == idx ? 'sub-program-active' : '',
            'sub-program',
          ]" @click="changeProgram(idx, item.key)">{{ item.name }}</v-chip>
        </div>
        <h4 class="text-center my-5" v-if="program[selectedProgram].sub_program">Sub menu</h4>
        <div v-if="program[selectedProgram].sub_program" style="gap: 10px; display: flex; overflow-x: auto;">
          <v-chip v-for="(item, idx) in program[selectedProgram].data" :key="idx" :class="[
            selectedSubProgram == idx ? 'sub-program-active' : '',
            'sub-program',
          ]" @click="changeSubProgram(idx, item.key)">{{ item.name }}</v-chip>
        </div>
        <div v-if="!program[selectedProgram].sub_program" style="margin-top: 30px;">
        <AccordeonFaq
            v-for="(item, index) in dataFaq"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
        </div>
        <!-- with sub program -->
         <div  v-if="program[selectedProgram].sub_program" style="margin-top: 30px" >
        <AccordeonFaq
            v-for="(item, index) in dataFaq"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
        </div>
        <empty-data v-if="!dataFaq.length" message="Belum ada faq saat ini" />
      </v-col>
    </v-row>
  </div>
    <!-- Call Center -->
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 20px 8px">
        <v-col cols="12">
          <div class="call-center-title">
            Belum Menemukan Jawaban Seputar Program HiColleagues?
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan 24/7 Customer Service HiColleagues
          </div>
        </v-col>
        <v-col cols="12" class="call-center-button" @click="sendToWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import EmptyData from "../../others/EmptyData.vue";
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  components: {
    EmptyData,
    AccordeonFaq
  },
  data() {
    return {
      selectedProgram: 0,
      selectedSubProgram: 0,
      dataFaq: [],
      program: [
        {
          name: "Umum",
          key: "HOME",
          sub_program: false,
        },
        {
          name: "Bootcamp",
          key: "BOOTCAMP",
          sub_program: false,
        },
        // {
        //   name: "Pelatihan Umum",
        //   key: "PUBLIC_TRAINING",
        //   sub_program: false,
        // },
        // {
        //   name: "Prakerja",
        //   key: "PRAKERJA_UMUM",
        //   sub_program: true,
        //   data: [
        //     {
        //       name: "Umum",
        //       key: "PRAKERJA_UMUM",
        //     },
        //     {
        //       name: "Kelas Webinar",
        //       key: "PRAKERJA_WEBINAR",
        //     },
        //   ],
        // },
        {
          name: "Corporate Service",
          sub_program: true,
          key: "CORPORATE",
          data: [
            {
              name: "Umum",
              key: "CORPORATE",
            },
            {
              name: "Corp Collaboration",
              key: "COLLABORATION",
            },
            {
              name: "Acceleration Program",
              key: "ACCELERATION",
            },
            {
              name: "Corp Training",
              key: "TRAINING",
            },
            {
              name: "Talent as a Service",
              key: "TALENT_SERVICE",
            },
            {
              name: "Technology Service",
              key: "SERVICES",
            },
          ],
        },
        // {
        //   name: "Event",
        //   sub_program: false,
        //   key: "EVENT"
        // },
        {
          name: "Kampus Merdeka",
          sub_program: true,
          key: "KAMPUS_MERDEKA",
          data: [
            {
              name: "Umum",
              key: "KAMPUS_MERDEKA",
            },
            {
              name: "Magang Bersertifikat",
              key: "MAGANG"
            },
          ],
        },
      ],
    };
  },
  methods: {
    sendToWa() {
      window.open("https://wa.me/6281234567890", "_blank");
    },
    async getFaqByName(name) {
      try {
        const resp = await this.$http.get(
          `/v1/faq?category=${name}&is_main=false`
        );
        this.dataFaq = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    toggleAccordion(index) {
      this.dataFaq[index].active = !this.dataFaq[index].active;
    },
    changeProgram(idx, key) {
      this.selectedProgram = idx;
      this.selectedSubProgram = 0;
      this.getFaqByName(key)
    },
    changeSubProgram(idx, key) {
      this.selectedSubProgram = idx;
      this.getFaqByName(key)
    },
  },
  mounted() {
    this.getFaqByName("HOME")
  }
};
</script>

<style src="./faq.css" scoped></style>