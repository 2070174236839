<template>    
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2.29883C6.38 2.29883 4.25 4.42883 4.25 7.04883C4.25 9.61883 6.26 11.6988 8.88 11.7888C8.96 11.7788 9.04 11.7788 9.1 11.7888C9.12 11.7888 9.13 11.7888 9.15 11.7888C9.16 11.7888 9.16 11.7888 9.17 11.7888C11.73 11.6988 13.74 9.61883 13.75 7.04883C13.75 4.42883 11.62 2.29883 9 2.29883Z" fill="#7B7E8C"/>
    <path d="M14.08 14.4488C11.29 12.5888 6.74002 12.5888 3.93002 14.4488C2.66002 15.2988 1.96002 16.4488 1.96002 17.6788C1.96002 18.9088 2.66002 20.0488 3.92002 20.8888C5.32002 21.8288 7.16002 22.2988 9.00002 22.2988C10.84 22.2988 12.68 21.8288 14.08 20.8888C15.34 20.0388 16.04 18.8988 16.04 17.6588C16.03 16.4288 15.34 15.2888 14.08 14.4488Z" fill="#7B7E8C"/>
    <path d="M19.99 7.63881C20.15 9.57881 18.77 11.2788 16.86 11.5088C16.85 11.5088 16.85 11.5088 16.84 11.5088H16.81C16.75 11.5088 16.69 11.5088 16.64 11.5288C15.67 11.5788 14.78 11.2688 14.11 10.6988C15.14 9.77881 15.73 8.39881 15.61 6.89881C15.54 6.08881 15.26 5.34881 14.84 4.71881C15.22 4.52881 15.66 4.40881 16.11 4.36881C18.07 4.19881 19.82 5.65881 19.99 7.63881Z" fill="#7B7E8C"/>
    <path d="M21.9902 16.8892C21.9102 17.8592 21.2902 18.6992 20.2502 19.2692C19.2502 19.8192 17.9902 20.0792 16.7402 20.0492C17.4602 19.3992 17.8802 18.5892 17.9602 17.7292C18.0602 16.4892 17.4702 15.2992 16.2902 14.3492C15.6202 13.8192 14.8402 13.3992 13.9902 13.0892C16.2002 12.4492 18.9802 12.8792 20.6902 14.2592C21.6102 14.9992 22.0802 15.9292 21.9902 16.8892Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "Participants2",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
  },
};
</script>