<template>
  <div class="partner">
    <div class="partner-title text-center">
      <h1>{{ title }}</h1>
    </div>
    <div class="partner-content">
      <div class="partner-container">
        <div class="card col-12 cursor-pointer" v-for="(partner, index) in partners" :key="index"
          @click="directive(partner.link_url)">
          <div class="portfolio-card-dekstop">
            <img :src="partner.img_link_url_web" style="max-width: 100%; border-radius: 34px;" />
          </div>
          <div class="portfolio-card-mobile">
            <img :src="partner.img_link_url_mobile" style="max-width: 100%; border-radius: 10px 80px 10px 10px;" />
          </div>
        </div>
      </div>
    </div>
    <div class="partner-button">
      <div class="slider">
        <div class="indicator" :style="{
          width: `${sliderIndicatorTesti}%`,
          transform: `translateX(${indicatorValueTesti}%)`,
          transition: `transform 0.5s`,
        }"></div>
      </div>
      <div class="menu-bottom">
        <img class="arrow-button" src="/img/other/corporate-arrow-left.png" alt="" ref="scrollLeft"
          @click="scrollLeftPartner" />
        <img class="arrow-button" src="/img/other/corporate-arrow-right.png" alt="" ref="scrollRight"
          @click="scrollRightPartner" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerComponent",
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_left: true,
      isScrolling: false,
      sliderIndicatorTesti: 0,
      indicatorValueTesti: 0,
    };
  },
  watch: {
    partners() {
      this.sliderIndicatorTesti = (1 / this.partners.length) * 100;
      if (this.sliderIndicatorTesti >= 100) {
        this.sliderIndicatorTesti = 100;
      }
    },
  },
  methods: {
    scrollRightPartner() {
      if (this.isScrolling) return;
      this.isScrolling = true;
      
      const container = this.$el.querySelector(".partner-container");
      const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;

      container.scrollBy({
        left: cardWidth,
        behavior: "smooth",
      });

      setTimeout(() => {
        this.indicatorValueTesti += this.sliderIndicatorTesti + ((this.partners.length - 1) * 100) / this.partners.length;
        this.is_left = true;

        if (this.indicatorValueTesti >= (this.partners.length - 1) * 100) this.indicatorValueTesti = (this.partners.length - 1) * 100;
        this.isScrolling = false;

      }, 700);
    },
    scrollLeftPartner() {
      if (this.isScrolling) return;
      this.isScrolling = true;

      const container = this.$el.querySelector(".partner-container");
      const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;

      container.scrollBy({
        left: -cardWidth,
        behavior: "smooth",
      });

      setTimeout(() => {
        this.indicatorValueTesti -= this.sliderIndicatorTesti + ((this.partners.length - 1) * 100) / this.partners.length;
        this.is_left = false;

        if (this.indicatorValueTesti <= 0) this.indicatorValueTesti = 0;
        this.isScrolling = false;
      }, 700);
    },
    autoScroll() {
      setInterval(() => {
        if (this.indicatorValueTesti >= (this.partners.length - 1) * 100) this.is_left = false;
        else if (this.indicatorValueTesti <= 0) this.is_left = true;

        if (this.is_left) this.scrollRightPartner();
        else this.scrollLeftPartner();
      }, 8000);
    },
    directive(link) {
      if (link && link != "-") window.open(link)
    }
  },
  mounted() {
    this.autoScroll()
  }
};
</script>

<style src="./css/banner.css" scoped></style>