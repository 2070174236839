import { render, staticRenderFns } from "./CorporateCollaborationMobile.vue?vue&type=template&id=235d7afc&scoped=true&"
import script from "./CorporateCollaborationMobile.vue?vue&type=script&lang=js&"
export * from "./CorporateCollaborationMobile.vue?vue&type=script&lang=js&"
import style0 from "./corporateCollaboration.css?vue&type=style&index=0&id=235d7afc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235d7afc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VCheckbox,VCol,VForm,VImg,VRow,VTextField,VWindow,VWindowItem})
