<template>
  <div class="container">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      :isError="isError"
      @close="isAlertErr = false"
    />
    <div class="mainContainer">
      <img
        style=""
        src="/img/corporate/collaboration/collaboration.png"
        alt=""
      />
      <h1>Bangun Kolaborasi Bersinergi untuk Tingkatkan Performa Bisnis</h1>
      <p>
        Perluas skala bisnis melalui kolaborasi dan sinergi bersama HiColleagues
        sebagai mitra terpercaya dalam merancang dan menyelenggarakan
        produk/event pelatihan
      </p>
      <div class="tombol">
        <button class="konsultasi" @click="scrollToSection('collab')">
          KOLABORASI SEKARANG
        </button>
        <button class="hubungi" @click="sendToWa">HUBUNGI KAMI</button>
      </div>
    </div>
    <div class="content">
      <div class="mitra" style="padding: 32px">
        <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
        <div
          class="d-flex overflow-x-hidden overflow-y-hidden"
          style="height: 100%"
        >
          <v-col
            cols="6"
            v-for="(data, idx) in partners"
            :key="idx"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container
            "
          >
            <v-img :src="data.img_url_mobile" contain />
          </v-col>
          <v-col
            cols="6"
            v-for="(data, idx) in partners"
            :key="idx"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container-2
            "
          >
            <v-img :src="data.img_url_mobile" contain />
          </v-col>
        </div>
      </div>
      <div class="detail" style="padding: 32px">
        <h1>HiColleagues Corporate Collaboration</h1>
        <p>
          Perluas kerjasama kemitraan melalui program unggulan HiColleagues dan
          berikan dampak ke peningkatan performa bisnis Anda
        </p>
        <div class="detail-card" style="border-radius: 16px">
          <div
            class="card"
            v-for="(item, idx) in details"
            :key="idx"
            style="margin-bottom: 20px; border-radius: 16px; padding: 20px"
          >
            <ul style="list-style: none; padding: 0">
              <li>
                <div>
                  <img
                    :src="item.image"
                    alt=""
                    style="
                      border-radius: 50px;
                      width: 40px;
                      height: 40px;
                      text-align: left;
                    "
                  />
                  <h1
                    style="
                      text-align: left;
                      margin: 0;
                      margin-top: 10px;
                      margin-bottom: 6px;
                    "
                  >
                    {{ item.title }}
                  </h1>
                  <p style="text-align: left; margin: 0">{{ item.desc }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="why" style="padding: 32px">
        <h1>
          Mengapa Memilih HiColleagues sebagai Mitra Corporate Collaboration
        </h1>
        <p>
          HiColleagues telah membuktikan melalui
          <b
            >30+ produk kerjasama kemitraan perusahaan yang telah
            dilaksanakan</b
          >
          dan memberi dampak positif terhadap akselerasi performa bisnis mitra
        </p>
        <div class="whys-card mt-10" style="border-radius: 16px">
          <div
            class=""
            v-for="(item, idx) in whys"
            :key="idx"
            style="margin-bottom: 20px"
          >
            <ul style="list-style: none; padding: 0">
              <li>
                <div
                  :style="
                    idx < whys.length - 1
                      ? 'border-bottom: 1px solid #acafbf; padding-bottom: 30px;'
                      : 'padding-bottom: 30px;'
                  "
                >
                  <img :src="item.image" width="60" height="60" alt="" />
                  <h1 style="text-align: left; margin: 0; margin-top: 10px">
                    {{ item.title }}
                  </h1>
                  <p style="text-align: left; margin: 0; margin-top: 10px">
                    {{ item.desc }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="card mb-6"
          style="
            object-fit: cover;
            width: 100%;
            height: 350px;
            display: block;
            overflow: hidden;
            padding: 0;
            border-radius: 30px;
          "
        >
          <img
            src="/img/corporate/collaboration/training.png"
            style="width: 100%"
            alt=""
          />
        </div>
      </div>
      <v-col
        style="margin: 20px 0px"
        class="d-flex justify-center align-center"
      >
        <CollaborationCircle :width="24" :height="24" style="z-index: 1" />
        <div
          style="
            height: 1px;
            width: 80%;
            border: 1px dashed grey;
            left: 50%;
            z-index: 0;
          "
        />
      </v-col>
      <div class="collaboratif" style="padding: 32px">
        <h1>Langkah Kolaboratif Kemitraan</h1>
        <div class="desc">
          <div class="card-content" v-for="(data, index) in steps" :key="index">
            <img
              :src="data.image_url"
              alt=""
              height="170"
              style="
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
                width: 100%;
                object-fit: cover;
              "
            />
            <div class="desc">
              <h3>{{ data.title }}</h3>
              <p>{{ data.description }}</p>
            </div>
          </div>
        </div>
        <div class="indicator-container">
          <div style="width: 10px; height: 10px"></div>
          <div class="indicator">
            <svg
              :style="{ transform: `translateX(${indicator * 15}px)` }"
              width="25"
              height="10"
              viewBox="0 0 25 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="25" height="10" rx="5" fill="#162CA2" />
            </svg>
            <svg
              :style="{ transform: `translateX(${-indicator2 * 30}px` }"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
            </svg>
            <svg
              :style="{ transform: `translateX(${-indicator3 * 30}px` }"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
            </svg>
          </div>

          <div class="btn-arrow">
            <div class="btn-card">
              <img
                src="/img/other/corporate-arrow-left.png"
                alt=""
                @click="scrollLeftStep"
              />
            </div>
            <div class="btn-card">
              <img
                src="/img/other/corporate-arrow-right.png"
                alt=""
                @click="scrollRightStep"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="project">
        <h1>Proyek yang Telah Berjalan</h1>
        <div>
          <img :src="portfolios[ProyekModel].image_url" alt="" />
          <div class="card-port">
            <div class="content-port">
              <h2>{{ portfolios[ProyekModel].title }}</h2>
              <p class="type" v-if="portfolios[ProyekModel].label">
                {{ portfolios[ProyekModel].label }}
              </p>
              <p class="short">{{ portfolios[ProyekModel].program }}</p>
              <p class="desc">{{ portfolios[ProyekModel].description }}</p>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <v-row
          style="margin: 0px 10px; padding: 0px 32px"
          class="d-flex align-center"
        >
          <v-col cols="8">
            <div class="slider">
              <div
                class="indicator"
                :style="{
                  width: `${sliderIndicatorProyek}%`,
                  transform: `translateX(${indicatorValueProyek}%)`,
                  transition: `transform 0.5s`,
                }"
              ></div>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="d-flex"
            style="justify-content: flex-end; gap: 11px"
          >
            <v-btn
              v-for="index in 2"
              fab
              :key="index"
              @click="index === 1 ? ProyekPrev() : ProyekNext()"
              class="btn-mobile-slider-corp"
              elevation="0"
            >
              <Arrow :direct="index === 1 ? 'right' : 'left'" />
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <h5 style="font-size: 16px; padding: 32px" class="text-center">
        Testimoni Kepuasan Klien Kami
      </h5>
      <v-window v-model="testimonyModel" style="padding: 0px 20px">
        <v-window-item
          v-for="index in testimony.length"
          :key="index"
          style="padding: 10px; height: 220px"
        >
          <v-card
            v-for="(data, index) in slicedTestimony(testimonyModel)"
            :key="index"
            style="
              background-color: #ffffff;
              box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
              border-radius: 16px;
              padding: 17px 20px;
              height: 190px;
            "
          >
            <div style="height: 100px">
              <div class="feedback">“{{ data.feedback }}”</div>
            </div>
            <v-row>
              <!-- Image -->
              <v-col cols="3">
                <v-img
                  :src="$utils.loadImage(data.image_url)"
                  width="60"
                  height="60"
                  style="border-radius: 50%"
                />
              </v-col>
              <!-- Name and Position -->
              <v-col cols="9">
                <div style="font-size: 12px; font-weight: 700">
                  {{ data.name }}
                </div>
                <div style="font-size: 10px">
                  {{ data.job }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
      <!-- Pagination -->
      <v-row
        style="margin: 0px 10px; padding: 32px"
        class="d-flex align-center"
      >
        <v-col cols="8">
          <div class="slider">
            <div
              class="indicator"
              :style="{
                width: `${sliderIndicatorTestimony}%`,
                transform: `translateX(${indicatorValueTestimony}%)`,
                transition: `transform 0.5s`,
              }"
            ></div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="d-flex"
          style="justify-content: flex-end; gap: 11px"
        >
          <v-btn
            v-for="index in 2"
            fab
            :key="index"
            @click="index === 1 ? testimonyPrev() : testimonyNext()"
            class="btn-mobile-slider-corp"
            elevation="0"
          >
            <Arrow :direct="index === 1 ? 'right' : 'left'" />
          </v-btn>
        </v-col>
      </v-row>

      <div class="form-konsultasi" style="padding: 32px">
        <div class="header text-center">
          <h1 style="font-size: 16px; font-weight: 700; line-height: 22px">
            Formulir Pendaftaran Konsultasi
          </h1>
        </div>
        <div class="sub-heading mt-1 text-center">
          <p
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
            "
          >
            Langkah tepat optimasi bisnis melalui layanan Corporate Service
            bersama HiColleagues
          </p>
          <v-form class="text-start" style="margin-top: 60px" id="collab">
            <v-col cols="100">
              <label class="form-label" for="company"
                >Nama Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="company"
                v-model="form.corporate_name"
                outlined
                placeholder="Masukkan Nama Perusahaan"
                :full-width="true"
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="website"
                >Website Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                v-model="form.web_url"
                name="website"
                outlined
                placeholder="Masukkan Alamat Website Perusahaan"
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="pic-name"
                >Nama Lengkap PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="pic-name"
                v-model="form.full_name"
                outlined
                placeholder="Masukkan Nama Lengkap PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="email"
                >Email PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="email"
                outlined
                v-model="form.email"
                placeholder="Masukkan Email PIC"
                max-width
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field
                class="form-input text-form"
                name="occupation"
                outlined
                v-model="form.job_pic"
                placeholder="Masukkan Okupasi PIC"
              >
              </v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="phone-number"
                >No. Whatsapp PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="phone-number"
                outlined
                placeholder="Masukkan No. Whatsapp PIC"
                v-model="form.phone_number"
                type="number"
                hide-spin-buttons
              >
                <template v-slot:prepend-inner>
                  <img
                    src="/img/other/flag-indonesia.png"
                    alt=""
                    width="30"
                    height="20"
                    style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)"
                  />
                  <span style="color: #575966" class="mx-3">+62</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="form-label" for="collaboration"
                >Proyeksi Kolaborasi/Kemitraan</label
              >
              <v-text-field
                class="form-input text-form"
                solo
                flat
                background-color="rgba(247, 249, 253, 1)"
                placeholder="Corporate Collaboration"
                disabled
              />
            </v-col>
            <v-row class="d-flex justify-center align-center mt-3">
              <v-checkbox
                class="form-input custom-checkbox"
                v-model="is_confirm"
              >
                <template v-slot:label>
                  <h5 style="font-size: 12px" class="label-checkbox">
                    Dengan mengirim formulir ini saya menyetujui<a href="#">
                      Syarat & Ketentuan </a
                    >untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                  </h5>
                </template>
              </v-checkbox>
            </v-row>
            <v-row class="d-flex justify-center align-center mt-6">
              <v-btn
                elevation="0"
                :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`"
                @click="onSubmit"
                >KIRIM
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="containers">
          <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
          <v-btn class="see-other-faq-corp"
        width="100%"
        elevation="0"
        @click="$router.push('/faq')"
      >
        <div style="font-weight: 700">Lihat Selengkapnya</div>
      </v-btn>  
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import Popup from "@/components/others/Popup.vue";
import CollaborationCircle from "@/components/ilustration/CollaborationCircle.vue";
import AccordeonFaq from "@/components/AccordeonFaq.vue";

export default {
  components: { Arrow, Popup, CollaborationCircle,AccordeonFaq },
  data() {
    return {
      details: [
        {
          image: "/img/corporate/collaboration/f1.png",
          title: "Workshop",
          desc: "Sesi interaktif yang dirancang untuk membahas tentang topik tertentu dengan memberikan kesempatan peserta untuk belajar secara praktis melalui studi kasus, diskusi kelompok, dan latihan secara langsung. ",
        },
        {
          image: "/img/corporate/collaboration/f2.png",
          title: "Webinar",
          desc: "Seminar online dalam mendapatkan wawasan melalui platform virtual, dengan mengikuti presentasi, panel diskusi, dan sesi tanya jawab yang disampaikan oleh para profesional di bidangnya.",
        },
        {
          image: "/img/corporate/collaboration/f3.png",
          title: "Mini Bootcamp",
          desc: "Pelatihan intensif jangka pendek dengan fokus terhadap pengembangan keterampilan tertentu, dirancang untuk memberikan pelatihan yang mendalam dan praktis dalam waktu singkat.",
        },
        {
          image: "/img/corporate/collaboration/f4.png",
          title: "Bootcamp",
          desc: "Program pelatihan intensif yang panjang & komprehensif dengan mencakup kurikulum yang dirancang khusus untuk pengembangan keterampilan teknis & profesional yang mendalam.",
        },
        {
          image: "/img/corporate/collaboration/f5.png",
          title: "Training",
          desc: "Program pelatihan yang dirancang khusus untuk meningkatkan keterampilan spesifik yang dibutuhkan di dunia industri yang bertujuan menjadi profesional yang kompeten.",
        },
      ],
      whys: [
        {
          image: "/img/corporate/collaboration/b1.png",
          title: "Kolaborasi Inklusif",
          desc: "Layanan kolaborasi yang dirancang untuk membangun budaya kerja inklusif dan membangun Tim yang lebih kuat dan kolaboratif",
        },
        {
          image: "/img/corporate/collaboration/b2.png",
          title: "Bangun Kredibilitas Mitra",
          desc: "HiColleagues dapat memberikan validitas tambahan pada perusahaan Anda sebagai our trusted partner.",
        },
        {
          image: "/img/corporate/collaboration/b3.png",
          title: "Tingkatkan Produktivitas & Efisiensi Tim",
          desc: "Bermitra dengan HiColleagues untuk membuka potensi penuh tim Anda dan mencapai tujuan bisnis secara lebih efektif.",
        },
        {
          image: "/img/corporate/collaboration/b4.png",
          title: "Strategi Kolaboratif Terbaik",
          desc: "Layanan kolaborasi yang menerapkan strategi kolaboratif dan solusi terpadu dalam membantu untuk mencapai tujuan bisnis Anda.",
        },
        {
          image: "/img/corporate/collaboration/b5.png",
          title: "Best Practice",
          desc: "Penerapan best practice & metodologi kolaboratif yang telah terbukti efektif di berbagai industri. HiColleagues memiliki proses kontrol kualitas yang ketat untuk memastikan program kolaborasi memenuhi standar tertinggi.",
        },
        {
          image: "/img/corporate/collaboration/b6.png",
          title: "Tingkat kepuasan Klien yang Tinggi",
          desc: "HiColleagues berkomitmen untuk terus memberikan tingkat kepuasan klien yang unggul dengan layanan kolaborasi terbaik.",
        },
      ],

      collaboratif: [
        {
          title: "Observasi & Identifikasi Kebutuhan",
          desc: "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
          image: "/img/corporate/hero.png",
        },
        {
          title: "Observasi & Identifikasi Kebutuhan",
          desc: "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
          image: "/img/corporate/hero.png",
        },
        {
          title: "Observasi & Identifikasi Kebutuhan",
          desc: "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
          image: "/img/corporate/hero.png",
        },
      ],
      portfolios: [],
      testimonyModel: 0,
      ProyekModel: 0,
      indicatorValueTestimony: 0,
      sliderIndicatorTestimony: 0,
      indicatorValueProyek: 0,
      sliderIndicatorProyek: 0,
      testimony: [],
      partners: [],
      steps: [
        {
          image_url: "/img/corporate/collaboration/c1.png",
          title: "Identifikasi Kebutuhan",
          description:
            "Tim ahli kami akan melakukan analisis mendalam terhadap kebutuhan bisnis Anda, tujuan perusahaan, tantangan yang dihadapi, mengidentifikasi area-area yang membutuhkan pengembangan & merancang solusi yang tepat dan terukur untuk memenuhi kebutuhan spesifik Anda",
        },
        {
          image_url: "/img/corporate/collaboration/c2.png",
          title: "Merancang Konsep Inovatif",
          description:
            "Kami akan merancang konsep dan strategi terbaik untuk mengatasi tantangan yang dihadapi perusahaan Anda menggunakan strategi pendekatan yang kreatif dan inovatif untuk menghasilkan solusi yang terdepan dan sesuai dengan tren terkini.",
        },
        {
          image_url: "/img/corporate/collaboration/c3.png",
          title: "Mengeksekusi",
          description:
            "Proyek kolaborasi akan ditangani oleh tim profesional dan berpengalaman yang memiliki spesialisasi di berbagai bidang. Tim kami akan selalu berkomunikasi dengan Anda untuk memastikan proyek berjalan sesuai dengan rencana dan target yang ditetapkan",
        },
        {
          image_url: "/img/corporate/collaboration/c4.png",
          title: "Pelaporan yang Transparan & Akurat",
          description:
            "Kami akan menyediakan laporan analisis yang komprehensif dan rinci mengenai hasil implementasi program kolaborasi yang mencakup metrik yang jelas dan terukur untuk menunjukkan kemajuan yang telah dicapai.",
        },
        {
          image_url: "/img/corporate/collaboration/c5.png",
          title: "Analisis Pasca Proyek",
          description:
            "Pasca proyek, kami melakukan analisis mengenai hasil yang telah dicapai. Dengan melihat Data dan informasi yang diperoleh dari analisis ini dapat digunakan untuk menyusun strategi yang lebih efektif untuk mencapai tujuan bisnis Anda.",
        },
      ],
      questions: [],
      indicator: 0,
      indicator2: 0,
      indicator3: 0,
      sliderIndicator: 0,
      indicatorValue: 0,
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "COLLABORATION",
        web_url: "",
        job_pic: "",
      },
    };
  },
  methods: {
    scrollRightStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft += cardWidth * 1.07;
      if (this.indicator < 2) {
        this.indicator++;
        if (this.indicator2 < 1) {
          this.indicator2++;
        }
      }
      if (this.indicator === 2 && this.indicator3 < 1) {
        this.indicator3++;
      }
    },
    scrollLeftStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft -= cardWidth * 1.07;
      if (this.indicator === 1) {
        this.indicator2--;
      }
      if (this.indicator > 0) {
        this.indicator--;
      }
      if (this.indicator3 < 2 && this.indicator > 0) {
        this.indicator3--;
      }
    },
    testimonyNext() {
      if (this.testimonyModel < this.testimony.length - 1) {
        this.testimonyModel += 1;
        this.indicatorValueTestimony += this.sliderIndicatorTestimony + 50;
      }

      if (this.indicatorValueTestimony === 100) {
        return;
      }
    },
    ProyekPrev() {
      if (this.ProyekModel > 0) {
        this.ProyekModel -= 1;
      }

      if (this.indicatorValueProyek === 0) {
        return;
      }

      this.indicatorValueProyek -= this.sliderIndicatorProyek + 50;
    },
    ProyekNext() {
      if (this.ProyekModel < this.portfolios.length - 1) {
        this.ProyekModel += 1;
        this.indicatorValueProyek += this.sliderIndicatorProyek + 50;
      }

      if (this.indicatorValueProyek === 100) {
        return;
      }
    },
    testimonyPrev() {
      if (this.testimonyModel > 0) {
        this.testimonyModel -= 1;
      }

      if (this.indicatorValueTestimony === 0) {
        return;
      }

      this.indicatorValueTestimony -= this.sliderIndicatorTestimony + 50;
    },
    async getPortofolio() {
      try {
        const resp = await this.$http.get(
          "/v1/project-portfolio?category=COLLABORATION&is_main=true"
        );
        this.portfolios = resp.data.data.project_portfolios;
        this.sliderIndicatorProyek =
          100 / resp.data.data.project_portfolios.length + 6;
      } catch (err) {
        console.log(err);
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=COLLABORATION");
        this.partners = resp.data.data.logos;
      } catch (err) {
        console.log(err);
      }
    },

    slicedTestimony(index) {
      return [this.testimony[index]];
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=COLLABORATION&page=1&per_page=3"
        );
        this.testimony = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=COLLABORATION&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false;
      this.form.full_name = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.corporate_name = "";
      this.form.category = "";
      this.form.web_url = "";
      this.form.job_pic = "";
    },
  },
  mounted() {
    this.getTestimoni();
    this.getClientLogo();
    this.getFaq();
    this.getPortofolio();
  },
  beforeMount() {
    this.sliderIndicator = (1 / this.portfolios.length) * 100;
    this.sliderIndicatorTestimony = 37;
  },
};
</script>

<style src="./corporateCollaboration.css" scoped></style>
